import React from 'react'
import Link from 'next/link'
import { ResponsiveBackgroundImage } from '@/atoms/ResponsiveBackgroundImage'
import { AsP, Text } from '@/atoms/Text'
import { paths } from '@/constants'
import { useTranslate } from '@/utils/translate/translate-client'
import { GuildGiveawayToolTip } from '@/views/TicketCheckoutViews/ShowtimesView/components/ToolTips/GuildGiveawayToolTip'

export const GuildHomesteadGiveawayBanner: React.FC = () => {
  const { t } = useTranslate('tickets')

  return (
    <div className="relative bg-[#15120F] @container">
      <ResponsiveBackgroundImage
        src="/v1731110392/Homestead/homestead-banner-bg-fade"
        className="hidden xs:block"
        backgroundSize="bg-contain"
        backgroundPosition="bg-right"
        responsiveAR={{
          xs: 2,
          sm: 2,
          md: 2,
          lg: 2,
          xl: 2,
          '2xl': 2,
          '3xl': 2,
        }}
      />
      <div className="relative flex flex-col items-start gap-2 px-4 py-6 @2xl:flex-row @2xl:items-center">
        <div>
          <Text
            as={AsP}
            color="white"
            className="!font-[dharma-gothic-e] text-[1.625rem] font-bold uppercase leading-none tracking-wider"
          >
            {t('get100EntriesToTheGiveaway', 'Get 100 entries to the giveaway!')}
          </Text>
          <Text
            as={AsP}
            color="homestead-yellow"
            className="mb-[5px] !font-[dharma-gothic-e] text-[2rem] font-bold uppercase leading-none tracking-[0.06rem]"
          >
            {t('claimYourGuildTickets', 'Claim your Guild tickets')}
          </Text>
          <Link
            target="_blank"
            href={paths.preSales.giveaway('homestead')}
            className="font-[bio-sans] text-sm font-semibold leading-tight text-homestead-tan underline"
          >
            {t('learnMoreAboutTheGiveaway', 'Learn more about the Giveaway.')}
          </Link>
        </div>
        <GuildGiveawayToolTip
          className="mt-2"
          removeBorder={true}
          buttonColor={'white'}
          buttonClassName=" bg-lightened-200 !text-white backdrop-blur-xl"
        />
      </div>
    </div>
  )
}
