import React from 'react'
import { LinkButton } from '@/atoms/Button'
import { EmailSentConfetti } from '@/atoms/Icons/EmailSentConfetti'
import { EnvelopeIcon } from '@/atoms/Icons/EnvelopeIcon'
import { HeadingSM, ParagraphMD } from '@/atoms/Text'
import { Translate, useTranslate } from '@/utils/translate/translate-client'

export const GroupTicketsEmailSent: React.FC = () => {
  const { t } = useTranslate('tickets')

  return (
    <div className="py-8 md:px-3">
      <div className="pointer-events-none absolute inset-x-0 top-0 opacity-50">
        <EmailSentConfetti />
      </div>
      <div className="flex flex-row items-center justify-center">
        <EnvelopeIcon size={32} />
        <HeadingSM weight="bold" className="text-core-gray-950">
          {t('groupTicketEmailSent', 'Email Sent!')}
        </HeadingSM>
      </div>
      <ParagraphMD className="mx-auto mt-4 max-w-md" weight="medium" color="core-gray-700">
        <Translate t={t} i18nKey="groupTicketEmailSentConfirmationMessageGroup">
          Thank you for your order! Please check your email for your group ticket code. If you need any assistance,
          please contact our{' '}
          <LinkButton
            className="m-0 inline border-none p-0 underline"
            href="https://support.angel.com/hc/en-us"
            variant="plain"
            external
          >
            support team
          </LinkButton>
          . Thank you!
        </Translate>
      </ParagraphMD>
    </div>
  )
}
