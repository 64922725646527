import React, { useMemo } from 'react'
import classNames from 'classnames'
import { LinkButton } from '@/atoms/Button'
import { FlexColumn } from '@/atoms/FlexContainers'
import { PayItForwardIcon } from '@/atoms/Icons/PayItForwardIcon'
import { CaptionLG, CaptionSM } from '@/atoms/Text'
import { TheatricalReleaseObject } from '@/types/codegen-federation'
import { useTranslate } from '@/utils/translate/translate-client'

interface TicketsPayItForwardAd {
  theatricalRelease: TheatricalReleaseObject
  theatricalSlug: string
  className?: string
}
export const TicketsPayItForwardAd: React.FC<TicketsPayItForwardAd> = ({
  theatricalSlug,
  theatricalRelease,
  className,
}) => {
  const { t } = useTranslate('tickets')
  const countryCode = theatricalRelease?.region?.countryCode

  const pifLink = useMemo(() => {
    if (countryCode !== 'US') return `/pay-it-forward/${theatricalSlug}/${countryCode}`
    return `/pay-it-forward/${theatricalSlug}`
  }, [countryCode, theatricalSlug])

  return (
    <FlexColumn className={classNames('gap-1 md:rounded-2xl bg-white/10 relative p-4', className)}>
      <FlexColumn className="gap-1">
        <CaptionLG color="white" weight="bold">
          {t('alreadyHaveYourTickets', 'Already Have Your Tickets?')}
        </CaptionLG>
        <CaptionSM className="mx-auto max-w-xs md:mx-0" color="core-gray-400">
          {t('ticketPagePifCta', 'Provide free tickets for someone to claim and watch at the movie theaters.')}
        </CaptionSM>
      </FlexColumn>
      <LinkButton
        className="w mt-2 fill-oxide-bright p-3 backdrop-blur-md"
        href={`${pifLink}?utm_campaign=${theatricalSlug}-theatrical&utm_medium=web&utm_source=tickets-footer-ad#`}
        variant="oxide-bright"
        prefetch
      >
        <div className="relative flex w-full flex-row items-center justify-center">
          <PayItForwardIcon color="black" />
          <div className="ml-2 whitespace-nowrap text-sm">{t('payItForward', 'Pay it Forward')}</div>
        </div>
      </LinkButton>
    </FlexColumn>
  )
}
