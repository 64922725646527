import React, { FC, useCallback } from 'react'
import { useRouter } from 'next/router'
import { Hypebot } from '@/organisms/Hypebot'
import { ChatbotMessageType, ChatFeedMessage } from '@/services/WatchPartyChatService'
import { WatchPartyChatContextProvider } from '@/services/WatchPartyChatService/WatchPartyChatService'
import { ChatbotMessageTicketIntent } from '@/services/WatchPartyChatService/types'
import { TheatricalReleaseObject } from '@/types/codegen-federation'
import TicketGoalLine from '@/views/TicketCheckoutViews/ShowtimesView/components/TicketGoalLine'

interface TicketGoalAndHypebotProps {
  theatricalRelease: TheatricalReleaseObject
  theatricalSlug: string
  shouldHideTitle?: boolean
}
const TicketGoalAndHypebot: FC<TicketGoalAndHypebotProps> = ({
  theatricalRelease,
  theatricalSlug,
  shouldHideTitle = false,
}) => {
  const { query } = useRouter()

  const theater = typeof query?.theater === 'string' ? query.theater.toLowerCase() : null
  const messageFilter = useCallback(
    (message: ChatFeedMessage | ChatbotMessageTicketIntent) => {
      const venue = (message as ChatbotMessageTicketIntent)?.venue_name?.toLowerCase()
      if (!theater || !venue || venue.includes(theater)) return true
      return false
    },
    [theater],
  )

  const acceptedHypeMessages = [
    ChatbotMessageType.claim,
    ChatbotMessageType.ticket,
    ChatbotMessageType.pif,
    ChatbotMessageType.guild,
  ]

  return (
    <div className="z-50 hidden w-full grow-1 flex-col rounded-2xl sm:min-h-10 md:flex md:max-h-[303.5px] md:min-h-[165px] md:shadow-modal md:backdrop-blur-2xl lg:max-h-full">
      <TicketGoalLine
        shouldHideTitle={shouldHideTitle}
        theatricalRelease={theatricalRelease}
        theatricalSlug={theatricalSlug}
      />
      <WatchPartyChatContextProvider
        chatCode={theatricalRelease?.hypebotCode}
        acceptedMessageTypes={acceptedHypeMessages}
        projectSlug={theatricalRelease?.projectSlug}
        messageFilter={messageFilter}
      >
        <Hypebot
          initialMessageClassName="text-white"
          isNewDesign={true}
          className="max-h-full w-full !grow-0 bg-white/10 text-white"
          theatricalRelease={theatricalRelease}
        />
      </WatchPartyChatContextProvider>
    </div>
  )
}

export default TicketGoalAndHypebot
