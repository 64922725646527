import React from 'react'
import { When } from 'react-if'
import { GroupTickets } from 'src/views/TicketCheckoutViews/ShowtimesView/components/modals/GroupTicketsModal/GroupTickets'
import { CircleBusyIndicator } from '@/atoms/CircleBusyIndicator'
import { CloseIcon } from '@/atoms/Icons/CloseIcon'
import { Modal } from '@/molecules/Modal'
import { GroupTicketOption } from '@/types/codegen-contentful'
import { GroupTicketsEmailSent } from '@/views/TicketCheckoutViews/ShowtimesView/components/modals/GroupTicketsModal/GroupTicketsEmailSent'
import { GroupTicketsFaq } from '@/views/TicketCheckoutViews/ShowtimesView/components/modals/GroupTicketsModal/GroupTicketsFaq'

const TICKET_PRICE = 15.0

export type GroupTicketsModalTypes = 'tickets' | 'faq' | 'complete' | 'loading' | 'group_ticket_email_sent'

export interface GroupTicketsModalProps {
  open: boolean
  onClose: () => void
  modalType: GroupTicketsModalTypes
  setModalType: React.Dispatch<React.SetStateAction<GroupTicketsModalTypes>>
  groupTicketOptions: GroupTicketOption[]
  shouldUseNewDesign?: boolean
  projectName: string
  theatricalSlug: string
}

export const GroupTicketsModal = ({
  open,
  onClose,
  modalType = 'tickets',
  setModalType,
  groupTicketOptions,
  projectName,
  shouldUseNewDesign,
  theatricalSlug,
}: GroupTicketsModalProps) => {
  return (
    <Modal
      backgroundScreenColor="black"
      backgroundOverlayClassName="overflow-y-auto min-h-screen"
      className="z-[9999]"
      isOpen={open}
      transition="appear"
      panelClasses="!w-[calc(100%-2em)] md:w-full !max-w-[540px] overflow-y-auto !p-6 !pt-12 md:!px-10 md:!pb-8 shadow-light-3"
    >
      <div>
        <div className="absolute right-6 top-6 cursor-pointer rounded-full bg-core-gray-300 p-2" onClick={onClose}>
          <CloseIcon color="core-gray-700" size={16} />
        </div>
        <div>
          <When condition={modalType === 'loading'}>
            <CircleBusyIndicator className="mx-auto mt-40 w-fit" variant="oxide" size={56} backgroundColor="gray-950" />
          </When>
          <When condition={modalType === 'tickets'}>
            <GroupTickets
              projectName={projectName}
              theatricalSlug={theatricalSlug}
              setModalType={setModalType}
              groupTicketOptions={groupTicketOptions}
              ticketPrice={TICKET_PRICE}
              shouldUseNewDesign={shouldUseNewDesign}
            />
          </When>
          <When condition={modalType === 'faq'}>
            <GroupTicketsFaq setModalType={setModalType} />
          </When>
          <When condition={modalType === 'group_ticket_email_sent'}>
            <GroupTicketsEmailSent />
          </When>
        </div>
      </div>
    </Modal>
  )
}
