import React, { useCallback, useMemo, useState } from 'react'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import qs from 'query-string'
import { Case, Default, Switch } from 'react-if'
import { Button } from '@/atoms/Button'
import { CinemarkLogo } from '@/atoms/Icons/CinemarkLogo'
import { CloseGradientIcon } from '@/atoms/Icons/CloseGradientIcon'
import { Image } from '@/atoms/Image'
import { AngelLogo } from '@/atoms/Logos/AngelLogo'
import { AsSpan, CaptionSM, HeadingSM, ParagraphLG, ParagraphMD, ParagraphSM, TitleXS } from '@/atoms/Text'
import { TheatricalMovie } from '@/services/CmsService/TheatricReleases'
import { buildCloudinaryImagePathFromContentfulObject } from '@/utils/Cloudinary/cloudinary'
import { useLocale } from '@/utils/LocaleUtil'
import { mergeQueryParameters } from '@/utils/UriBuilder'
import { getBasePath } from '@/utils/sitemap/base'
import { Translate, useTranslate } from '@/utils/translate/translate-client'

export interface TicketPromoModalIntroProps {
  closePromoModal: () => void
  setEmployeeNumber: (employeeNumber: string) => void
  companyName: string | null
  shouldShowCinemark?: boolean
  projectTitle: string
  movies?: TheatricalMovie[] | []
  setSelectedMovie?: React.Dispatch<React.SetStateAction<string | null>>
  shouldShowMovieSelect?: boolean
}
export const TicketPromoModalIntro: React.FC<TicketPromoModalIntroProps> = ({
  closePromoModal,
  setEmployeeNumber,
  companyName,
  shouldShowCinemark = false,
  projectTitle,
  movies,
  setSelectedMovie,
  shouldShowMovieSelect = false,
}) => {
  const { t } = useTranslate('tickets')
  const [value, setValue] = useState<string>('')
  const [shouldShowCinemarkIntro, setShouldShowCinemarkIntro] = useState<boolean>(shouldShowCinemark)
  const { query, push, asPath } = useRouter()
  const { locale } = useLocale()
  const url = `${getBasePath(locale)}`
  const isPromoRoute = query?.promo
  const orderedMovies = useMemo(() => {
    const selectedMovie = movies?.find((s) => s.theatricalSlug === query?.slug)
    const sortedMovies = movies
      ?.filter((m) => m.theatricalSlug !== selectedMovie?.theatricalSlug)
      .sort((a, b) => {
        return new Date(a.releaseDate).getTime() - new Date(b.releaseDate).getTime()
      })
    if (!sortedMovies || !selectedMovie || sortedMovies.length === 0) return []
    return [selectedMovie, ...sortedMovies]
  }, [movies, query?.slug])

  const handleMovieSelect = useCallback(
    (theatricalSlug: string) => {
      if (!setSelectedMovie) return
      try {
        if (query?.promo) setSelectedMovie(theatricalSlug)
        if (!query.promo) {
          setSelectedMovie(null)
          closePromoModal()
        }
        const urlObject = new URL(`${url}${asPath}`)
        const paramsFromUrl = qs.parse(urlObject.search)
        const entries = Object.entries(paramsFromUrl).filter((p) => p[0] !== 'movieSelect')
        push(
          mergeQueryParameters(
            `${url}/tickets/${theatricalSlug}`,
            Object.fromEntries(entries) as { [k: string]: string },
          ),
        )
      } catch (err) {
        return setSelectedMovie(query?.slug as string)
      }
    },
    [asPath, query?.slug, push, url, setSelectedMovie, closePromoModal, query?.promo],
  )
  return (
    <>
      <Switch>
        <Case condition={shouldShowMovieSelect}>
          <div className="mt-4">
            <div className="mb-6 flex flex-col gap-2">
              <HeadingSM weight="bold">{t('selectAMovieText', 'Select a Movie')}</HeadingSM>
              <ParagraphSM color="core-gray-700">
                {isPromoRoute
                  ? t('pleaseSelectWhichMovieFreeTickets', 'Please select which movie you would like free tickets to.')
                  : t('requestTicketTitle', 'Please select which movie you would like tickets to.')}
              </ParagraphSM>
            </div>
            <div className="mb-6 mt-4 flex justify-center">
              <form className="flex w-full flex-col gap-3" onSubmit={(e) => e.preventDefault()}>
                {orderedMovies?.map((movie) => {
                  return (
                    <div
                      key={movie.projectName}
                      className="grow-1 select-none"
                      onClick={() => handleMovieSelect(movie.theatricalSlug)}
                    >
                      <label className="flex w-full cursor-pointer items-center justify-center gap-2">
                        <span
                          className={classNames(
                            'flex justify-between py-3 px-4 border-2 rounded-lg flex-grow-1 max-w-[320px] min-h-[120px] w-full gap-3',
                            query?.slug === movie.theatricalSlug ? 'border-core-gray-950' : 'border-core-gray-400',
                          )}
                        >
                          <Image
                            alt="Movie thumbnail"
                            src={buildCloudinaryImagePathFromContentfulObject(movie.verticalPosters[0])}
                            width={64}
                            height={96}
                          />
                          <span className="flex flex-col justify-center gap-1 text-left">
                            <TitleXS as={AsSpan} weight="bold" className="block">
                              {movie?.projectName}
                            </TitleXS>
                            <CaptionSM as={AsSpan} weight="medium" color="core-gray-600" className="block">
                              {movie?.filmDetails?.mediumDescription}
                            </CaptionSM>
                          </span>
                        </span>
                      </label>
                    </div>
                  )
                })}
              </form>
            </div>
          </div>
        </Case>
        <Case condition={companyName}>
          <div className="mt-4">
            <HeadingSM weight="bold">{t('requestFreeTicketTitle', 'Request a Free Ticket')}</HeadingSM>
            <ParagraphMD color="core-gray-700" className="mb-4 mt-6">
              <Translate t={t} i18nKey="thanksToAngelsWhoPaidItForwardCompanyv2" values={{ companyName }}>
                Thanks to <strong className="text-core-gray-950">{{ companyName }}</strong> joining Pay it Forward with
                Angel Studios, we have a limited number of free tickets to watch Sound of Freedom in theaters.
              </Translate>
            </ParagraphMD>
            <ParagraphLG weight="semibold" className="text-left">
              <Translate t={t} i18nKey="requestFreeTicketLabel">
                To request a free ticket:
              </Translate>
            </ParagraphLG>
            <ParagraphMD className="text-left" weight="normal" color="core-gray-700">
              <Translate t={t} i18nKey="requestFreeTicketIntroductionCompany" values={{ companyName }}>
                1. Enter your code sponsored by <strong className="text-core-gray-950">{{ companyName }}</strong>.
                <br />
                2. Enter your location and choose a date.
                <br />
                3. Select a showtime.
                <br />
                4. Follow the prompts to request your free ticket.
              </Translate>
            </ParagraphMD>
            <ParagraphLG weight="semibold" className="mt-4 text-left">
              <Translate t={t} i18nKey="requestFreeTicketNumberLabel">
                Sponsor code or employee number:
              </Translate>
            </ParagraphLG>
            <input
              placeholder="1234"
              className="mt-2 w-full rounded-lg border border-core-gray-200 bg-core-gray-100 p-3.5 font-medium"
              type="text"
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
            <div className="mx-auto flex max-w-[250px] flex-col">
              <Button
                disabled={value.trim().length < 2}
                className="mt-8 w-full"
                variant="black"
                onClick={() => {
                  setEmployeeNumber(value)
                  closePromoModal()
                }}
              >
                <ParagraphLG className="mx-auto" weight="semibold">
                  {t('continue', 'Continue')}
                </ParagraphLG>
              </Button>
            </div>
          </div>
        </Case>

        <Case condition={shouldShowCinemarkIntro && !companyName}>
          <div className="flex flex-col gap-8">
            <div className="flex items-center justify-center gap-3">
              <AngelLogo height={48} color="core-gray-950" />
              <CloseGradientIcon size={20} />
              <CinemarkLogo />
            </div>
            <ParagraphMD className="leading-7" color="core-gray-700" weight="medium">
              <Translate i18nKey="cinemarkFreeTicketIntro" t={t}>
                Angel Studios has partnered with Cinemark Theaters to bring you{' '}
                <span className="font-bold text-oxide">Pay it Forward</span> movie tickets from our community.
              </Translate>
            </ParagraphMD>
            <Button
              onClick={() => setShouldShowCinemarkIntro(false)}
              className="mx-auto w-full max-w-[250px] px-8 py-3"
              variant="black"
            >
              {t('freeTicketContinue', 'Continue')}
            </Button>
          </div>
        </Case>

        <Default>
          <div className="mt-4">
            <HeadingSM weight="bold">{t('requestFreeTicketTitle', 'Request a Free Ticket')}</HeadingSM>
            <ParagraphMD color="core-gray-700" className="mb-4 mt-6">
              <Translate t={t} i18nKey="thanksToAngelsWhoPaidItForward" values={{ projectTitle }}>
                Thanks to Angels in our network who have Paid It Forward, we have a limited number of free tickets
                available to see <i>{{ projectTitle }}</i> in theaters.
              </Translate>
            </ParagraphMD>
            <ParagraphLG weight="semibold" className="text-left">
              <Translate t={t} i18nKey="requestFreeTicketLabel">
                To request a free ticket:
              </Translate>
            </ParagraphLG>
            <ParagraphMD className="text-left" weight="normal" color="core-gray-700">
              <Translate t={t} i18nKey="requestFreeTicketIntroductionV2">
                1. Enter your location and choose a date.
                <br />
                2. Select a showtime.
                <br />
                3. Follow the prompts to request your free ticket.
              </Translate>
            </ParagraphMD>
            <div className="mx-auto flex max-w-[250px] flex-col">
              <Button className="mt-8 w-full" variant="black" onClick={() => closePromoModal()}>
                <ParagraphLG className="mx-auto" weight="semibold">
                  {t('continue', 'Continue')}
                </ParagraphLG>
              </Button>
            </div>
          </div>
        </Default>
      </Switch>
    </>
  )
}
