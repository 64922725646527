import { useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { getVenueFromQuery } from '@/services/TicketsService/TicketsService'
import { TheatricalShowtimeVenue } from '@/types/codegen-federation'

interface Props {
  venues: TheatricalShowtimeVenue[]
}
export const useFindQueryVenue = ({ venues }: Props) => {
  const { isReady, query } = useRouter()
  const [venue, setVenue] = useState<TheatricalShowtimeVenue | undefined>()
  const [isVenueLoadComplete, setIsVenueLoadComplete] = useState(false)

  useEffect(() => {
    if (!isReady || isVenueLoadComplete || venues.length === 0) return

    const venueFromQuery = getVenueFromQuery(query, venues)
    if (venueFromQuery) setVenue(venueFromQuery)

    setIsVenueLoadComplete(true)
  }, [isReady, isVenueLoadComplete, query, venues])

  return useMemo(() => {
    return { venue }
  }, [venue])
}
