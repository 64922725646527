import React, { useState } from 'react'
import classNames from 'classnames'
import { Button } from '@/atoms/Button'
import { Image } from '@/atoms/Image'
import { YoutubeVideoModal } from '@/molecules/YoutubeVideoModal'
import { Translate, useTranslate } from '@/utils/translate/translate-client'
import {
  HomesteadButtonText,
  HomesteadParagraphMD,
  HomesteadTitleXL,
} from '@/views/PreSales/PreSalesGiveawayView/components/Typography'
import { useMultiplier } from './utils'

export const GeneralHomesteadGiveawayBanner: React.FC = () => {
  const { t } = useTranslate('tickets')
  const multiplier = useMultiplier()
  const [showModal, setShowModal] = useState(false)

  if (!multiplier) return null

  return (
    <div className="@container">
      <div className="relative flex @4xl:h-[120px]">
        <div
          className={classNames(
            'relative flex w-2/3 flex-col bg-homestead-tan-200 py-4 z-20',
            '@lg:w-2/3',
            '@2xl:w-auto flex-1',
            '@4xl:items-center @4xl:flex-row @4xl:flex-1 @4xl:py-0 ',
          )}
        >
          <div className="absolute inset-0 bg-[url(https://images.angelstudios.com/image/upload/v1729801080/dirt-texture-on-white-01.webp)] bg-cover bg-no-repeat opacity-[.1] mix-blend-multiply" />

          <div className="relative z-10 flex h-full flex-col justify-center px-4">
            <h3 className="!font-[dharma-gothic-e] text-[18px] uppercase leading-none tracking-[0.7px] text-homestead-primary-blue @2xl:text-[22px]">
              {t('watchHomesteadInTheatersAndYouCouldWinThe', 'Watch Homestead in theaters and You could win the')}
            </h3>
            <HomesteadTitleXL className="!text-[36px] font-medium text-homestead-red @2xl:!text-[44px]">
              {t('300kHomesteadGiveaway', '$300k Homestead Giveaway')}
            </HomesteadTitleXL>

            <div className="self-start bg-homestead-yellow px-1.5 py-1">
              <HomesteadParagraphMD className="whitespace-pre-line !text-[14px] font-medium !leading-[1.2] !text-homestead-primary-blue @sm:whitespace-nowrap @sm:!text-[16px]">
                <Translate
                  t={t}
                  i18nKey="getMultiplerYourEntriesThisWeekOnly"
                  defaults={`Get <bold>{{multiplier}}x your entries</bold> this week only.\nEnds Sunday.`}
                  components={{
                    bold: <span className="font-bold" />,
                  }}
                  values={{ multiplier }}
                />
              </HomesteadParagraphMD>
            </div>
          </div>

          <Button
            variant="plain"
            onClick={() => setShowModal(true)}
            className={classNames(
              'relative z-10 self-start ml-4 rounded-lg bg-homestead-red px-4 py-2 @2xl:py-3 text-white transition-all duration-300 ease-in-out hover:bg-homestead-red-dark active:bg-homestead-red-200 mt-3',
              '@4xl:mx-auto @4xl:self-center',
            )}
          >
            <HomesteadButtonText>{t('watchGiveAwayVideo', 'Watch giveaway video')}</HomesteadButtonText>
          </Button>
        </div>

        <div className="relative hidden w-1/3 shrink-0 overflow-hidden @lg:block @2xl:w-[240px]">
          <div
            className="absolute inset-y-0 left-[-1px] z-10 w-8 bg-homestead-tan-200"
            style={{ clipPath: 'polygon(0 0, 100% 0, 0 100%)' }}
          />
          <Image
            width={240}
            height={120}
            src={`/v1730228369/angel-studios/projects/homestead/homestead-giveaway-set-01`}
            alt={t('homeSteadGiveawayItems', 'Homestead giveaway items')}
            className="hidden h-full object-cover object-[50%_80%] @4xl:block"
          />

          <Image
            width={240}
            height={120}
            src={`/v1731701946/angel-studios/projects/homestead/utv-image-turning-01`}
            alt={t('homeSteadGiveawayItems', 'Homestead giveaway items')}
            className="hidden h-full object-cover object-[50%_80%] @lg:block @4xl:hidden"
          />
        </div>

        <YoutubeVideoModal
          youTubeUrl="https://www.youtube.com/embed/V5zQ6XyHhrs"
          showModal={showModal}
          handleClose={() => setShowModal(false)}
          className="!z-[60]"
        />
      </div>
    </div>
  )
}
