import React, { FC } from 'react'
import { EmailSentConfetti } from '@/atoms/Icons/EmailSentConfetti'
import { EnvelopeIcon } from '@/atoms/Icons/EnvelopeIcon'
import { HeadingSM, ParagraphMD } from '@/atoms/Text'
import { AnalyticsEvent } from '@/utils/analytics'
import { useTranslate } from '@/utils/translate/translate-client'
import { BuyTicketsLink } from '@/views/TicketCheckoutViews/ShowtimesView/components/TicketsPromo/FreeTicketModal'

interface PromoSuccessProps {
  whatsAppVerified: boolean
  theaterSlug?: string
  isOnlineRedeemable?: boolean
  ticketUrl: string
  trackClick: (eventName: AnalyticsEvent, customAttributes?: unknown) => void
}

const PromoSuccess: FC<PromoSuccessProps> = ({ whatsAppVerified, trackClick, ticketUrl }) => {
  const { t } = useTranslate('tickets')

  return (
    <div>
      <div className="absolute top-0  ">
        <EmailSentConfetti />
      </div>
      <div className="flex flex-row items-center justify-center gap-1">
        <EnvelopeIcon color="core-gray-950" size={36} />
        {whatsAppVerified ? (
          <HeadingSM weight="bold">{t('whatsAppSentPromoModal', 'WhatsApp Message Sent!')}</HeadingSM>
        ) : (
          <HeadingSM weight="bold">{t('emailSentPromoModal', 'Email Sent!')}</HeadingSM>
        )}
      </div>
      <ParagraphMD className="mt-4" weight="normal" color="core-gray-700">
        {whatsAppVerified
          ? t(
              'whatsappSentConfirmationMessageRemixV5',
              'We will follow up with more information via WhatsApp. When a voucher becomes available we will deliver it\n' +
                '              you via WhatsApp.',
            )
          : t(
              'yourOnTheWaitList',
              "You're on the waitlist! Check your email for more information (it may take up to 10 min).",
            )}
      </ParagraphMD>
      <BuyTicketsLink
        isPrimaryButton
        trackClick={trackClick}
        ticketUrl={ticketUrl}
        text={t('buyTicketsInsteadTitleCase', 'Buy Tickets Instead')}
      />
    </div>
  )
}

export default PromoSuccess
