import React, { FC } from 'react'
import classNames from 'classnames'
import { FlexColumn, FlexRow } from '@/atoms/FlexContainers'
import { CaptionSM, CaptionXS, Text } from '@/atoms/Text'
import { slugs } from '@/constants'
import { useTranslate } from '@/utils/translate/translate-client'
import { ManualPromoCode } from '@/views/TicketCheckoutViews/ShowtimesView/components/ManualPromoCode'
import { FlexibleBookingToolTip } from '../ToolTips/FlexibleBookingToolTip'

interface BogoBannerProps {
  className?: string
  theatricalSlug: string
  code: string
}
const BogoBanner: FC<BogoBannerProps> = ({ className, theatricalSlug, code }) => {
  const { t } = useTranslate('tickets')

  if (theatricalSlug === slugs.soundOfHopeTheStoryOfPossumTrot) {
    return (
      <div className={classNames('relative z-[49] w-full @container', className)}>
        <FlexRow
          style={
            theatricalSlug === slugs.soundOfHopeTheStoryOfPossumTrot
              ? {
                  backgroundImage:
                    'url(https://images.angelstudios.com/image/upload/v1715801934/hope-vert-banner-vanilla-uw-flip2_2.png)',
                }
              : undefined
          }
          className={classNames(
            'relative w-full gap-2 bg-[#005F68] bg-cover bg-left bg-no-repeat p-4 text-white @3xl:justify-between @3xl:pr-[180px] md:bg-center',
            theatricalSlug === slugs.soundOfHopeTheStoryOfPossumTrot ? '@6xl:pr-[300px]' : '@2xl:pr-[180px]',
          )}
        >
          <FlexColumn className="relative z-20 shrink-0 gap-0.5">
            <Text weight="bold" className="photon-title-md @md:photon-heading-xs @2xl:photon-heading-sm uppercase">
              {t('bogoTicketsBanner', 'BOGO Tickets')}
            </Text>
            <CaptionSM color="core-gray-200" className="max-w-[10rem] text-[0.75rem] sm:!max-w-full md:text-xs" italic>
              *{t('onlyValidForJuly3rdAnd4th', 'Only valid for July 3rd & July 4th')}
            </CaptionSM>
            <FlexibleBookingToolTip
              className="!z-0 mr-auto mt-1"
              buttonClassName={classNames(
                '!z-0 backdrop-blur border-none w-full',
                theatricalSlug === slugs.soundOfHopeTheStoryOfPossumTrot ? 'bg-white/20' : 'bg-black/30',
              )}
              panelPosition="CENTER"
            />
          </FlexColumn>
          <div className="mx-auto flex shrink-0 flex-col items-center gap-2">
            <Text weight="bold" className="photon-title-xs  @md:hidden">
              {t('useAtCheckout', 'Use at Checkout')}
            </Text>
            <Text weight="bold" className="photon-title-xs hidden @md:block">
              {t('useCodeAtCheckout', 'Use Code at Checkout')}
            </Text>
            <ManualPromoCode
              showCopyIcon
              codeClassName="!bg-white py-2 px-3 @md:px-6 @md:py-3 !text-black font-semibold text-sm rounded-lg"
              isDarkMode
              codeOnly
              code={code}
            />
            <CaptionXS color="core-gray-200" className="max-w-[9rem] text-center text-[0.625rem] md:text-xs" italic>
              {t('availableForAngelPartnering', 'Available for Angel Partnering Theaters.')}
            </CaptionXS>
          </div>
        </FlexRow>
      </div>
    )
  }

  return null
}

export default BogoBanner
