import React from 'react'
import { GuildShieldSolid } from '@/atoms/Icons/GuildShieldSolid'
import { ParagraphSM, TitleXS } from '@/atoms/Text'
import { CaptionMD } from '@/atoms/Text'
import { useTranslate } from '@/utils/translate/translate-client'
import { BaseTooltipProps } from '.'
import { BaseTooltip } from '.'

export const GuildScoreToolTip: React.FC<BaseTooltipProps> = ({
  iconSize = 16,
  className,
  buttonClassName,
  panelClassName,
  panelPosition = 'CENTER',
  isIconOnly,
  removeBorder = false,
  buttonStyle,
  guildScore,
}) => {
  const { t } = useTranslate('tickets')

  const trigger = (
    <>
      <div>
        <GuildShieldSolid size={iconSize} color="gray-300" />
      </div>

      <CaptionMD>{guildScore}</CaptionMD>
    </>
  )

  const content = (
    <>
      <TitleXS color="core-gray-950" weight="bold">
        {t('whatIsGuildScore', 'What is the Guild Score?')}
      </TitleXS>
      <ParagraphSM className="mt-2 text-[0.688rem] leading-[16px]" color="core-gray-700">
        {t(
          'whatIsGuildScoreText',
          'Every film and series on Angel Studios is first reviewed by the Angel Guild—a passionate community dedicated to stories that amplify light. A higher Guild Score means the Guild believes the film or series has the potential to inspire, uplift, and resonate deeply with audiences like you.',
        )}
      </ParagraphSM>
    </>
  )

  return (
    <BaseTooltip
      className={className}
      buttonClassName={buttonClassName}
      panelClassName={panelClassName}
      panelPosition={panelPosition}
      isIconOnly={isIconOnly}
      removeBorder={removeBorder}
      buttonStyle={buttonStyle}
      trigger={trigger}
      content={content}
    />
  )
}
