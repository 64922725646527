import React from 'react'
import { getHexColor, OneColorIconProps } from '../utils'

export const ShareLinkIcon: React.FC<OneColorIconProps> = ({ size = 16, color = 'white', className }) => {
  const hex = getHexColor(color)

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M5.127 11.2358C4.20478 11.2358 3.41867 10.9108 2.76867 10.2608C2.11867 9.61076 1.79367 8.82465 1.79367 7.90243C1.79367 6.9802 2.11867 6.19409 2.76867 5.54409C3.41867 4.89409 4.20478 4.56909 5.127 4.56909H7.127C7.31589 4.56909 7.47423 4.63298 7.602 4.76076C7.72978 4.88854 7.79367 5.04687 7.79367 5.23576C7.79367 5.42465 7.72978 5.58298 7.602 5.71076C7.47423 5.83854 7.31589 5.90243 7.127 5.90243H5.127C4.57145 5.90243 4.09923 6.09687 3.71034 6.48576C3.32145 6.87465 3.127 7.34687 3.127 7.90243C3.127 8.45798 3.32145 8.9302 3.71034 9.31909C4.09923 9.70798 4.57145 9.90243 5.127 9.90243H7.127C7.31589 9.90243 7.47423 9.96631 7.602 10.0941C7.72978 10.2219 7.79367 10.3802 7.79367 10.5691C7.79367 10.758 7.72978 10.9163 7.602 11.0441C7.47423 11.1719 7.31589 11.2358 7.127 11.2358H5.127ZM6.46034 8.56909C6.27145 8.56909 6.11311 8.5052 5.98534 8.37743C5.85756 8.24965 5.79367 8.09131 5.79367 7.90243C5.79367 7.71354 5.85756 7.5552 5.98534 7.42743C6.11311 7.29965 6.27145 7.23576 6.46034 7.23576H10.4603C10.6492 7.23576 10.8076 7.29965 10.9353 7.42743C11.0631 7.5552 11.127 7.71354 11.127 7.90243C11.127 8.09131 11.0631 8.24965 10.9353 8.37743C10.8076 8.5052 10.6492 8.56909 10.4603 8.56909H6.46034ZM9.79367 11.2358C9.60478 11.2358 9.44645 11.1719 9.31867 11.0441C9.19089 10.9163 9.127 10.758 9.127 10.5691C9.127 10.3802 9.19089 10.2219 9.31867 10.0941C9.44645 9.96631 9.60478 9.90243 9.79367 9.90243H11.7937C12.3492 9.90243 12.8214 9.70798 13.2103 9.31909C13.5992 8.9302 13.7937 8.45798 13.7937 7.90243C13.7937 7.34687 13.5992 6.87465 13.2103 6.48576C12.8214 6.09687 12.3492 5.90243 11.7937 5.90243H9.79367C9.60478 5.90243 9.44645 5.83854 9.31867 5.71076C9.19089 5.58298 9.127 5.42465 9.127 5.23576C9.127 5.04687 9.19089 4.88854 9.31867 4.76076C9.44645 4.63298 9.60478 4.56909 9.79367 4.56909H11.7937C12.7159 4.56909 13.502 4.89409 14.152 5.54409C14.802 6.19409 15.127 6.9802 15.127 7.90243C15.127 8.82465 14.802 9.61076 14.152 10.2608C13.502 10.9108 12.7159 11.2358 11.7937 11.2358H9.79367Z"
        fill={hex}
      />
    </svg>
  )
}
