import React, { FC, useState } from 'react'
import { PhoneNumber } from 'libphonenumber-js'
import { LinkButton } from '@/atoms/Button'
import { ParagraphMD } from '@/atoms/Text'
import { RequestShowtimesModal } from '@/organisms/RequestShowtimesModal'
import { TheatricalShowtimeVenue } from '@/types/codegen-federation'
import { useSafeAnalytics } from '@/utils/analytics'
import { useTranslate } from '@/utils/translate/translate-client'

interface ShowtimesWithNoUrlsStateProps {
  theatricalSlug: string
  venue: TheatricalShowtimeVenue
  venuePhoneNumber: PhoneNumber | undefined
  analyticsPayload: {
    venue: TheatricalShowtimeVenue
    latitude: number | undefined
    longitude: number | undefined
    theatricalName: string
    theatricalSlug: string
    projectSlug: string
  }
}

const EmptyShowtimesState: FC<ShowtimesWithNoUrlsStateProps> = ({ analyticsPayload, venuePhoneNumber }) => {
  const { t } = useTranslate('tickets')
  const [showRequestShowtimesModal, setShowRequestShowtimesModal] = useState(false)
  const { track } = useSafeAnalytics()

  const closeModal = () => {
    setShowRequestShowtimesModal(false)
  }

  return (
    <div className="my-2 block w-full justify-between">
      <div className="flex flex-col gap-4">
        {!!venuePhoneNumber && (
          <>
            <div className="rounded-xl bg-core-gray-100 p-4">
              <ParagraphMD color="core-gray-700">
                {t(
                  'movieNotPlayingYet',
                  "{{movieName}} isn't playing at this theater yet, but you can help make it happen! Give them a call and let them know you'd buy tickets if they show the movie!",
                  { movieName: analyticsPayload.theatricalName },
                )}
              </ParagraphMD>
              <div className="flex pt-5">
                <LinkButton
                  href={venuePhoneNumber.getURI()}
                  variant="black"
                  onClick={() => {
                    track('Tickets Venue Phone Number Clicked', analyticsPayload)
                  }}
                >
                  {t('callVenueNumber', 'Call: {{phoneNumber}}', { phoneNumber: venuePhoneNumber.formatNational() })}
                </LinkButton>
              </div>
            </div>
          </>
        )}
      </div>
      <RequestShowtimesModal
        showModal={showRequestShowtimesModal}
        onClose={closeModal}
        analyticsPayload={analyticsPayload}
      />
    </div>
  )
}

export default EmptyShowtimesState
