import React, { useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'
import { motion } from 'framer-motion'
import ScenePlayer from '@/views/TicketCheckoutViews/ShowtimesView/components/ScenePlayer'

interface TicketVideoModalProps {
  sceneId: string
  containerRef?: React.RefObject<HTMLDivElement>
  scrollToTop: () => void
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const variants = {
  open: { width: '100vw', height: '100vh' },
  closed: { width: '100%', height: 'auto' },
}

const containerVariants = {
  open: { backgroundColor: 'rgba(0, 0, 0, 0.9)' }, // Initial color with full opacity
  closed: { backgroundColor: 'rgba(0, 0, 0, 0)' }, // Target color with lower opacity
}
const TicketSceneTrailer = ({ sceneId, containerRef, scrollToTop, isOpen, setIsOpen }: TicketVideoModalProps) => {
  const [getShowtimeBtnClicked, setGetShowtimeBtnClicked] = useState(false)

  useEffect(() => {
    if (containerRef?.current) {
      if (isOpen) {
        containerRef.current.style.overflow = 'hidden'
      } else {
        containerRef.current.style.overflow = 'auto'
      }
    }
  }, [containerRef, isOpen])

  const closeFullScreen = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  const handleGetShowtimesClick = useCallback(() => {
    closeFullScreen()
    setGetShowtimeBtnClicked(true)
  }, [closeFullScreen])

  useEffect(() => {
    if (getShowtimeBtnClicked) {
      scrollToTop()
    }
  }, [getShowtimeBtnClicked, scrollToTop])

  return (
    <>
      <div className={classNames('flex w-full items-center justify-center', !isOpen && 'aspect-h-9 aspect-w-16')}>
        <motion.div
          initial={isOpen ? variants?.open : variants?.closed}
          transition={{ duration: 0.4 }}
          animate={isOpen ? 'open' : 'closed'}
          variants={variants}
          className={classNames(
            'absolute inset-0 z-[999999] flex items-center justify-center bg-black/45 rounded-xl',
            isOpen && 'lg:-top-16',
          )}
        >
          <motion.div
            onClick={closeFullScreen}
            animate={isOpen ? 'open' : 'closed'}
            variants={containerVariants}
            transition={{ duration: 0.2 }}
            className="flex h-full w-full items-center justify-center bg-black/95"
          >
            <ScenePlayer
              id={sceneId}
              handleGetShowtimesClick={handleGetShowtimesClick}
              onClose={closeFullScreen}
              isFullScreen={isOpen}
              handleCloseFullScreen={closeFullScreen}
              sceneId={sceneId}
            />
          </motion.div>
        </motion.div>
      </div>
    </>
  )
}

export default TicketSceneTrailer
