import React from 'react'
import classNames from 'classnames'
import { Placeholder } from '@/atoms/Placeholder'
import { useThemeContext } from '@/contexts/ThemeContext'

const VenueListSkeleton = () => {
  const { isDarkMode } = useThemeContext()
  return (
    <div className="flex h-full min-h-[60vh] flex-col gap-3">
      <Placeholder
        placeholderClassName={classNames(isDarkMode ? 'bg-core-gray-900' : 'bg-core-gray-200')}
        className="h-32 w-full rounded-lg bg-gray-500"
      />
      <Placeholder
        placeholderClassName={classNames(isDarkMode ? 'bg-core-gray-900' : 'bg-core-gray-200')}
        className="h-32 w-full rounded-lg bg-gray-500"
      />
      <Placeholder
        placeholderClassName={classNames(isDarkMode ? 'bg-core-gray-900' : 'bg-core-gray-200')}
        className="h-32 w-full rounded-lg bg-gray-500"
      />
      <Placeholder
        placeholderClassName={classNames(isDarkMode ? 'bg-core-gray-900' : 'bg-core-gray-200')}
        className="h-32 w-full rounded-lg bg-gray-500"
      />
      <Placeholder
        placeholderClassName={classNames(isDarkMode ? 'bg-core-gray-900' : 'bg-core-gray-200')}
        className="h-32 w-full  rounded-lg bg-gray-500"
      />
      <Placeholder
        placeholderClassName={classNames(isDarkMode ? 'bg-core-gray-900' : 'bg-core-gray-200')}
        className="h-32 w-full rounded-lg bg-gray-500"
      />
      <Placeholder
        placeholderClassName={classNames(isDarkMode ? 'bg-core-gray-900' : 'bg-core-gray-200')}
        className="h-32 w-full  rounded-lg bg-gray-500"
      />
    </div>
  )
}

export default VenueListSkeleton
