import { useCallback, useEffect, useMemo, useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { useRouter } from 'next/router'
import {
  InitializeReservationChangeMutation,
  InitializeReservationChangeMutationVariables,
  InitializeReservationMutation,
  InitializeReservationMutationVariables,
  ReservationObject,
} from '@/types/codegen-federation'
import { useSafeAnalytics } from '@/utils/analytics'
import useHandleKnownError from '@/views/TicketCheckoutViews/hooks/useHandleKnownError'
import useHandleUnexpectedError from '@/views/TicketCheckoutViews/hooks/useHandleUnexpectedError'
import { getDiscountCodes, getFlags } from '@/views/TicketCheckoutViews/utils'
import { KnownReservationErrors, TheatricalErrorContext } from '@/views/TicketCheckoutViews/utils/errorTrackingUtils'

export const INIT_RESERVATION_FRAGMENT = gql`
  fragment InitReservationFragment on ReservationObject {
    id
    isChangeReservation
    showtimeId
    priorReservation {
      id
      showtimeId
      theatricalSlug
      seatIds
    }
    venueSummary {
      name
      address {
        countryCode
        city
        zipCode
        line
        state
      }
    }
    showtimeSummary {
      localStartTimeFriendlyString
      localStartDateFriendlyString
      utcStartTime
    }
    pendingDiscounts {
      id
      name
      description
      value
      type
      category
    }
  }
`

const INITIALIZE_RESERVATION = gql`
  ${INIT_RESERVATION_FRAGMENT}
  mutation initializeReservation($input: InitializeReservationInput!) {
    initializeReservation(input: $input) {
      reservation {
        ...InitReservationFragment
      }
      errors {
        reference
        description
      }
    }
  }
`
const INITIALIZE_RESERVATION_CHANGE = gql`
  ${INIT_RESERVATION_FRAGMENT}
  mutation initializeReservationChange($input: InitializeReservationChangeInput!) {
    initializeReservationChange(input: $input) {
      reservation {
        ...InitReservationFragment
      }
      errors {
        reference
        description
      }
    }
  }
`

interface Props {
  theatricalSlug: string
  skip?: boolean
}

export const useInitReservation = ({ theatricalSlug, skip }: Props) => {
  const [errors, setErrors] = useState<KnownReservationErrors | null>(null)
  const [reservation, setReservation] = useState<ReservationObject | null>(null)
  const { handleUnexpectedError } = useHandleUnexpectedError({ reservationId: 'UNKNOWN' })
  const { handleKnownError } = useHandleKnownError()
  const { track } = useSafeAnalytics()
  const { query, isReady } = useRouter()

  const [initializeReservation, { loading: isInitReservationLoading }] = useMutation<
    InitializeReservationMutation,
    InitializeReservationMutationVariables
  >(INITIALIZE_RESERVATION, {
    errorPolicy: 'all',
    onError: (error) => {
      handleUnexpectedError({ context: 'InitializeReservationOnError', error })
    },
  })

  const [initializeReservationChange, { loading: isInitReservationChangeLoading }] = useMutation<
    InitializeReservationChangeMutation,
    InitializeReservationChangeMutationVariables
  >(INITIALIZE_RESERVATION_CHANGE, {
    errorPolicy: 'all',
  })

  const loading = isInitReservationLoading || isInitReservationChangeLoading

  const handleErrors = useCallback(
    (errors: KnownReservationErrors | undefined, context: TheatricalErrorContext, reservationId?: string) => {
      if (errors?.length && errors.length > 0) {
        handleKnownError({ errors, context, reservationId })
        setErrors(errors)
      }
    },
    [handleKnownError],
  )

  const handleSuccess = useCallback(
    (
      reservation: ReservationObject | null | undefined,
      trackEvent: 'Initialized Ticket Reservation' | 'Initialized Ticket Reservation Change',
      reservationId?: string,
    ) => {
      if (reservation) {
        track(`${trackEvent}`, {
          benefit: 'tickets',
          theatricalSlug,
          reservationId,
        })

        setReservation(reservation)
      }
    },
    [theatricalSlug, track],
  )

  const initReservation = useCallback(
    async (input: InitializeReservationMutationVariables['input']) => {
      try {
        const response = await initializeReservation({
          variables: {
            input,
          },
        })

        const data = response?.data?.initializeReservation
        const errors = data?.errors

        if (errors && errors.length > 0) {
          handleErrors(errors as KnownReservationErrors, 'InitializeReservation', data?.reservation?.id)
          return
        }

        handleSuccess(data?.reservation as ReservationObject, 'Initialized Ticket Reservation', data?.reservation?.id)
      } catch (error) {
        handleUnexpectedError({ context: 'InitializeReservation', error, extraMetadata: { queryInput: input } })
      }
    },
    [initializeReservation, handleErrors, handleSuccess, handleUnexpectedError],
  )

  const initReservationChange = useCallback(
    async (reservationChangeId: string) => {
      try {
        const response = await initializeReservationChange({
          variables: {
            input: {
              reservationId: reservationChangeId,
            },
          },
        })

        const data = response?.data?.initializeReservationChange
        const errors = data?.errors

        if (errors && errors.length > 0) {
          handleErrors(data?.errors as KnownReservationErrors, 'InitializeReservation', data?.reservation?.id)
          return
        }

        handleSuccess(data?.reservation as ReservationObject, 'Initialized Ticket Reservation', data?.reservation?.id)
      } catch (error) {
        handleUnexpectedError({ context: 'InitializeReservationChange', error })
      }
    },
    [handleErrors, handleSuccess, handleUnexpectedError, initializeReservationChange],
  )

  useEffect(() => {
    if (!isReady) return
    if (skip) return
    if (reservation) return

    const discountCodes = getDiscountCodes({ query })
    const flags = getFlags({ query })
    const friendReservationId = query?.reservationId as string
    const reservationChangeId = query?.editReservationId as string

    if (reservationChangeId) {
      initReservationChange(reservationChangeId)
      return
    }

    if (friendReservationId) {
      initReservation({ discountCodes, flags, friendReservationId, theatricalSlug })
      return
    }
    initReservation({ discountCodes, flags, theatricalSlug })
  }, [initReservation, initReservationChange, isReady, query, reservation, skip, theatricalSlug])

  return useMemo(() => ({ reservation, loading, errors }), [errors, loading, reservation])
}
