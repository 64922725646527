import React from 'react'
import classNames from 'classnames'
import { Button } from '@/atoms/Button'
import { MinusIcon } from '@/atoms/Icons/MinusIcon'
import { PlusIcon } from '@/atoms/Icons/PlusIcon'
import { TitleMD } from '@/atoms/Text'
import { useTranslate } from '@/utils/translate/translate-client'

export interface MultiFreeTicketsProps {
  handleTicketCountChange: (action: string, maxTickets: number) => void
  ticketCount: number
  maxTickets: number
}

export const MultiFreeTickets: React.VFC<MultiFreeTicketsProps> = ({
  handleTicketCountChange,
  ticketCount,
  maxTickets,
}) => {
  const { t } = useTranslate('tickets')
  const reachedTicketLimit = ticketCount >= maxTickets
  const minTicketsSelected = ticketCount === 1

  return (
    <div className="mt-6 flex w-full flex-row px-2 align-middle sm:px-0">
      <TitleMD
        className="photon-title-xs sm:photon-title-s flex items-center pr-3 !normal-case sm:pr-0"
        weight="semibold"
      >
        {t('howManyTicketsNeed', 'How many tickets do you need?')}
      </TitleMD>
      <div className="flex h-6 flex-row items-center justify-center align-middle sm:h-8 ">
        <Button
          variant="plain"
          onClick={() => handleTicketCountChange('decrement', maxTickets)}
          className={classNames(
            minTicketsSelected ? 'cursor-not-allowed' : 'hover:border-gray-600 cursor-pointer ease-in-out duration-200',
            'flex justify-center items-center ml-2 rounded-full border border-gray-300 border-t-gray-300 p-0 h-full max-h-6 w-[1.5rem] sm:max-h-8 sm:w-[34px] border-solid',
          )}
        >
          <MinusIcon size={20} color={minTicketsSelected ? 'gray-400' : 'black'} />
        </Button>
        <div className="photon-title-xs sm:photon-title-sm flex w-8 items-center justify-center">{ticketCount}</div>
        <Button
          variant="plain"
          onClick={() => handleTicketCountChange('increment', maxTickets)}
          className={classNames(
            reachedTicketLimit ? 'cursor-not-allowed' : 'hover:border-gray-600 cursor-pointer ease-in-out duration-200',
            'flex justify-center items-center rounded-full border border-gray-300 border-t-gray-300 p-0 h-full max-h-6 w-[1.5rem] sm:max-h-8 sm:w-[34px] border-solid',
          )}
        >
          <PlusIcon size={20} color={reachedTicketLimit ? 'gray-400' : 'black'} />
        </Button>
      </div>
    </div>
  )
}
