import React from 'react'
import classNames from 'classnames'
import { isAfter, startOfDay } from 'date-fns'
import { CircleStarIcon } from '@/atoms/Icons/CircleStarIcon'
import { AsP, EyebrowMD, EyebrowSM, HeadingSM } from '@/atoms/Text'
import { useLocale } from '@/utils/LocaleUtil'

interface DateCardProps {
  date: Date
  disabled: boolean
  onClick: () => void
  selected: boolean
  useDivider?: boolean
}

export const SpecialDateCard: React.FC<DateCardProps> = ({ date, disabled, onClick, selected, useDivider = false }) => {
  const { locale } = useLocale()
  const hasEnded = isAfter(startOfDay(new Date()), startOfDay(date))

  return (
    <div className="relative">
      <div
        className={classNames('w-[72px] h-24 relative rounded-xl ease-in-out text-gray-900 border-[1.5px]', {
          'cursor-not-allowed opacity-30': disabled || hasEnded,
          'cursor-pointer hover:scale-105 hover:bg-white': !disabled && !hasEnded,
          '!border-error-300 !bg-error-100': !selected,
          '!border-transparent !bg-error-700 shadow-[0_8px_16px_-1px_#F45A3B59]': selected,
        })}
        onClick={() => {
          if (disabled || hasEnded) return
          onClick()
        }}
      >
        <CircleStarIcon size={16} className="absolute right-1 top-1" color={selected ? 'white' : 'error-600'} />
        <div className="flex h-full flex-col justify-center pt-0.5 text-center">
          <EyebrowMD
            color="core-gray-700"
            className={classNames('uppercase', {
              '!text-error-100': selected,
              '!text-error-600': !selected,
            })}
          >
            {date.toLocaleString(locale, { month: 'short' })}
          </EyebrowMD>
          <HeadingSM
            as={AsP}
            weight="bold"
            className={classNames('leading-[36px] tracking-[-1px]', {
              '!text-white': selected,
              '!text-error-900': !selected,
            })}
          >
            {date.getDate()}
          </HeadingSM>
          <EyebrowSM
            color="core-gray-700"
            className={classNames('uppercase', {
              '!text-error-100': selected,
              '!text-error-600': !selected,
            })}
          >
            {date.toLocaleString(locale, { weekday: 'short' })}
          </EyebrowSM>
        </div>
        {selected && (
          <div
            className={classNames('absolute bottom-1 right-1/2 h-1 w-3 translate-x-2/4 rounded-full bg-oxide-bright')}
          ></div>
        )}
      </div>
      {useDivider && (
        <div className="absolute -right-3 top-1/2 z-10 h-[60px] w-[1px] -translate-y-1/2 rounded bg-core-gray-300" />
      )}
    </div>
  )
}
