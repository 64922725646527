import React, { FC, useEffect, useRef } from 'react'
import YouTube, { YouTubePlayer } from 'react-youtube'
import { LoadingSpinnerIcon } from '@/atoms/Icons/LoadingSpinnerIcon'

interface Props {
  id: string
  loading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  shouldAutoPlay?: boolean
  sceneId: string
  onClose: () => void
  title?: string
}
const SceneVideoPlayer: FC<Props> = ({
  loading,
  setIsLoading,
  sceneId,
  onClose,
  id,
  shouldAutoPlay,
  title = 'Movie Scene',
}) => {
  const playerRef = useRef<YouTubePlayer>(null)

  useEffect(() => {
    return () => {
      playerRef?.current?.clearVideo()
    }
  }, [setIsLoading])

  return (
    <div className="lg:rounded-xl">
      <span className="aspect-h-9 aspect-w-16 block">
        {loading && (
          <div className="z-50 flex items-center justify-center rounded-xl">
            <LoadingSpinnerIcon color="white" size={42} />
          </div>
        )}
        <YouTube
          onReady={(e) => {
            setIsLoading(false)
            playerRef.current = e.data
          }}
          opts={{ playerVars: { autoplay: shouldAutoPlay ? 1 : 0, rel: 0 } }}
          iframeClassName="md:rounded-xl h-full w-full"
          videoId={sceneId}
          id={id}
          className="bg-core-gray-950 md:rounded-xl"
          title={title}
          onEnd={onClose}
        />
      </span>
    </div>
  )
}

export default SceneVideoPlayer
