import { FilledTicketIcon } from '@/atoms/Icons/FilledTicketIcon'
import { CaptionMD, TitleSM } from '@/atoms/Text'
import { Accordion, AccordionItem } from '@/molecules/Accordion'
import { useTranslate } from '@/utils/translate/translate-client'
import { CopyValueButton } from '@/views/TicketCheckoutViews/ShowtimesView/components/CopyValueButton'

interface TicketDiscountCodesModalProps {
  discounts: string[]
  isAutoApplied?: boolean
  isGiftFlow?: boolean
}
const TicketDiscountCodesModal = ({
  discounts,
  isAutoApplied = true,
  isGiftFlow = false,
}: TicketDiscountCodesModalProps) => {
  const { t } = useTranslate('tickets')

  return (
    <div className="fixed inset-x-0 bottom-0 z-[99] md:bottom-5 md:left-auto md:right-5">
      <Accordion className="max-h-[220px] w-full overflow-y-scroll border-[1.5px] border-core-gray-200 bg-white px-6 shadow-xl md:max-h-[240px] md:w-[400px] md:rounded-2xl">
        <AccordionItem
          iconColor="core-gray-950"
          shouldUseCaretIcon
          defaultOpen
          labelClassName="text-core-gray-950"
          label={
            <div className="mr-2 flex items-center gap-1">
              <FilledTicketIcon color="core-gray-950" />
              <TitleSM weight="bold">
                {isGiftFlow
                  ? t('youWereGiftedTickets', "You've been gifted tickets!")
                  : t('ticketDiscountCodes', 'Ticket Discount Codes')}
              </TitleSM>
            </div>
          }
        >
          <CaptionMD color="core-gray-700" className="md:text-sm">
            {isAutoApplied
              ? t(
                  'participatingGiftedTicketsDiscountDescription',
                  'Select your showtime at a participating venue to redeem your tickets.',
                )
              : t(
                  'participatingCodesDescription',
                  'Select your showtime at a participating venue and enter your discount codes at checkout.',
                )}
          </CaptionMD>
          {!isAutoApplied && (
            <div className="mt-3 flex flex-col gap-2">
              {discounts.map((discount) => {
                return <CopyValueButton key={discount} className="!rounded-xl" value={discount} />
              })}
            </div>
          )}
        </AccordionItem>
      </Accordion>
    </div>
  )
}

export default TicketDiscountCodesModal
