import { useEffect, useMemo, useState } from 'react'
import Bugsnag from '@bugsnag/js'
import { useRouter } from 'next/router'
import { slugs } from '@/constants'
import { useRegionContext } from '@/contexts/RegionContext'
import { useExperimentContext } from '@/experimentation'
import { getWebClient } from '@/services/ApolloClient'
import { useUserGuildTickets } from '@/services/GuildUserService'
import { getNextRouterQueryParam } from '@/views/GuildCheckoutSuccessView/utils'
import { useInitReservation } from '@/views/TicketCheckoutViews/hooks/useInitReservation'

export function useHomesteadGiveawayBanner(theatricalSlug: string): 'guild' | 'general' | 'pre-order' | 'none' {
  // TODD: commented out while we're finalizing banner experimentation/logic

  const client = getWebClient()
  const { numberAvailable, loading: loadingUserGuild } = useUserGuildTickets({
    theatricalSlug: 'homestead',
    client,
    skip: theatricalSlug !== slugs.homestead,
  })
  const { reservation, loading: loadingReservation } = useInitReservation({ theatricalSlug })
  const { region } = useRegionContext()
  const { getFeatureValue } = useExperimentContext()
  const router = useRouter()

  return useMemo(() => {
    if (theatricalSlug !== slugs.homestead || region !== 'US' || loadingUserGuild || loadingReservation) return 'none'

    const flowQueryParam = getNextRouterQueryParam('flow', router)
    const discountsQueryParam = getNextRouterQueryParam('discounts', router)

    // if in guild email blast flow, show guild banner
    if (flowQueryParam === 'guild-email-blast') {
      return 'guild'
    }

    // also show guild banner if user has guild tickets and the feature flag is enabled
    if (numberAvailable >= 2 && getFeatureValue('homestead_giveaway_showtime_banner', false)) {
      return 'guild'
    }

    // show pre-order banner if user has presale pending discounts
    if (reservation?.pendingDiscounts?.some((discount) => discount.category === 'PRESALE')) {
      return 'pre-order'
    }

    // show general banner to logged-out users if we're not showing other discounts
    if (!discountsQueryParam) return 'general'

    return 'none'
  }, [
    theatricalSlug,
    region,
    loadingUserGuild,
    loadingReservation,
    router,
    numberAvailable,
    getFeatureValue,
    reservation?.pendingDiscounts,
  ])
}

export function useMultiplier() {
  const [multiplier, setMultiplier] = useState<number | null>(null)

  useEffect(() => {
    if (multiplier === null) {
      fetch('https://theatrical.angelstudios.com/api/v1/open/weeks-until/homestead')
        .then((res) => res.json())
        .then((data) => (data.weeks_until_release ? setMultiplier(data.weeks_until_release) : null))
        .catch((error) => {
          Bugsnag.notify(error)
        })
    }
  }, [multiplier])

  return multiplier
}
