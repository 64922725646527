import React from 'react'
import { getHexColor, OneColorIconProps } from '../utils'

export const FilledTicketIcon: React.FC<OneColorIconProps> = ({ size = 24, color = 'white', className }) => {
  const stroke = getHexColor(color)

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M2.5 6C2.5 4.89543 3.39543 4 4.5 4H8V20H4.5C3.39543 20 2.5 19.1046 2.5 18V14.5C3.88071 14.5 5 13.3807 5 12C5 10.6193 3.88071 9.5 2.5 9.5V6Z"
        fill={stroke}
      />
      <path
        d="M11 20H20.5C21.6046 20 22.5 19.1046 22.5 18V14.5C21.1193 14.5 20 13.3807 20 12C20 10.6193 21.1193 9.5 22.5 9.5V6C22.5 4.89543 21.6046 4 20.5 4H11V20Z"
        fill={stroke}
      />
    </svg>
  )
}
