import React, { FC } from 'react'
import { TheatricalPromoType } from '@/services/Theatrical/contentfulQueries'
import { ReservationObject, TheatricalReleaseObject } from '@/types/codegen-federation'
import { useTranslate } from '@/utils/translate/translate-client'
import { DiscountType, useTicketsContext } from '@/views/TicketCheckoutViews/ShowtimesView/components/TicketsContext'
import ChangeReservationBanner from '@/views/TicketCheckoutViews/ShowtimesView/components/banners/ChangeReservationBanner'
import { TicketPromoBanner } from '@/views/TicketCheckoutViews/ShowtimesView/components/banners/DiscountBanner'
import { GuildTicketsBanner } from '@/views/TicketCheckoutViews/ShowtimesView/components/banners/GuildTicketsBanner'
import { isAngelIntegratedCountry } from '@/views/TicketCheckoutViews/ShowtimesView/utils/utils'
import { useGuildTickets } from '@/views/TicketCheckoutViews/hooks/useGuildTickets'
import { GeneralHomesteadGiveawayBanner } from './banners/Homestead/GeneralHomesteadGiveawayBanner'
import { GuildHomesteadGiveawayBanner } from './banners/Homestead/GuildHomesteadGiveawayBanner'
import { PreOrderHomesteadGiveawayBanner } from './banners/Homestead/PreOrderHomesteadBanner'
import { useHomesteadGiveawayBanner } from './banners/Homestead/utils'

interface BannerSectionProps {
  reservation?: ReservationObject
  isEditReservationFlow: boolean
  theatricalRelease: TheatricalReleaseObject
  promo?: TheatricalPromoType
  calendarRef?: React.RefObject<HTMLDivElement>
}

const TicketBannerSection: FC<BannerSectionProps> = ({
  promo,
  reservation,
  isEditReservationFlow,
  theatricalRelease,
  calendarRef,
}) => {
  const { t } = useTranslate('tickets')
  const { discount } = useTicketsContext()
  const { areGuildTicketsAvailable, discountCodes } = useGuildTickets(theatricalRelease?.theatricalSlug)
  const countryCode = theatricalRelease?.region?.countryCode
  const homesteadBannerType = useHomesteadGiveawayBanner(theatricalRelease?.theatricalSlug)

  return (
    <>
      {reservation?.id && !isEditReservationFlow && isAngelIntegratedCountry(countryCode) && (
        <TicketPromoBanner
          theatricalSlug={theatricalRelease?.theatricalSlug}
          className="hidden w-full md:flex"
          promo={promo}
          discount={discount as DiscountType}
          calendarRef={calendarRef}
        />
      )}

      {reservation?.priorReservation?.id &&
        reservation?.isChangeReservation &&
        isAngelIntegratedCountry(countryCode) && (
          <ChangeReservationBanner
            description={t('pleaseSelectANewShowtimeBelow', 'Please select a new showtime below.')}
            priorReservation={reservation?.priorReservation}
            step={1}
          />
        )}
      {areGuildTicketsAvailable && (
        <GuildTicketsBanner
          discountCodes={discountCodes}
          projectName={theatricalRelease?.title ?? ''}
          theatricalSlug={theatricalRelease?.theatricalSlug}
          className="overflow-hidden lg:hidden"
        />
      )}
      {homesteadBannerType !== 'none' && (
        <div className="hidden md:block">
          {homesteadBannerType === 'guild' && <GuildHomesteadGiveawayBanner />}
          {homesteadBannerType === 'general' && <GeneralHomesteadGiveawayBanner />}
          {homesteadBannerType === 'pre-order' && <PreOrderHomesteadGiveawayBanner />}
        </div>
      )}
    </>
  )
}

export default TicketBannerSection
