import { OpenGraph } from 'next-seo/lib/types'
import { paths } from '@/constants/paths'
import { TheatricalReleaseObject } from '@/types/codegen-federation'
import { getCloudinaryImageUrl } from '@/utils/Cloudinary'
import { useTranslate } from '@/utils/translate/translate-client'

export function useSeoData(
  config: TheatricalReleaseObject,
  path: string,
): Required<Pick<OpenGraph, 'description' | 'title' | 'url'>> & OpenGraph {
  const { t } = useTranslate('tickets')
  const url = `${paths.base}${path}`.split('?')[0]
  const theatricalName = config.title
  const title = t('ticketsSEOTitle', `{{theatricalName}} Tickets & Showtimes | Angel Studios`, { theatricalName })
  const description = t(
    'ticketsSEODescription',
    'Buy {{theatricalName}} tickets, watch trailers and find showtimes near you.',
    {
      theatricalName,
    },
  )

  return {
    title,
    description,
    url,
    type: 'video.movie',
    site_name: 'Angel Studios',
    images: [
      {
        url: getCloudinaryImageUrl({ path: config?.region?.seoImage ?? '' }),
        height: 630,
        width: 1200,
        alt: description,
      },
    ],
  }
}
