import React, { FC, useCallback, useEffect, useState } from 'react'
import { FlexRow } from '@/atoms/FlexContainers'
import { CircleInfoIcon } from '@/atoms/Icons/CircleInfoIcon'
import { CloseIcon } from '@/atoms/Icons/CloseIcon'
import { Text } from '@/atoms/Text'
import { getBooleanFromLocalStorage, writeToLocalStorage } from '@/utils/local-storage'
import { useTranslate } from '@/utils/translate/translate-client'

interface Props {
  theatricalSlug: string
}

const ExclusiveShowtimeDateWarning: FC<Props> = ({ theatricalSlug }) => {
  const { t } = useTranslate('tickets')
  const [shouldShowWarning, setShouldShowWarning] = useState(false)

  const key = `exclusive-showtime-${theatricalSlug}-closed`

  useEffect(() => {
    const hasClosedWarning = getBooleanFromLocalStorage(key)
    if (!hasClosedWarning) setShouldShowWarning(true)
  }, [key])

  const onClose = useCallback(() => {
    writeToLocalStorage(key, true)
    setShouldShowWarning(false)
  }, [key])

  if (!shouldShowWarning) return null

  return (
    <FlexRow className="gap-2 rounded-md border-[1.5px] border-info-300 bg-info-100 p-4 shadow-light-1">
      <CircleInfoIcon className="shrink-0 md:h-[24px] md:!w-[24px]" size={20} color="info-500" />
      <Text className="photon-caption-sm md:photon-caption-md" color="info-700">
        {t(
          'exclusiveDateOffersLimitedShowtimes',
          'This exclusive date offers limited showtimes and might not be available in your area.',
        )}
      </Text>
      <button onClick={onClose} className="ml-auto shrink-0">
        <CloseIcon color="info-300" className="md:h-[20px] md:!w-[20px]" size={16} />
      </button>
    </FlexRow>
  )
}

export default ExclusiveShowtimeDateWarning
