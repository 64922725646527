import React, { FC, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { Button } from '@/atoms/Button'
import { CheckIcon } from '@/atoms/Icons/CheckIcon'
import { ShareLinkIcon } from '@/atoms/Icons/ShareLinkIcon'
import { LabelSM, TitleXS } from '@/atoms/Text'
import { Translate, useTranslate } from '@/utils/translate/translate-client'

interface CopyCodeButtonProps {
  value: string
  className?: string
}

export const CopyValueButton: FC<CopyCodeButtonProps> = ({ value, className }) => {
  const { t } = useTranslate('account')
  const [isCopiedMessageDisplayed, setIsCopiedMessageDisplayed] = useState(false)
  const timeoutRef = useRef<NodeJS.Timeout>()

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  const onClick = () => {
    navigator.clipboard.writeText(value)
    setIsCopiedMessageDisplayed(true)

    const timeout = setTimeout(() => {
      setIsCopiedMessageDisplayed(false)
    }, 2000)

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = timeout
  }

  return (
    <Button
      disabled={isCopiedMessageDisplayed}
      className={classNames(
        'flex rounded-2xl p-3 items-center transition-all duration-[3000] min-h-[44px]',
        isCopiedMessageDisplayed ? 'bg-core-gray-400 justify-center' : 'bg-core-gray-200 justify-between',
        className,
      )}
      variant="plain"
      onClick={onClick}
    >
      {isCopiedMessageDisplayed ? (
        <Translate t={t} i18nKey="copiedValue" values={{ value }}>
          <CheckIcon size={16} color="core-gray-950" />
          <LabelSM weight="bold">Copied</LabelSM>
        </Translate>
      ) : (
        <Translate t={t} i18nKey="copyValue" values={{ value }}>
          <TitleXS weight="bold">{{ value }}</TitleXS>
          <div className="flex gap-y-0.5">
            <ShareLinkIcon size={16} color="core-gray-950" />
            <LabelSM>Copy</LabelSM>
          </div>
        </Translate>
      )}{' '}
    </Button>
  )
}
