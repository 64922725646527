import React from 'react'
import { FlexRow } from '@/atoms/FlexContainers'
import { Image } from '@/atoms/Image'
import { ParagraphSM, TitleXS, CaptionMD } from '@/atoms/Text'
import { TheatricalReleaseObject } from '@/types/codegen-federation'
import { useTranslate } from '@/utils/translate/translate-client'
import { BaseTooltip } from './ToolTips'

const IMAGES = {
  VERIFIED_HOT: 'v1732750381/a209eb3767a02b08bb0045a8118a4ca4.png',
  POPCORN: 'v1688691818/angel-studios/misc-icons/popcorn.svg',
} as const

interface CriticScoreProps {
  theatricalRelease: TheatricalReleaseObject
}

const ScoreDisplay = ({ value, imageSrc }: { value: number; imageSrc: string }) => (
  <div className="flex items-center gap-2">
    <div className="h-1 w-1 rounded-full bg-core-gray-400" />
    <FlexRow className="gap-1">
      <Image className="rounded-xl shadow-xl" alt="" src={imageSrc} width={16} height={16} />
      <CaptionMD className="uppercase">{value}%</CaptionMD>
    </FlexRow>
  </div>
)

const TooltipContent = () => {
  const { t } = useTranslate('tickets')

  return (
    <>
      <TitleXS color="core-gray-950" weight="bold">
        {t('whatIsVerifiedHotTitle', 'Verified Hot Score')}
      </TitleXS>
      <ParagraphSM className="mt-2 text-[0.688rem] leading-[16px]" color="core-gray-700">
        {t(
          'whatIsVerifiedHot',
          'The Verified Hot status and badge is an elevated designation honoring the highest verified reviewed theatrical films by fans on Rotten Tomatoes. Verified Hot is composed of Verified Ratings and Reviews from the Popcornmeter score.',
        )}
      </ParagraphSM>
    </>
  )
}

export const CriticScore = ({ theatricalRelease }: CriticScoreProps) => {
  if (!theatricalRelease) return null
  const audienceScore = theatricalRelease?.critics
    ?.find((critic) => critic?.company === 'Rotten Tomatoes')
    ?.scores?.find((score) => score?.name === 'Audience')

  if (!audienceScore?.value || audienceScore.value < 70) {
    return null
  }

  const isVerifiedHot = audienceScore.value > 90
  const imageSrc = isVerifiedHot ? IMAGES.VERIFIED_HOT : IMAGES.POPCORN
  const scoreDisplay = <ScoreDisplay value={audienceScore.value} imageSrc={imageSrc} />

  if (!isVerifiedHot) {
    return <ScoreDisplay value={audienceScore.value} imageSrc={IMAGES.POPCORN} />
  }

  return (
    <BaseTooltip
      buttonClassName="!p-0 bg-black/10 p-1 bg-[#FFF] bg-opacity-20 border-0 !focus-visible:outline-0 focus-visible:ring-0 focus-visible:ring-transparent focus-visible:ring-offset-0"
      panelClassName="!z-[9999] !outline-none !top-[26px]"
      trigger={scoreDisplay}
      content={<TooltipContent />}
      removeBorder={false}
      panelPosition="CENTER"
    />
  )
}
