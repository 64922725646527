import React, { useEffect, useState } from 'react'
import { InView } from 'react-intersection-observer'
import { Button, LinkButton } from '@/atoms/Button'
import { FlexColumn } from '@/atoms/FlexContainers'
import { HeadingSM, ParagraphSM, Text } from '@/atoms/Text'
import { GroupTicketOption } from '@/types/codegen-contentful'
import { useSafeTrack } from '@/utils/analytics'
import { useEcommerceEvents } from '@/utils/analytics/ecommerce-events'
import { isStagingEnvironment } from '@/utils/environment-utils'
import { Translate, useTranslate } from '@/utils/translate/translate-client'
import { GroupTicketsV2Options } from '@/views/TicketCheckoutViews/ShowtimesView/components/modals/GroupTicketsModal/GroupTickets/GroupTicketsV2Options'
import { GroupTicketsModalTypes } from '@/views/TicketCheckoutViews/ShowtimesView/components/modals/GroupTicketsModal/GroupTicketsModal'

const GROUP_DISCOUNT_RATE = 0.5

interface GroupTicketsV1Props {
  setModalType: React.Dispatch<React.SetStateAction<GroupTicketsModalTypes>>
  groupTicketOptions: GroupTicketOption[]
  ticketPrice: number
  projectName: string
  theatricalSlug: string
  shouldUseNewDesign?: boolean
}

export const GroupTickets: React.FC<GroupTicketsV1Props> = ({
  setModalType,
  groupTicketOptions,
  ticketPrice,
  projectName,
  theatricalSlug,
  shouldUseNewDesign,
}) => {
  const { t } = useTranslate('tickets')
  const track = useSafeTrack()
  const { trackProductAdded, trackProductViewed } = useEcommerceEvents()
  const [groupTicketOption, setGroupTicketOption] = useState(
    groupTicketOptions?.[3] ?? groupTicketOptions?.[2] ?? groupTicketOptions?.[1] ?? groupTicketOptions?.[0],
  )
  const stripeUrl = isStagingEnvironment()
    ? groupTicketOption?.stripeStagingUrl ?? ''
    : groupTicketOption?.stripeUrl ?? ''

  useEffect(() => {
    track('Group Tickets Options Viewed')
  }, [track])

  const handleCtaClick = () => {
    // TODO: deprecated - remove after release of trackProductAdded and marketing's (Kylo's) blessing
    track('Group Tickets Checkout Started')

    trackProductAdded({
      category: 'group tickets',
      funnel: 'theatrical',
      name: `${groupTicketOption.title} ${theatricalSlug}`,
      price: Number((ticketPrice * GROUP_DISCOUNT_RATE).toFixed(2)),
      currency: 'USD',
      quantity: groupTicketOption?.totalTicketCount ?? 0,
      product_id: `group-tickets-${theatricalSlug}`,
      sku: `group-tickets-${theatricalSlug}`,
      url: groupTicketOption?.stripeUrl,
      projectSlug: theatricalSlug,
    })
  }

  return (
    <InView
      triggerOnce
      onChange={(inView) => {
        if (!inView) return

        trackProductViewed({
          category: 'group tickets',
          funnel: 'theatrical',
          name: `${groupTicketOption.title} ${theatricalSlug}`,
          price: Number((ticketPrice * GROUP_DISCOUNT_RATE).toFixed(2)),
          currency: 'USD',
          quantity: groupTicketOption?.totalTicketCount ?? 0,
          product_id: `group-tickets-${theatricalSlug}`,
          sku: `group-tickets-${theatricalSlug}`,
          url: groupTicketOption?.stripeUrl,
          projectSlug: theatricalSlug,
          value: groupTicketOption.totalTicketCount
            ? groupTicketOption.totalTicketCount * ticketPrice * GROUP_DISCOUNT_RATE
            : 0,
        })
      }}
    >
      {!shouldUseNewDesign && (
        <>
          <HeadingSM className="mt-8 text-center text-[1.75rem] sm:mt-0" color="core-gray-950" weight="bold">
            {t('flexGroupTickets', 'Flex Group Tickets')}
          </HeadingSM>
          <ParagraphSM
            color="core-gray-700"
            weight="medium"
            className="mb-6 mt-2 text-center leading-[26px] sm:px-1.5 md:px-2"
          >
            <Translate t={t} i18nKey="youCanUseTheseTicketsOnAnySoundOfFreedomV5" values={{ projectName }}>
              You can use these tickets on any {{ projectName }} showtime for qualified theaters. Ticket Prices are
              estimated at $15, but if your local theater prices are more, then you will have to pay the difference.{' '}
              <Button
                onClick={() => setModalType('faq')}
                className="inline font-semibold text-core-gray-950 underline"
                variant="plain"
              >
                Learn More
              </Button>
            </Translate>
          </ParagraphSM>
        </>
      )}

      {shouldUseNewDesign && (
        <>
          <Text
            className="photon-heading-xs md:photon-heading-sm block text-center"
            color="core-gray-950"
            weight="bold"
          >
            {t('flexGroupTickets', 'Flex Group Tickets')}
          </Text>
          <Text
            color="core-gray-700"
            weight="medium"
            className="photon-paragraph-sm md:photon-paragraph-md mt-1 block pb-6 text-center"
          >
            <Translate t={t} i18nKey="youCanUseTheseTicketsOnAnySoundOfFreedomV6">
              Tickets only available at Angel Partner Theaters. Ticket prices estimated at $15. Additional charges may
              apply.{' '}
              <Button
                onClick={() => setModalType('faq')}
                className="inline font-semibold text-core-gray-950 underline"
                variant="plain"
              >
                Learn More
              </Button>
            </Translate>
          </Text>
        </>
      )}
      <GroupTicketsV2Options
        setGroupTicketOption={setGroupTicketOption}
        groupTicketOption={groupTicketOption}
        groupTicketOptions={groupTicketOptions}
        ticketPrice={ticketPrice}
        shouldUseNewDesign={shouldUseNewDesign}
      />
      {!shouldUseNewDesign && (
        <LinkButton
          onClick={handleCtaClick}
          className="mx-auto flex w-fit items-center justify-center px-16 py-3"
          href={stripeUrl}
          variant="black"
        >
          {t('checkoutGroupTickets', 'Checkout')}
        </LinkButton>
      )}
      {shouldUseNewDesign && (
        <FlexColumn className="items-center gap-6">
          <LinkButton
            onClick={handleCtaClick}
            className="mx-auto flex w-full max-w-[380px] items-center justify-center rounded-[14px] px-7 py-[17px] md:w-fit"
            href={stripeUrl}
            variant="black"
          >
            {t('checkoutGroupTickets', 'Checkout')}
          </LinkButton>
        </FlexColumn>
      )}
    </InView>
  )
}
