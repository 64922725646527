import React from 'react'
import { CircleInfoIcon } from '@/atoms/Icons/CircleInfoIcon'
import { EyebrowMD, ParagraphSM, TitleXS } from '@/atoms/Text'
import { useTranslate } from '@/utils/translate/translate-client'
import { BaseTooltipProps } from '.'
import { BaseTooltip } from '.'

export const SpecificTimesToolTip: React.FC<BaseTooltipProps> = ({
  iconSize = 16,
  className,
  buttonClassName,
  panelClassName,
  panelPosition = 'CENTER',
  isIconOnly,
  buttonStyle,
  titleText,
  children,
}) => {
  const { t } = useTranslate('tickets')

  const trigger = (
    <>
      {!isIconOnly && children ? (
        children
      ) : (
        <EyebrowMD className="!text-core-gray-200">
          {titleText ? titleText : t('seeSpecificTimes', 'SEE SPECIFIC TIMES')}
        </EyebrowMD>
      )}
      <CircleInfoIcon size={iconSize} color={'core-gray-200'} />
    </>
  )

  const content = (
    <>
      <TitleXS color="core-gray-950" weight="bold">
        {t('specificShowTimesDetails', 'Specific Showtimes Details')}
      </TitleXS>
      <ParagraphSM className="mt-2 text-[0.688rem] leading-[16px]" color="core-gray-700">
        <EyebrowMD className="block">
          {t('monThruThurs', 'Mon–Thurs')} <span className="uppercase">({t('allDay', 'All Day')})</span>
        </EyebrowMD>
        <EyebrowMD>{t('friThruSun', 'Fri–Sun (Before 5pm)')}</EyebrowMD>
      </ParagraphSM>
    </>
  )

  return (
    <BaseTooltip
      className={className}
      buttonClassName={buttonClassName}
      panelClassName={panelClassName}
      panelPosition={panelPosition}
      isIconOnly={isIconOnly}
      removeBorder={true}
      buttonStyle={buttonStyle}
      trigger={trigger}
      content={content}
    />
  )
}
