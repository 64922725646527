import { useCallback, useMemo, useRef, useState } from 'react'
import Bugsnag from '@bugsnag/js'
import axios from 'axios'
import { debounce } from 'lodash'
import { useUser } from '@/services/UserService'
import { useLocale } from '@/utils/LocaleUtil'
import { UserLocation } from '@/utils/location/location'
import { cLogger } from '@/utils/logging/client-logger'
import { useTranslate } from '@/utils/translate/translate-client'

interface searchResult {
  geometry: {
    coordinates: [number, number]
  }
  properties: {
    label: string
    id: string
  }
}

export interface AddressSearchResult extends Omit<searchResult, 'geometry'> {
  geometry: {
    coordinates: {
      latitude: number
      longitude: number
    }
  }
}

interface Props {
  countryCode: string
  limitExperiment?: boolean
}

const extractCoordinates = (geometry: searchResult['geometry']) => {
  return {
    coordinates: {
      latitude: geometry?.coordinates?.[1],
      longitude: geometry?.coordinates?.[0],
    },
  }
}

const formatSearchResults = (searchResults: searchResult[] | undefined) => {
  if (!searchResults) return []
  return searchResults.map((result) => {
    return { ...result, geometry: extractCoordinates(result.geometry) }
  })
}
interface SearchParams {
  term: string
  lang: string
  abort: AbortController
  countryCode: string
  coordinates?: UserLocation['coordinates']
}

const searchForLocations = async ({ term, abort, lang, coordinates, countryCode }: SearchParams) => {
  if (!term) return []

  const params = {
    text: term,
    ['api_key']: process.env.NEXT_PUBLIC_TICKET_SEARCH_KEY,
    ['focus.point.lat']: coordinates?.latitude,
    ['focus.point.lon']: coordinates?.longitude,
    ['boundary.country']: countryCode,
    layers: '-country,-region,-continent,-street,-intersection,-address,-neighbourhood,-borough',
    lang,
  }

  const { data } = await axios.get('https://api.geocode.earth/v1/autocomplete?', {
    params,
    signal: abort.signal,
  })

  return formatSearchResults(data?.features)
}

const useAutoCompleteAddressSearch = ({ countryCode, limitExperiment }: Props) => {
  const { t } = useTranslate('tickets')
  const { locale } = useLocale()
  const { userLocation } = useUser()
  const [results, setResults] = useState<AddressSearchResult[] | []>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const abortRef = useRef(new AbortController())
  const searchCountryCode = useMemo(() => {
    if (countryCode === 'US' || countryCode === 'CA') return 'US,CA'
    return countryCode
  }, [countryCode])

  const search = useMemo(() => {
    return debounce(async (term: string) => {
      try {
        if (!term) {
          return setResults([])
        }
        setLoading(true)
        setError(null)
        abortRef.current.abort()
        abortRef.current = new AbortController()

        const searchResults = await searchForLocations({
          term,
          lang: locale,
          coordinates: userLocation?.coordinates,
          abort: abortRef.current,
          countryCode: searchCountryCode,
        })

        if (limitExperiment) {
          const results = searchResults ?? []
          setResults(results.slice(0, 5))
        } else {
          setResults(searchResults ?? [])
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        if (axios.isCancel(err)) return
        cLogger().warn('Failed to do ticket search!', err)
        Bugsnag.notify(new Error(`Failed to do ticket search: ${err.message}`))
        setError(err?.message ?? t('somethingWentWrongWhileSearching', 'Something went wrong while searching'))
      } finally {
        setLoading(false)
      }
    }, 500)
  }, [limitExperiment, locale, searchCountryCode, t, userLocation?.coordinates])

  const clearResults = useCallback(() => {
    setResults([])
  }, [])

  return useMemo(() => {
    return { search, results, clearResults, loading, error }
  }, [clearResults, error, loading, results, search])
}

export default useAutoCompleteAddressSearch
