import React, { FC, useEffect, useState } from 'react'
import classNames from 'classnames'
import { motion } from 'framer-motion'
import { FlexRow } from '@/atoms/FlexContainers'
import { AsP, CaptionSM, Text } from '@/atoms/Text'
import { ReactFCC } from '@/types/react'
import { useTranslate } from '@/utils/translate/translate-client'
import { getPeakMountainTime } from '@/views/TicketCheckoutViews/ShowtimesView/utils/utils'
import { useCountDown } from '@/views/TicketCheckoutViews/hooks/useCountDown'

interface TimeProps {
  time: string
  title: string
  animate?: boolean
  timeClassName?: string
  timeTitleClassName?: string
  className?: string
  isModal?: boolean
}

const Time: ReactFCC<TimeProps> = ({
  time,
  title,
  timeClassName,
  timeTitleClassName,
  className,
  animate = false,
  isModal,
}) => {
  return (
    <div
      className={classNames(
        'gap-1 shrink-0 flex',
        isModal ? 'flex-col !gap-0 !items-center justify-center' : '!items-baseline',
        className,
      )}
    >
      {!animate && (
        <Text
          as={AsP}
          className={classNames(
            isModal ? 'photon-title-md text-center w-[26px]' : 'photon-title-xs text-right w-[19px]',
            timeClassName,
          )}
          weight="bold"
        >
          {time}
        </Text>
      )}

      {animate && (
        <motion.div
          key={time}
          className="relative"
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -30 }}
        >
          <Text
            as={AsP}
            className={classNames(
              isModal ? 'photon-title-md text-center w-[26px]' : 'photon-title-xs text-right w-[19px]',
              timeClassName,
            )}
            weight="bold"
          >
            {time}
          </Text>
        </motion.div>
      )}
      <CaptionSM weight="medium" className={classNames(isModal && 'text-center', timeTitleClassName)}>
        {title}
      </CaptionSM>
    </div>
  )
}

interface TimeLeftProps {
  utcTargetDate?: string
  className?: string
  timeWrapperClassName?: string
  isModal?: boolean
}

const TimeLeft: FC<TimeLeftProps> = ({ utcTargetDate, className, timeWrapperClassName, isModal = false }) => {
  const { t } = useTranslate('tickets')
  const [countDownDate, setCountDownDate] = useState(utcTargetDate ?? getPeakMountainTime())
  const { timeLeft, isExpired } = useCountDown({ utcTargetDate: countDownDate, shouldPadSeconds: true })
  const { hours, minutes, seconds } = timeLeft

  useEffect(() => {
    if (isExpired && !utcTargetDate) setCountDownDate(getPeakMountainTime())
  }, [isExpired, utcTargetDate])

  return (
    <FlexRow className={classNames('shrink-0 justify-center gap-2 relative', className)}>
      <Time isModal={isModal} className={classNames(timeWrapperClassName)} time={hours} title={t('hrs', 'HRS')} />
      <Time
        isModal={isModal}
        timeClassName={classNames(!isModal && '!w-auto')}
        className={classNames(timeWrapperClassName)}
        time={minutes}
        title={t('min', 'MIN')}
      />
      <Time
        isModal={isModal}
        className={classNames(timeWrapperClassName)}
        time={seconds}
        title={t('sec', 'SEC')}
        animate
      />
    </FlexRow>
  )
}

export default TimeLeft
