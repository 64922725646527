import React from 'react'
import { CircleInfoIcon } from '@/atoms/Icons/CircleInfoIcon'
import { LabelXS, ParagraphSM, TitleXS } from '@/atoms/Text'
import { useThemeContext } from '@/contexts/ThemeContext'
import { useTranslate } from '@/utils/translate/translate-client'
import { BaseTooltipProps } from '.'
import { BaseTooltip } from '.'

export const FlexibleBookingToolTip: React.FC<BaseTooltipProps> = ({
  iconSize = 16,
  className,
  buttonClassName,
  panelClassName,
  panelPosition = 'CENTER',
  isIconOnly,
  removeBorder = false,
  buttonStyle,
}) => {
  const { t } = useTranslate('tickets')
  const { isDarkMode } = useThemeContext()

  const trigger = (
    <>
      <CircleInfoIcon size={iconSize} color={isDarkMode ? 'gray-100' : 'gray-500'} />
      {!isIconOnly && (
        <LabelXS className="text-[9px] sm:text-xs">{t('plusFlexibleBooking', 'Plus Flexible Booking™')}</LabelXS>
      )}
    </>
  )

  const content = (
    <>
      <TitleXS color="core-gray-950" weight="bold">
        {t('whatIsFlexibleBooking', 'What is Flexible Booking?')}
      </TitleXS>
      <ParagraphSM className="mt-2 text-[0.688rem] leading-[16px]" color="core-gray-700">
        {t(
          'flexibleBookingDescriptionV4',
          "With Flexible Booking™ you have the convenience of adjusting your seat, showtime, or theater at any moment, right up until an hour before your movie starts. By booking early, you're not just guaranteed the best seats; you're also protected by the flexibility to change your plans. Simply click the link in your order confirmation email or visit the 'My Tickets' section of your profile on Angel.com to make any changes. Flexible Booking™ is only available at Angel.com.",
        )}
      </ParagraphSM>
    </>
  )

  return (
    <BaseTooltip
      className={className}
      buttonClassName={buttonClassName}
      panelClassName={panelClassName}
      panelPosition={panelPosition}
      isIconOnly={isIconOnly}
      removeBorder={removeBorder}
      buttonStyle={buttonStyle}
      trigger={trigger}
      content={content}
    />
  )
}
