import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Button } from '@/atoms/Button'
import { FilledTicketIcon } from '@/atoms/Icons/FilledTicketIcon'
import { useTranslate } from '@/utils/translate/translate-client'
import SceneVideoPlayer from '@/views/TicketCheckoutViews/ShowtimesView/components/SceneVideoPlayer'

interface ScenePlayerProps {
  id: string
  sceneId: string
  isFullScreen: boolean
  handleCloseFullScreen: () => void
  onClose: () => void
  handleGetShowtimesClick: () => void
}
const ScenePlayer = ({
  id,
  sceneId,
  isFullScreen,
  handleCloseFullScreen,
  onClose,
  handleGetShowtimesClick,
}: ScenePlayerProps) => {
  const { t } = useTranslate('tickets')

  const sceneRef = useRef<HTMLDivElement>(null)
  const [isVideoLoading, setIsVideoLoading] = useState(true)

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (event && sceneRef.current && !sceneRef.current.contains(event.target as Node)) {
        handleCloseFullScreen()
      }
    },
    [handleCloseFullScreen],
  )

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [handleClickOutside])

  return (
    <div onClick={(e) => e.stopPropagation()} ref={sceneRef} className="w-full max-w-[900px]">
      <div className="aspect-h-9 aspect-w-16 flex w-full items-center justify-between rounded-xl bg-core-gray-950">
        <SceneVideoPlayer
          id={id}
          shouldAutoPlay={false}
          loading={isVideoLoading}
          setIsLoading={setIsVideoLoading}
          sceneId={sceneId}
          onClose={onClose}
        />
      </div>
      {isFullScreen && (
        <Button onClick={handleGetShowtimesClick} className="mx-auto mt-6 flex items-center gap-1" variant="white">
          <FilledTicketIcon color="core-gray-950" />
          {t('getShowtimes', 'Get Showtimes')}
        </Button>
      )}
    </div>
  )
}

export default ScenePlayer
