import React, { FC, HTMLAttributes, useEffect } from 'react'
import { useRouter } from 'next/router'
import { LinkButton } from '@/atoms/Button'
import { ParagraphMD } from '@/atoms/Text'
import { AngelDialog } from '@/molecules/AngelDialog'
import { useTranslate } from '@/utils/translate/translate-client'

type ExcludedPaths = {
  param: string
  name: string
}

interface Props extends HTMLAttributes<HTMLDivElement> {
  show: boolean
  message: string
  redirectPath: string
  redirectText: string
  doNotRedirect?: boolean
  excludedPaths?: ExcludedPaths[] | undefined
}

export const ErrorRedirect: FC<Props> = ({
  show,
  message = 'Apologies for the error, we have made a note of the issue.',
  redirectPath,
  excludedPaths,
  doNotRedirect,
  redirectText,
}) => {
  const router = useRouter()
  const { t } = useTranslate('common')

  useEffect(() => {
    const { query, isReady, push } = router

    const isExcluded = excludedPaths?.some((path) => query[path.param] === path.name)
    let timeout: NodeJS.Timeout

    if (isReady) {
      if (!isExcluded && !doNotRedirect && show && redirectPath) {
        timeout = setTimeout(() => {
          push(redirectPath)
        }, 5000)

        return () => {
          clearTimeout(timeout)
        }
      }
    }
  }, [excludedPaths, redirectPath, router, doNotRedirect, show])

  return (
    <AngelDialog
      open={show}
      isDarkMode={false}
      onClose={() => router?.reload()}
      className="max-w-[450px]"
      title={t('technicalIssues', 'Technical Issues')}
    >
      <ParagraphMD className="mb-6">{message}</ParagraphMD>
      <LinkButton href={redirectPath} variant="black">
        {redirectText}
      </LinkButton>
    </AngelDialog>
  )
}
