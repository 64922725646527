import React, { FC } from 'react'
import { TitleSM } from '@/atoms/Text'
import { slugs } from '@/constants/slugs'
import { GoalLineV3 } from '@/molecules/GoalLine/GoalLineV3'
import { TheatricalReleaseObject } from '@/types/codegen-federation'
import { TicketsInfoIconTooltip } from '@/views/TicketCheckoutViews/ShowtimesView/components/TicketsInfoIconTooltip'
import useTheatricalGoalService from '@/views/TicketCheckoutViews/hooks/useTheatricalGoalService'

interface TicketGoalLineProps {
  theatricalRelease: TheatricalReleaseObject
  theatricalSlug: string
  shouldHideTitle?: boolean
}
const TicketGoalLine: FC<TicketGoalLineProps> = ({ shouldHideTitle, theatricalSlug, theatricalRelease }) => {
  const countryCode = theatricalRelease?.region?.countryCode
  const releaseDate = theatricalRelease?.region?.releaseDate

  const { goalLineInfo } = useTheatricalGoalService({
    theatricalSlug,
    releaseDate: theatricalRelease?.region?.releaseDate,
  })

  if (countryCode !== 'US') return <div className="rounded-t-2xl bg-white/10 py-2" />

  return (
    <div className="rounded-t-2xl bg-white/20 px-6 py-4">
      {shouldHideTitle && (
        <TitleSM weight="bold" className="hidden justify-between text-gray-100 md:flex lg:mb-2">
          {goalLineInfo?.goalLineTitle}
          <TicketsInfoIconTooltip shouldUseNewAfterDeathGoal={theatricalSlug === slugs.afterDeath} />
        </TitleSM>
      )}
      <div>
        <GoalLineV3
          goalLineInfo={goalLineInfo}
          countryCode={countryCode ?? 'US'}
          theatricalSlug={theatricalSlug}
          releaseDate={releaseDate}
          shouldUseIncrementer
          isRounded={true}
        />
      </div>
    </div>
  )
}

export default TicketGoalLine
