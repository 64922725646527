import { FC } from 'react'
import classNames from 'classnames'
import { Image } from '@/atoms/Image'

interface Props {
  cloudinaryPath: string
  className?: string
}

export const VenueLogo: FC<Props> = ({ cloudinaryPath, className }) => {
  return (
    <Image
      className={classNames('rounded-xl', className)}
      src={cloudinaryPath}
      width={48}
      height={48}
      alt="Theater Logo"
    />
  )
}
