import { useRouter } from 'next/router'
import { useInlineExperiment } from '@/experimentation'

interface Variant {
  variantKey: string
  trailer?: { secure_url?: string | null; trailerUrl?: string | null }
  thumbnail?: { item?: Array<{ secure_url: string }> }
}

export function useTrailerExperiment(
  trailerExperiment: { experimentKey?: string; variants?: Variant[] } | undefined,
  trailerUrl: string,
  trailerThumbnail: string,
): {
  selectedVariation: Variant | undefined
  trailerUrl: string
  trailerThumbnailUrl: string
  selectedVariationKey: string
} {
  const { query } = useRouter()

  const resolveTrailerUrl = () =>
    query.promo === 'stop-hate' ? 'https://www.youtube.com/embed/7a1ifICBeZQ?si=Tr9u6nM7MvT-pZKg' : trailerUrl

  const variations = trailerExperiment?.variants?.map((variant) => variant.variantKey) || []
  const selectedVariationKey = useInlineExperiment({
    key: trailerExperiment?.experimentKey ?? '',
    variations: variations as [string, string, ...string[]],
    active: variations.length > 1,
  })

  const selectedVariation =
    trailerExperiment?.variants?.find((variant) => variant.variantKey === selectedVariationKey) ??
    trailerExperiment?.variants?.find((variant) => variant.variantKey === 'default')

  const selectedThumbnailUrl = selectedVariation?.thumbnail?.item?.[0]?.secure_url ?? trailerThumbnail
  const selectedTrailerUrl = selectedVariation?.trailer?.trailerUrl ?? resolveTrailerUrl()

  return {
    selectedVariationKey,
    selectedVariation,
    trailerUrl: selectedTrailerUrl,
    trailerThumbnailUrl: selectedThumbnailUrl,
  }
}
