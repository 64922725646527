import React, { FC } from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { TicketPromoBanner } from 'src/views/TicketCheckoutViews/ShowtimesView/components/banners/DiscountBanner'
import { TitleLG } from '@/atoms/Text'
import { slugs } from '@/constants/slugs'
import { GoalLineV3 } from '@/molecules/GoalLine/GoalLineV3'
import { TheatricalPromoType, TicketPromoTypes } from '@/services/Theatrical/contentfulQueries'
import { ReservationObject, TheatricalReleaseObject } from '@/types/codegen-federation'
import { DiscountType, useTicketsContext } from '@/views/TicketCheckoutViews/ShowtimesView/components/TicketsContext'
import { TicketsInfoIconTooltip } from '@/views/TicketCheckoutViews/ShowtimesView/components/TicketsInfoIconTooltip'
import { GeneralHomesteadGiveawayBanner } from '@/views/TicketCheckoutViews/ShowtimesView/components/banners/Homestead/GeneralHomesteadGiveawayBanner'
import { isAngelIntegratedCountry } from '@/views/TicketCheckoutViews/ShowtimesView/utils/utils'
import useTheatricalGoalService from '@/views/TicketCheckoutViews/hooks/useTheatricalGoalService'
import { GuildHomesteadGiveawayBanner } from './banners/Homestead/GuildHomesteadGiveawayBanner'
import { PreOrderHomesteadGiveawayBanner } from './banners/Homestead/PreOrderHomesteadBanner'
import { useHomesteadGiveawayBanner } from './banners/Homestead/utils'

interface TicketCarouselProps {
  discount?: DiscountType | null
  scrollToTop?: () => void
  theatricalSlug: string
  theatricalRelease: TheatricalReleaseObject
  className?: string
  reservation?: ReservationObject
  promo?: TheatricalPromoType
}
const MobileTicketCtaCarousel: FC<TicketCarouselProps> = ({
  className,
  discount,
  reservation,
  theatricalRelease,
  theatricalSlug,
  promo,
}) => {
  const { query } = useRouter()
  const countryCode = theatricalRelease?.region?.countryCode ?? 'US'
  const releaseDate = theatricalRelease?.region?.releaseDate ?? new Date()
  const { promoExperiment } = useTicketsContext()
  const currentPromo = promoExperiment ?? promo
  const { goalLineInfo } = useTheatricalGoalService({
    theatricalSlug,
    releaseDate: theatricalRelease?.region?.releaseDate,
  })
  const shouldShowPromoBanner =
    currentPromo?.type === TicketPromoTypes.EarlyBird ||
    !!discount ||
    currentPromo?.type === TicketPromoTypes.NoFees ||
    (query?.promo === 'bogo' && theatricalSlug === slugs.bonhoeffer)
  const homesteadBannerType = useHomesteadGiveawayBanner(theatricalSlug)

  if (isAngelIntegratedCountry(theatricalRelease?.region?.countryCode ?? ''))
    return (
      <Splide
        className={classNames('tickets-carousel z-[52]', className)}
        hasTrack={true}
        options={{
          arrows: false,
          autoplay: true,
          speed: 1000,
          type: 'loop',
          pagination: true,
          paginationDirection: 'ltr',
          interval: 6000,
          perMove: 1,
          perPage: 1,
          classes: { pagination: 'splide__pagination tickets-page !flex' },
        }}
      >
        {query?.promo !== 'stop-hate' && (
          <SplideSlide className="relative w-full">
            <div className="flex h-full items-center bg-core-gray-950 px-6 py-4">
              <GoalLineV3
                goalLineInfo={goalLineInfo}
                shouldUseIncrementer
                releaseDate={releaseDate}
                theatricalSlug={theatricalSlug}
                countryCode={countryCode}
                isRounded={true}
              />
            </div>
          </SplideSlide>
        )}
        {shouldShowPromoBanner &&
          !!reservation?.id &&
          isAngelIntegratedCountry(theatricalRelease?.region?.countryCode ?? '') && (
            <SplideSlide>
              <TicketPromoBanner theatricalSlug={theatricalSlug} discount={discount as DiscountType} promo={promo} />
            </SplideSlide>
          )}

        {homesteadBannerType === 'guild' && (
          <SplideSlide>
            <GuildHomesteadGiveawayBanner />
          </SplideSlide>
        )}
        {homesteadBannerType === 'general' && (
          <SplideSlide>
            <GeneralHomesteadGiveawayBanner />
          </SplideSlide>
        )}
        {homesteadBannerType === 'pre-order' && (
          <SplideSlide>
            <PreOrderHomesteadGiveawayBanner />
          </SplideSlide>
        )}
      </Splide>
    )

  return (
    <div className={classNames('h-full bg-core-gray-950 px-6 py-4', className)}>
      <TitleLG weight="bold" className={classNames('justify-between text-gray-100 hidden md:flex')}>
        {goalLineInfo?.goalLineTitle}
        <TicketsInfoIconTooltip shouldUseNewAfterDeathGoal={theatricalSlug === slugs.afterDeath} />
      </TitleLG>
      <div className="md:mt-3 lg:mb-3">
        <GoalLineV3
          goalLineInfo={goalLineInfo}
          shouldUseIncrementer
          theatricalSlug={theatricalSlug}
          countryCode={countryCode}
          releaseDate={releaseDate}
          isRounded={true}
        />
      </div>
    </div>
  )
}

export default MobileTicketCtaCarousel
