import React from 'react'
import { OneColorIconProps } from '../utils'

export const CloseGradientIcon: React.FC<OneColorIconProps> = ({ className, size }) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 13.4L6.09999 19.3C5.91665 19.4834 5.68332 19.575 5.39999 19.575C5.11665 19.575 4.88332 19.4834 4.69999 19.3C4.51665 19.1167 4.42499 18.8834 4.42499 18.6C4.42499 18.3167 4.51665 18.0834 4.69999 17.9L10.6 12L4.69999 6.10005C4.51665 5.91672 4.42499 5.68338 4.42499 5.40005C4.42499 5.11672 4.51665 4.88338 4.69999 4.70005C4.88332 4.51672 5.11665 4.42505 5.39999 4.42505C5.68332 4.42505 5.91665 4.51672 6.09999 4.70005L12 10.6L17.9 4.70005C18.0833 4.51672 18.3167 4.42505 18.6 4.42505C18.8833 4.42505 19.1167 4.51672 19.3 4.70005C19.4833 4.88338 19.575 5.11672 19.575 5.40005C19.575 5.68338 19.4833 5.91672 19.3 6.10005L13.4 12L19.3 17.9C19.4833 18.0834 19.575 18.3167 19.575 18.6C19.575 18.8834 19.4833 19.1167 19.3 19.3C19.1167 19.4834 18.8833 19.575 18.6 19.575C18.3167 19.575 18.0833 19.4834 17.9 19.3L12 13.4Z"
        fill="url(#paint0_linear_406_9025)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_406_9025"
          x1="4.00018"
          y1="12.0001"
          x2="20.0002"
          y2="12.0001"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.271302" stop-color="#141414" />
          <stop offset="0.828606" stop-color="#DD0000" />
        </linearGradient>
      </defs>
    </svg>
  )
}
