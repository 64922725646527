import React, { FC, useCallback, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Button } from '@/atoms/Button'
import { GiftIcon } from '@/atoms/Icons/GiftIcon'
import { Image } from '@/atoms/Image'
import { CaptionXS, TitleXS } from '@/atoms/Text'
import { useTranslate } from '@/utils/translate/translate-client'
import { ShareGuildTicketsModal } from '@/views/TicketCheckoutViews/ShowtimesView/components/modals/ShareGuildTickets'
import { useGuildTickets } from '@/views/TicketCheckoutViews/hooks/useGuildTickets'

interface GuildTicketsBanner {
  theatricalSlug: string
  projectName: string
  discountCodes: string[]
  className?: string
  shouldUseGradient?: boolean
}

export const GuildTicketsBanner: FC<GuildTicketsBanner> = ({
  className,
  shouldUseGradient = false,
  theatricalSlug,
  projectName,
  discountCodes,
}) => {
  const [isGuildShareModalOpen, setIsGuildShareModalOpen] = useState(false)
  const { t } = useTranslate('tickets')
  const { giftGuildTicketsMobile, guildDiscountQuantity } = useGuildTickets(theatricalSlug)

  const handleShareGuildDiscount = useCallback(async () => {
    if (isMobile) return giftGuildTicketsMobile()

    setIsGuildShareModalOpen(true)
  }, [giftGuildTicketsMobile])

  const content = (
    <div className={className}>
      <div
        style={{ background: 'linear-gradient(103.7deg, #2B2014 0%, #191209 100%)' }}
        className="relative flex items-center justify-between p-3 text-white lg:p-4"
      >
        <div className="flex max-w-[300px] flex-col gap-2">
          <TitleXS weight="bold">
            {t('movieTicketsGuildComplimentary', {
              count: guildDiscountQuantity,
              defaultValue_one: 'You have 1 complimentary ticket applied to this movie.',
              defaultValue_other: 'You have 2 complimentary tickets applied to this movie.',
            })}
          </TitleXS>
          <CaptionXS color="core-gray-500" italic>
            {t('availableForAngelPartneringTheaters', 'Available for Angel Partnering Theaters.')}
          </CaptionXS>
          <Button
            onClick={handleShareGuildDiscount}
            className="mr-auto mt-2 flex items-center gap-1 !px-4 !py-2 !text-xs"
            variant="white"
          >
            <GiftIcon size={16} color="core-gray-950" />
            {t('giftYourTickets', 'Gift Your Tickets')}
          </Button>
        </div>
        <Image
          alt=""
          className="absolute bottom-0 right-0"
          src="/v1719426387/guild-ticket-graphic-copped_4x.webp"
          width={100}
          height={128}
        />
      </div>
    </div>
  )

  if (shouldUseGradient) {
    return (
      <>
        <div className="relative z-10 overflow-hidden rounded-3xl p-[2px]">
          <div
            className="p-[1px]"
            style={{
              backgroundImage:
                'linear-gradient(166.78deg, #EDD7C6 1.42%, #D6A177 14.6%, #FEDEC6 39.98%, #D48D59 60.48%, #A66141 85.7%, #612B12 94.6%)',
            }}
          >
            {content}
          </div>
        </div>
        <ShareGuildTicketsModal
          open={isGuildShareModalOpen}
          onClose={() => {
            setIsGuildShareModalOpen(false)
          }}
          theatricalSlug={theatricalSlug}
          projectName={projectName}
          discountCodes={discountCodes}
        />
      </>
    )
  }

  return (
    <>
      {content}
      <ShareGuildTicketsModal
        open={isGuildShareModalOpen}
        onClose={() => {
          setIsGuildShareModalOpen(false)
        }}
        theatricalSlug={theatricalSlug}
        projectName={projectName}
        discountCodes={discountCodes}
      />
    </>
  )
}
