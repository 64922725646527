import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { LoadingSpinnerIcon } from '@/atoms/Icons/LoadingSpinnerIcon'
import { MagnifyingGlassIcon } from '@/atoms/Icons/MagnifyingGlassIcon'
import { useTranslate } from '@/utils/translate/translate-client'
import useAutoCompleteAddressSearch from '@/views/TicketCheckoutViews/ShowtimesView/hooks/useAutoCompleteAddressSearch'
import '@/views/TicketCheckoutViews/ShowtimesView/hooks/usePreventFacebookAutofill'
import { Coordinates } from '@/views/TicketCheckoutViews/ShowtimesView/hooks/useVenueSearch/useVenueSearch'

interface NewAddressSearchProps {
  onChange: (coordinates: Coordinates, searchString: string) => void
  className?: string
  countryCode: string
  limitResultsExperiment?: boolean
  defaultAddressString?: string
}

export const AddressSearch: React.FC<NewAddressSearchProps> = ({
  onChange,
  className,
  countryCode,
  limitResultsExperiment,
  defaultAddressString,
}) => {
  const { query, isReady } = useRouter()

  const { search, results, clearResults, loading } = useAutoCompleteAddressSearch({
    countryCode,
    limitExperiment: limitResultsExperiment,
  })
  const [inputValue, setInputValue] = useState<string>(defaultAddressString ?? '')
  const [hoverValue, setHoverValue] = useState<number>(0)
  const [showPredictionPanel, setShowPredictionPanel] = useState(false)
  const [hasSearched, setHasSearched] = useState(false)
  const [hasSetDefaultInputValue, setHasSetDefaultInputValue] = useState(false)
  const inputRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslate('tickets')

  useEffect(() => {
    if (defaultAddressString) {
      setInputValue(defaultAddressString)
      setHasSetDefaultInputValue(true)
    }
  }, [defaultAddressString, hasSearched, hasSetDefaultInputValue, isReady, query.city, query.searchDate])

  const handleInputChange = (value: string) => {
    setInputValue(value)
    search(value)
  }

  useEffect(() => {
    const closePredictionPanelOnClick = (e: MouseEvent) => {
      if (inputRef.current && !inputRef.current.contains(e.target as Node)) {
        setShowPredictionPanel(false)
      }
    }
    document.addEventListener('click', closePredictionPanelOnClick)

    return () => {
      document.removeEventListener('click', closePredictionPanelOnClick)
    }
  }, [])

  useEffect(() => {
    if (hoverValue > results.length) setHoverValue(0)
  }, [hoverValue, results.length])

  useEffect(() => {
    if (inputValue === '') clearResults()
  }, [clearResults, inputValue])

  return (
    <div
      onClick={() => {
        if (!hasSearched) {
          setHasSearched(true)
          setInputValue('')
        }
      }}
      ref={inputRef}
      role="search"
      className={classNames('relative z-[40] h-full w-full', className)}
    >
      <div
        aria-label="input"
        className={classNames(
          'absolute inset-x-0 top-0 z-10 overflow-hidden rounded-[10px] border-[1.5px] bg-white border-core-gray-400',
          showPredictionPanel && results?.length > 0 && 'shadow-light-3',
        )}
      >
        <div className="relative">
          <input
            id="location-search"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            type="text"
            onClick={() => setShowPredictionPanel(true)}
            className="w-full rounded-md bg-transparent p-2 px-11 text-core-gray-950 ring-0 ring-transparent placeholder:text-core-gray-600"
            value={inputValue}
            list="address-list"
            placeholder={t('geoapifyInputPlaceholder', 'Search City, Business, Or Zip Code')}
            onChange={(evt) => {
              handleInputChange(evt.target.value)
              setShowPredictionPanel(true)
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                if (results.length > 0) {
                  if (!showPredictionPanel) return
                  setInputValue(results[hoverValue].properties?.label)
                  onChange(results[hoverValue].geometry.coordinates, results[hoverValue].properties?.label)
                  setShowPredictionPanel(false)
                }
              }
              if (e.key === 'ArrowDown') {
                setHoverValue((state) => {
                  if (state > results.length) return state
                  return state + 1
                })
              }

              if (e.key === 'ArrowUp') {
                setHoverValue((state) => {
                  if (state === 0) return state
                  return state - 1
                })
              }
            }}
          />
          {loading ? (
            <div className="absolute left-4 top-1/2 -translate-y-1/2">
              <LoadingSpinnerIcon size={20} color="core-gray-500" />
            </div>
          ) : (
            <MagnifyingGlassIcon size={20} className="absolute left-4 top-1/2 -translate-y-1/2" color="core-gray-500" />
          )}
        </div>
        {showPredictionPanel && (
          <ul role="listbox" className="bg-transparent">
            {results.length > 0 &&
              results.map((result, index) => {
                const isLast = index + 1 === results.length

                return (
                  <li
                    key={result?.properties?.id}
                    tabIndex={0}
                    role="option"
                    aria-selected={hoverValue === index}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        onChange(result.geometry.coordinates, result?.properties?.label)
                        setInputValue(result?.properties?.label)
                        setShowPredictionPanel(false)
                      }
                    }}
                    onMouseEnter={() => {
                      setHoverValue(index)
                    }}
                    onClick={() => {
                      onChange(result.geometry.coordinates, result?.properties?.label)
                      setInputValue(result?.properties?.label)
                      setShowPredictionPanel(false)
                    }}
                    className={classNames(
                      'cursor-pointer px-2 pl-10 py-[10px] flex gap-2 items-center hover:bg-core-gray-200',
                      index === hoverValue && 'bg-core-gray-200',
                      isLast && 'mb-2',
                    )}
                  >
                    {result?.properties?.label}
                  </li>
                )
              })}
          </ul>
        )}
      </div>
    </div>
  )
}
