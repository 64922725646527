import React, { FC } from 'react'
import { FlexRow } from '@/atoms/FlexContainers'
import { CaptionMD } from '@/atoms/Text'
import { AppFeatures, ExperimentVariation, GBFadeInExperiment } from '@/experimentation'
import { TheatricalReleaseObject } from '@/types/codegen-federation'
import { GuildScoreToolTip } from '@/views/TicketCheckoutViews/ShowtimesView/components/ToolTips/GuildScoreToolTip'
import { CriticScore } from './CriticScore'

interface TicketMovieDetailsProps {
  duration: string | null
  theatricalRelease: TheatricalReleaseObject
  projectGuildScore: {
    guildScore: number | undefined
  }
}

const TicketMovieDetails: FC<TicketMovieDetailsProps> = ({ duration, theatricalRelease, projectGuildScore }) => {
  const guildScore = projectGuildScore?.guildScore

  return (
    <FlexRow className="relative z-[100] gap-2 text-core-gray-300">
      {duration && (
        <>
          <CaptionMD>{duration}</CaptionMD>
          <div className="h-1 w-1 rounded-full bg-core-gray-400" />
        </>
      )}
      {theatricalRelease?.movieRating && <CaptionMD className="uppercase">{theatricalRelease?.movieRating}</CaptionMD>}
      <GBFadeInExperiment defaultValue={false} experimentName={'tickets-guild-score' as keyof AppFeatures}>
        <ExperimentVariation variation={true}>
          <div className="flex items-center gap-2">
            <div className="h-1 w-1 rounded-full bg-core-gray-400" />
            {guildScore && (
              <div className="relative z-[999]">
                <GuildScoreToolTip
                  buttonClassName="!p-0 bg-black/10 p-1 bg-[#FFF] bg-opacity-20 border-0 !focus-visible:outline-0 focus-visible:ring-0 focus-visible:ring-transparent focus-visible:ring-offset-0"
                  panelClassName="!z-[9999] !outline-none !top-[26px]"
                  guildScore={guildScore}
                />
              </div>
            )}
          </div>
        </ExperimentVariation>
      </GBFadeInExperiment>
      <CriticScore theatricalRelease={theatricalRelease} />
    </FlexRow>
  )
}

export default TicketMovieDetails
