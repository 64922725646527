import React from 'react'
import { Button } from '@/atoms/Button'
import { CloseIcon } from '@/atoms/Icons/CloseIcon'
import { Image } from '@/atoms/Image'
import { CaptionSM } from '@/atoms/Text'
import { Modal } from '@/molecules/Modal'
import { useTranslate } from '@/utils/translate/translate-client'
import {
  HomesteadButtonText,
  HomesteadEyebrowMD,
  HomesteadParagraphMD,
  HomesteadTitleXL,
} from '@/views/PreSales/PreSalesGiveawayView/components/Typography'

interface Props {
  isOpen: boolean
  onClose: () => void
}

export const PreOrderHomesteadGiveawayModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { t } = useTranslate('tickets')

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      panelClasses="!max-w-[480px] !p-0"
      childWrapperClassName="!mt-0"
      className="!z-[60]"
    >
      <div className="relative flex flex-col bg-black">
        <div className="relative">
          <Image
            width={480}
            height={360}
            src="/v1731940662/angel-studios/projects/homestead/horizontal-poster-with-logo"
            alt="Homestead"
            className="h-full object-cover object-[0%_50%]"
          />
          <div className="absolute inset-x-0 bottom-0 h-6 bg-gradient-to-t from-black to-transparent" />
        </div>

        <button
          onClick={onClose}
          className="absolute right-6 top-6 z-10 flex size-8 cursor-pointer items-center justify-center rounded-full bg-lightened-200 transition-bg hover:bg-gray-800/50"
        >
          <CloseIcon size={16} color="core-gray-100" />
        </button>

        <div className="px-6 pb-6 text-left lg:px-8 lg:pb-8">
          <div className="inline-block rounded-md bg-homestead-yellow px-3">
            <HomesteadEyebrowMD weight="semibold" className="!leading-[1.2]">
              {t('preOrderTicketsHyphenated', 'Pre-Order Tickets')}
            </HomesteadEyebrowMD>
          </div>

          <HomesteadTitleXL weight="bold" className="mt-3 text-homestead-yellow">
            {t('promoCodeApplied', 'Promo code applied')}
          </HomesteadTitleXL>

          <HomesteadParagraphMD color="white" className="mt-4">
            {t(
              'thankYouForPreOrderingTickets',
              'Thank you for pre-ordering tickets. Simply choose a showtime and checkout.',
            )}
          </HomesteadParagraphMD>

          <CaptionSM color="core-gray-500" weight="normal" italic className="mt-4 !text-[14px]">
            {t('availableWithAngelPartnerTheaters', 'Available with Angel Partner Theaters')}
          </CaptionSM>

          <Button
            variant="plain"
            onClick={onClose}
            className="mt-6 rounded-lg bg-homestead-red px-4 py-3 text-white transition-all duration-300 ease-in-out hover:bg-homestead-red-dark active:bg-homestead-red-200"
          >
            <HomesteadButtonText>{t('selectShowtimes', 'Select Showtimes')}</HomesteadButtonText>
          </Button>
        </div>
      </div>
    </Modal>
  )
}
