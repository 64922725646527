import React from 'react'
import classNames from 'classnames'
import { AsP, EyebrowMD, EyebrowSM, HeadingSM } from '@/atoms/Text'
import { useLocale } from '@/utils/LocaleUtil'

interface DateCardProps {
  date: Date
  disabled: boolean
  onClick: () => void
  selected: boolean
  showtimeCount?: number | null
  isLastChance?: boolean
  isAfterWideRelease?: boolean
}

export const DateCard: React.FC<DateCardProps> = ({ date, disabled, onClick, selected }) => {
  const { locale } = useLocale()

  return (
    <div className="relative">
      <div
        className={classNames(
          'w-[72px] h-24 relative rounded-xl hover:scale-105 hover:bg-white bg-core-gray-200 text-gray-900 border-[1.5px] border-core-gray-200 hover:!z-30',
          {
            'cursor-not-allowed': disabled,
            'cursor-pointer': !disabled,
            '!border-transparent !bg-core-gray-950 shadow-[0_8px_16px_-1px_rgba(0,0,0,0.08)]': selected,
            '!border-core-gray-200 !bg-white': !selected,
          },
        )}
        onClick={onClick}
      >
        <div className="flex h-full flex-col justify-center pt-0.5 text-center">
          <EyebrowMD
            color="core-gray-700"
            className={classNames('uppercase', {
              '!text-core-gray-400': selected,
            })}
          >
            {date.toLocaleString(locale, { month: 'short' })}
          </EyebrowMD>
          <HeadingSM
            as={AsP}
            weight="bold"
            className={classNames('leading-[36px] tracking-[-1px]', {
              '!text-white': selected,
            })}
          >
            {date.getDate()}
          </HeadingSM>
          <EyebrowSM
            color="core-gray-700"
            className={classNames('uppercase', {
              '!text-core-gray-400': selected,
            })}
          >
            {date.toLocaleString(locale, { weekday: 'short' })}
          </EyebrowSM>
        </div>
        {selected && (
          <div
            className={classNames('absolute bottom-1 right-1/2 h-1 w-3 translate-x-2/4 rounded-full bg-oxide-bright')}
          ></div>
        )}
      </div>
    </div>
  )
}
