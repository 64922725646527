import React, { FC, useMemo } from 'react'
import classNames from 'classnames'
import { isAfter } from 'date-fns'
import { useRouter } from 'next/router'
import { FlexRow } from '@/atoms/FlexContainers'
import { BlockedIcon } from '@/atoms/Icons/BlockedIcon'
import { CalendarIcon } from '@/atoms/Icons/CalendarIcon'
import { CircleStarIcon } from '@/atoms/Icons/CircleStarIcon'
import { CouponIcon } from '@/atoms/Icons/CouponIcon'
import { GuildSolidIcon } from '@/atoms/Icons/GuildSolidIcon'
import { Popover } from '@/atoms/Popover'
import { CaptionMD } from '@/atoms/Text'
import { slugs } from '@/constants'
import { TicketPromoTypes } from '@/services/Theatrical/contentfulQueries'
import { TheatricalShowtimeVenue } from '@/types/codegen-federation'
import { useTranslate } from '@/utils/translate/translate-client'
import { useTicketsContext } from '@/views/TicketCheckoutViews/ShowtimesView/components/TicketsContext'

interface Props {
  className?: string
  guildTicketsCount?: number
  isAngelPartnerVenue: boolean
  theatricalSlug: string
  venue: TheatricalShowtimeVenue
  noOnlineTicketing?: boolean
}

const VenuePills: FC<Props> = ({
  className,
  guildTicketsCount,
  isAngelPartnerVenue,
  venue,
  theatricalSlug,
  noOnlineTicketing = false,
}) => {
  const { t } = useTranslate('tickets')
  const { discount, promoExperiment, promo, isFreeTicketPromo } = useTicketsContext()
  const { query } = useRouter()
  const ticketPromo = promoExperiment ?? promo
  const showtimeUtcStartDate = venue?.showtimes?.[0]?.utcStartTime

  const isShowtimeDateAfterJuly4th = useMemo(() => {
    if (!showtimeUtcStartDate) return true
    return isAfter(new Date(showtimeUtcStartDate), new Date(2024, 6, 5))
  }, [showtimeUtcStartDate])

  const discountName = useMemo(() => {
    if (!discount) return null
    if (theatricalSlug === slugs.soundOfHopeTheStoryOfPossumTrot && isShowtimeDateAfterJuly4th) return null

    if (discount?.name?.toLowerCase() === 'no fees' && ticketPromo?.type === TicketPromoTypes.NoFees) {
      return t('noFeesTicketsOnSelectTimes', 'No Fees on Select Times')
    }

    if (discount?.name?.toLowerCase() === 'early bird' && ticketPromo?.type === TicketPromoTypes.EarlyBird) {
      return t('couponDiscountSelectTimes', '{{discount}} off Select Times', {
        discount: discount?.description,
      })
    }
  }, [discount, isShowtimeDateAfterJuly4th, t, theatricalSlug, ticketPromo?.type])

  if (noOnlineTicketing) {
    return (
      <FlexRow className={classNames('gap-2 flex-wrap', className)}>
        <Popover
          className="!z-50"
          title={t('noOnlineTicketingUnavailable', 'Online Ticketing Unavailable')}
          description={t(
            'onlineTicketingIsNotAvailableForThisTheater',
            'Online ticketing is not available for this theater. Please visit the theater in person to purchase your tickets. Enjoy the show!',
          )}
          icon={<BlockedIcon size={20} color="warm-01" />}
          triggerComponent={
            <span className="relative flex items-center justify-center gap-1 rounded-full border border-core-gray-300 px-3 py-0.5">
              <BlockedIcon size={14} color="core-gray-800" />
              <CaptionMD color="core-gray-800">
                {t('noOnlineTicketingUnavailable', 'Online Ticketing Unavailable')}
              </CaptionMD>
            </span>
          }
        />
      </FlexRow>
    )
  }

  if (!isAngelPartnerVenue && !isFreeTicketPromo) {
    return (
      <FlexRow className={classNames('gap-2 flex-wrap', className)}>
        <Popover
          className="!z-50"
          title={t('discountsUnavailable', 'Discounts Unavailable')}
          description={t(
            'benefitsUnavailableDescriptionV2',
            'This theater is not an Angel Partner Theater. Discounts, coupon codes, Flexible Booking™, and Guild ticket redemptions are not available at this time through Angel.com.',
          )}
          icon={<BlockedIcon size={20} color="warm-01" />}
          triggerComponent={
            <span className="relative flex items-center justify-center gap-1 rounded-full border border-core-gray-300 px-3 py-0.5">
              <BlockedIcon size={14} color="core-gray-800" />
              <CaptionMD color="core-gray-800">{t('discountsUnavailable', 'Discounts Unavailable')}</CaptionMD>
            </span>
          }
        />
      </FlexRow>
    )
  }

  return (
    <FlexRow className={classNames('gap-2 flex-wrap', className)}>
      <Popover
        className="!z-50"
        title={t('angelPartner', 'Angel Partner')}
        description={t(
          'angelPartnerDescriptionV3',
          'Angel Partner Theaters offer discounts, coupons, Flexible Booking, and Guild ticket redemptions. Only available at Angel.com.',
        )}
        icon={<CircleStarIcon color="warm-03" size={20} />}
        triggerComponent={
          <span className="relative flex items-center justify-center gap-1 rounded-full border border-core-gray-300 px-3 py-0.5">
            <CircleStarIcon color="core-gray-800" size={14} />
            <CaptionMD color="core-gray-800">{t('angelPartner', 'Angel Partner')}</CaptionMD>
          </span>
        }
      />
      <Popover
        className="!z-40"
        title={t('flexibleBooking', 'Flexible Booking™')}
        description={t(
          'flexibleBookingDescriptionPill',
          "With Flexible Booking™, change your seat, showtime, or theater anytime up to an hour before the movie starts. To modify, click the link in your confirmation email or visit 'My Tickets' on Angel.com. Flexible Booking is only available Angel.com.",
        )}
        icon={<CalendarIcon color="oxide-bright" size={20} />}
        triggerComponent={
          <span className="relative flex items-center justify-center gap-1 rounded-full border border-core-gray-300 px-3 py-0.5">
            <CalendarIcon color="core-gray-800" size={14} />
            <CaptionMD color="core-gray-800">{t('flexibleBooking', 'Flexible Booking™')}</CaptionMD>
          </span>
        }
      />
      {!!guildTicketsCount && guildTicketsCount > 0 && (
        <Popover
          className="!z-30"
          title={t('redeemGuildTickets', 'Redeem Guild Tickets')}
          description={t(
            'redeemGuildTicketsDescription',
            'Your Guild membership includes complimentary movie tickets that can be redeemed at this theater. Select a showtime and your Guild tickets will be applied at checkout. Only available with Angel Partner Theaters at Angel.com.',
          )}
          icon={<CircleStarIcon color="warm-03" size={20} />}
          triggerComponent={
            <span className="relative flex items-center justify-center gap-1 rounded-full border border-core-gray-300 px-3 py-0.5">
              <GuildSolidIcon color1="core-gray-800" color2="white" size={14} />
              <CaptionMD color="core-gray-800">{t('redeemGuildTickets', 'Redeem Guild Tickets')}</CaptionMD>
            </span>
          }
        />
      )}
      {query?.promo !== 'none' && (
        <Popover
          className="!z-30"
          title={t('discountPopoverTitle', 'Available Discounts')}
          description={t('discountPopoverDescription', 'Only available with Angel Partner Theaters at Angel.com.')}
          icon={<CouponIcon color="warm-03" size={20} />}
          triggerComponent={
            <>
              {query?.promo === 'bogo' && query?.bogocode && theatricalSlug !== slugs.soundOfHopeTheStoryOfPossumTrot && (
                <div className="relative flex items-center justify-center gap-1 rounded-full border  border-core-warning-200 bg-core-warning-50 px-3 py-0.5">
                  <CouponIcon color="core-warning-800" size={14} />
                  <CaptionMD color="core-warning-800">{t('bogo', 'BOGO')}</CaptionMD>
                </div>
              )}

              {query?.promo === 'bogo' &&
                query?.bogocode &&
                theatricalSlug === slugs.soundOfHopeTheStoryOfPossumTrot &&
                !isShowtimeDateAfterJuly4th && (
                  <div className="relative flex items-center justify-center gap-1 rounded-full border  border-core-warning-200 bg-core-warning-50 px-3 py-0.5">
                    <CouponIcon color="core-warning-800" size={14} />
                    <CaptionMD color="core-warning-800">{t('bogo', 'BOGO')}</CaptionMD>
                  </div>
                )}
              {theatricalSlug === slugs.soundOfHopeTheStoryOfPossumTrot &&
                (query?.promo === 'dailywire' || query?.promo === 'live10') && (
                  <div className="relative flex items-center justify-center gap-1 rounded-full border  border-core-warning-200 bg-core-warning-50 px-3 py-0.5">
                    <CouponIcon color="core-warning-800" size={14} />
                    <CaptionMD className="uppercase" color="core-warning-800">
                      {t('dailyWirePercentOff', '10% off')}
                    </CaptionMD>
                  </div>
                )}
              {discountName && !query?.promo && (
                <div className="relative flex items-center justify-center gap-1 rounded-full border  border-core-warning-200 bg-core-warning-50 px-3 py-0.5">
                  <CouponIcon color="core-warning-800" size={14} />
                  <CaptionMD color="core-warning-800">{discountName}</CaptionMD>
                </div>
              )}
            </>
          }
        />
      )}
    </FlexRow>
  )
}

export default VenuePills
