import React, { FC } from 'react'
import { Button } from '@/atoms/Button'
import { FlexColumn, FlexRow } from '@/atoms/FlexContainers'
import { CircleCheckIcon } from '@/atoms/Icons/CircleCheckIcon'
import { CloseIcon } from '@/atoms/Icons/CloseIcon'
import { Image } from '@/atoms/Image'
import { CaptionSM, HeadingLG, HeadingXL, ParagraphMD, TitleSM } from '@/atoms/Text'
import { Modal } from '@/molecules/Modal'
import { useTranslate } from '@/utils/translate/translate-client'
import { DiscountType } from '@/views/TicketCheckoutViews/ShowtimesView/components/TicketsContext'
import TimeLeft from '@/views/TicketCheckoutViews/ShowtimesView/components/banners/DiscountBanner/TimeLeft'

interface FlashSaleModalProps {
  open: boolean
  onClose: () => void
  discount: DiscountType
  theatricalName: string
}
const FlashSaleModal: FC<FlashSaleModalProps> = ({ open, onClose, discount, theatricalName }) => {
  const { t } = useTranslate('tickets')

  return (
    <Modal
      backgroundScreenColor="black"
      isOpen={open}
      onClose={onClose}
      panelClasses="bg-white shadow-dark-4 rounded-3xl lg:min-w-[380px] !p-10 relative text-center !overflow-visible"
      transition="appear"
      className="z-[99999]"
    >
      <Image
        className="absolute left-0 top-0 rounded-tl-3xl"
        alt=""
        src="/v1709778082/bolt-yellow_4x.webp"
        width={86.5}
        height={186}
        priority
      />
      <Image
        className="absolute -bottom-[1px] -right-[1px] rounded-br-3xl"
        alt=""
        src="/v1709778219/bolt-gray_4x.webp"
        width={57.67}
        height={102}
        priority
      />
      <div>
        <div className="absolute right-6 top-6 cursor-pointer rounded-full bg-core-gray-200 p-1" onClick={onClose}>
          <CloseIcon color="black" size={18} />
        </div>
        <FlexColumn className="gap-6">
          <TimeLeft />
          <FlexColumn className="mt-3 justify-center uppercase">
            <HeadingXL className="text-5xl" color="warm-03" weight="bold">{`${discount?.value}% ${t(
              'offTicketModal',
              'off',
            )}`}</HeadingXL>
            <TitleSM weight="bold">{t('flashSaleTitle', '{{theatricalName}} Tickets', { theatricalName })}</TitleSM>
          </FlexColumn>
          <FlexColumn className="items-center gap-2">
            <HeadingLG weight="bold">{t('flashSale', 'Flash Sale')}</HeadingLG>
            <FlexColumn className="gap-1">
              {discount?.description && (
                <FlexRow className="gap-1">
                  <CircleCheckIcon size={20} color1="oxide-primary" />
                  <ParagraphMD color="core-gray-700">
                    {t('getPercentOffTicketPrice', 'Get {{percent}} off ticket price', {
                      percent: discount?.description,
                    })}
                  </ParagraphMD>
                </FlexRow>
              )}
              <FlexRow className="gap-1">
                <CircleCheckIcon size={20} color1="oxide-primary" />
                <ParagraphMD color="core-gray-700">{t('enjoyFlexibleBooking', 'Enjoy Flexible Booking™')}</ParagraphMD>
              </FlexRow>
            </FlexColumn>
          </FlexColumn>

          <FlexColumn className="gap-3">
            <Button onClick={onClose} className="mx-auto mt-4 w-full max-w-[180px] rounded-xl !px-6" variant="black">
              {t('applyDiscount', 'Apply Discount')}
            </Button>
            <CaptionSM italic color="core-gray-600">
              {t('availableForAngelPartneringTheaters', 'Available for Angel Partnering Theaters.')}
            </CaptionSM>
          </FlexColumn>
        </FlexColumn>
      </div>
    </Modal>
  )
}

export default FlashSaleModal
