import { useEffect, useMemo, useState } from 'react'
import { slugs } from '@/constants/slugs'
import { useExperimentContext } from '@/experimentation'
import { ReservationObject, ReservationPendingDiscount } from '@/types/codegen-federation'

const getTotalDiscountUsesByType = (discounts: ReservationPendingDiscount[] = [], type: 'PRESALE' | 'GUILD') => {
  return discounts
    .filter((discount) => discount?.category === type)
    .map((discount) => discount?.remainingUses ?? 0)
    .reduce((sum, number) => sum + number, 0)
}

interface Props {
  theatricalSlug: string
  reservation: ReservationObject | undefined
}

export const useEmptyListGiveawayExperiment = ({ reservation, theatricalSlug }: Props) => {
  const [isGiveawayEmptyStateExperimentEnabled, setIsGiveawayEmptyStateExperimentEnabled] = useState(false)
  const [isReady, setIsReady] = useState(false)

  const { getFeatureValue, ready: isGbReady } = useExperimentContext()
  const { pendingDiscounts } = reservation ?? {}

  const totalGuildDiscounts = useMemo(() => getTotalDiscountUsesByType(pendingDiscounts, 'GUILD'), [pendingDiscounts])
  const totalPresaleDiscounts = useMemo(
    () => getTotalDiscountUsesByType(pendingDiscounts, 'PRESALE'),
    [pendingDiscounts],
  )

  useEffect(() => {
    if (!isGbReady) return

    const isValidForExperiment = theatricalSlug === slugs.homestead && !totalGuildDiscounts && !totalPresaleDiscounts

    if (!isValidForExperiment) {
      setIsReady(true)
      setIsGiveawayEmptyStateExperimentEnabled(false)
      return
    }

    const isEnabled = getFeatureValue('homestead-ticket-empty-state', false)
    setIsGiveawayEmptyStateExperimentEnabled(isEnabled)
    setIsReady(true)
  }, [getFeatureValue, isGbReady, theatricalSlug, totalGuildDiscounts, totalPresaleDiscounts])

  return {
    isEnabled: isGiveawayEmptyStateExperimentEnabled,
    isReady,
  }
}
