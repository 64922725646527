import React from 'react'
import { getHexColor, OneColorIconProps } from '../utils'

export const UploadIcon: React.FC<OneColorIconProps> = ({ className, color = 'core-gray-100', size = 12 }) => {
  const hex = getHexColor(color)

  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9993 18.6663C13.6688 18.6663 13.3919 18.5543 13.1687 18.3303C12.9447 18.1071 12.8327 17.8302 12.8327 17.4996V9.15794L10.6452 11.3454C10.4118 11.5788 10.1396 11.6954 9.82852 11.6954C9.51741 11.6954 9.23546 11.5691 8.98268 11.3163C8.74935 11.0829 8.63774 10.8057 8.64785 10.4844C8.65718 10.164 8.76879 9.89683 8.98268 9.68294L13.1827 5.48294C13.2993 5.36628 13.4257 5.28344 13.5618 5.23444C13.698 5.18622 13.8438 5.16211 13.9993 5.16211C14.1549 5.16211 14.3007 5.18622 14.4368 5.23444C14.573 5.28344 14.6993 5.36628 14.816 5.48294L19.016 9.68294C19.2493 9.91628 19.361 10.1932 19.3509 10.5136C19.3415 10.8348 19.2299 11.1024 19.016 11.3163C18.7827 11.5496 18.5058 11.6709 18.1854 11.6803C17.8641 11.6904 17.5869 11.5788 17.3535 11.3454L15.166 9.15794V17.4996C15.166 17.8302 15.0544 18.1071 14.8312 18.3303C14.6072 18.5543 14.3299 18.6663 13.9993 18.6663ZM6.99935 23.3329C6.35768 23.3329 5.80857 23.1047 5.35202 22.6481C4.89468 22.1908 4.66602 21.6413 4.66602 20.9996V18.6663C4.66602 18.3357 4.77763 18.0584 5.00085 17.8344C5.22485 17.6112 5.50213 17.4996 5.83268 17.4996C6.16324 17.4996 6.44052 17.6112 6.66452 17.8344C6.88774 18.0584 6.99935 18.3357 6.99935 18.6663V20.9996H20.9993V18.6663C20.9993 18.3357 21.1114 18.0584 21.3354 17.8344C21.5586 17.6112 21.8355 17.4996 22.166 17.4996C22.4966 17.4996 22.7735 17.6112 22.9967 17.8344C23.2207 18.0584 23.3327 18.3357 23.3327 18.6663V20.9996C23.3327 21.6413 23.1044 22.1908 22.6479 22.6481C22.1905 23.1047 21.641 23.3329 20.9993 23.3329H6.99935Z"
        fill={hex}
      />
    </svg>
  )
}
