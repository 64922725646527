interface WindowWithAutofill extends Window {
  _AutofillCallbackHandler?: ((..._: unknown[]) => void) | null
}

if (typeof window !== 'undefined') {
  ;(window as WindowWithAutofill)._AutofillCallbackHandler =
    (window as WindowWithAutofill)._AutofillCallbackHandler || function () {}
}

export {}
