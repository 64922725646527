import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  getBooleanFromLocalStorage,
  getFloatFromLocalStorage,
  getIntegerFromLocalStorage,
  getObjectFromLocalStorage,
  getStringFromLocalStorage,
  writeToLocalStorage,
} from '@/utils/local-storage'

type LocaleStorageType = 'string' | 'object' | 'integer' | 'float' | 'boolean'

export const useLocalStorage = <T>(key: string, defaultValue: T, storageType: LocaleStorageType) => {
  const [value, setValue] = useState<T>(defaultValue)
  const [loading, setLoading] = useState(true)
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    switch (storageType) {
      case 'string': {
        const storageValue = getStringFromLocalStorage(key)
        if (storageValue != null) setValue(storageValue as T)
        break
      }
      case 'object': {
        const storageValue = getObjectFromLocalStorage(key)
        if (storageValue != null) setValue(storageValue as T)
        break
      }
      case 'integer': {
        const storageValue = getIntegerFromLocalStorage(key)
        if (storageValue != null) setValue(storageValue as T)
        break
      }
      case 'float': {
        const storageValue = getFloatFromLocalStorage(key)
        if (storageValue != null) setValue(storageValue as T)
        break
      }
      case 'boolean': {
        const storageValue = getBooleanFromLocalStorage(key)
        if (storageValue != null) setValue(storageValue as T)
        break
      }
    }
    setLoading(false)
    setIsReady(true)
  }, [key, storageType])

  const setStoredValue = useCallback(
    (value: T) => {
      if (value == null) return

      writeToLocalStorage(key, value)
      setValue(value)
    },
    [key],
  )

  return useMemo(() => {
    return {
      loading,
      storedValue: value,
      setStoredValue,
      isReady,
    }
  }, [loading, setStoredValue, value, isReady])
}
