import React from 'react'
import { useRouter } from 'next/router'
import { ErrorRedirect } from '@/molecules/Error/ErrorRedirect'
import { useTranslate } from '@/utils/translate/translate-client'

interface FandangoRedirectProps {
  shouldRedirectToFandango: boolean
  fandangoUrl: string
}
const FandangoRedirect = ({ shouldRedirectToFandango, fandangoUrl }: FandangoRedirectProps) => {
  const { t } = useTranslate('tickets')
  const { query, isReady } = useRouter()

  if (!fandangoUrl) return null

  const errorRedirectMessage = t(
    'ticketsErrorRedirect',
    'We are experiencing some issues with our ticketing system, you will be forwarded to Fandango.',
  )
  return (
    <>
      {shouldRedirectToFandango && (
        <ErrorRedirect
          show
          message={errorRedirectMessage}
          doNotRedirect={!isReady || !!query?.promo}
          redirectPath={fandangoUrl}
          redirectText={t('goToFandango', 'Go to Fandango')}
        />
      )}
    </>
  )
}

export default FandangoRedirect
