import React, { useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import { isAfter, isEqual, startOfDay } from 'date-fns'
import useEmblaCarousel from 'embla-carousel-react'
import { TheatricalReleaseObject } from '@/types/codegen-federation'
import { DateCard } from '@/views/TicketCheckoutViews/ShowtimesView/components/DateCard'
import { SpecialDateCard } from '@/views/TicketCheckoutViews/ShowtimesView/components/SpecialDateCard'
import {
  generateDates,
  getDayBefore,
  getProjectEarliestDate,
} from '@/views/TicketCheckoutViews/ShowtimesView/utils/utils'

function getDateAfter60DaysFromDate(inputDate: Date) {
  const futureDate = new Date(inputDate.getDate())
  futureDate.setDate(inputDate.getDate() + 60)
  return futureDate
}
function isTodayBeforeReleaseDate(releaseDate: Date) {
  return new Date() < releaseDate
}

export interface CalendarProps {
  disabled: boolean
  onChange: (date: Date) => void
  selectedDate?: Date
  startDate: Date
  shouldSlide?: boolean
  scrollToTop: () => void
  calendarRef: React.RefObject<HTMLDivElement>
  releaseDate: string
  theatricalSlug: string
  theatricalRelease: TheatricalReleaseObject
  className?: string
  specialDate?: Date
}

const minSlideIndexToStartScrolling = 3

export const Calendar: React.FC<CalendarProps> = ({
  disabled,
  onChange,
  selectedDate,
  startDate,
  shouldSlide = true,
  scrollToTop,
  calendarRef,
  releaseDate,
  theatricalSlug,
  theatricalRelease,
  className,
  specialDate,
}) => {
  const shouldShowOneDayBefore = releaseDate ? isTodayBeforeReleaseDate(new Date(releaseDate)) : true
  const [selected, setSelected] = useState<number | null>(null)
  const [hasSetInitialDate, setHasSetInitialDate] = useState(false)
  const isSpecialDateInPast = specialDate && isAfter(startOfDay(new Date()), startOfDay(specialDate))

  const dates: Date[] = useMemo(() => {
    const generatedDates = shouldShowOneDayBefore
      ? generateDates(getProjectEarliestDate(startDate, theatricalSlug), getDateAfter60DaysFromDate(startDate), 60)
      : generateDates(startDate, getDateAfter60DaysFromDate(startDate), 60)

    if (specialDate && !isSpecialDateInPast) return [specialDate, ...generatedDates]
    if (specialDate && isSpecialDateInPast) return [specialDate, ...generatedDates]

    return generatedDates
  }, [isSpecialDateInPast, shouldShowOneDayBefore, specialDate, startDate, theatricalSlug])

  useEffect(() => {
    if (!disabled && selectedDate) {
      const date = selectedDate ? selectedDate : new Date()
      const selectedIndex = dates.findIndex((d) => d.getDate() === date.getDate() && d.getMonth() === date.getMonth())
      setSelected(selectedIndex)
    }
  }, [dates, selectedDate, disabled, hasSetInitialDate, shouldSlide, specialDate])

  if (selected == null) return null

  return (
    <div ref={calendarRef} className={classNames(className)}>
      <DateCards
        dates={dates}
        disabled={disabled}
        selected={selected}
        releaseDate={new Date(releaseDate)}
        specialDate={specialDate}
        hasSetInitialDate={hasSetInitialDate}
        theatricalSlug={theatricalSlug}
        setHasSetInitialDate={setHasSetInitialDate}
        onChange={onChange}
        scrollToTop={scrollToTop}
        setSelected={setSelected}
        theatricalRelease={theatricalRelease}
      />
    </div>
  )
}

export interface DateCardsProps {
  dates: Date[]
  disabled: boolean
  selected: number | null
  releaseDate: Date
  hasSetInitialDate: boolean
  theatricalSlug?: string
  theatricalRelease: TheatricalReleaseObject
  setHasSetInitialDate: (hasSetInitialDate: boolean) => void
  onChange: (date: Date) => void
  scrollToTop: () => void
  setSelected: (index: number) => void
  specialDate?: Date
}

const DateCards: React.FC<DateCardsProps> = ({
  dates,
  disabled,
  specialDate,
  selected,
  releaseDate,
  hasSetInitialDate,
  onChange,
  scrollToTop,
  setHasSetInitialDate,
  setSelected,
}) => {
  const dayBeforeReleaseDate = getDayBefore(new Date(releaseDate))
  const [emblaRef, embedApi] = useEmblaCarousel({
    dragFree: true,
    duration: 20,
    align: 'start',
  })

  useEffect(() => {
    if (selected && !hasSetInitialDate) {
      if (selected > minSlideIndexToStartScrolling) embedApi?.scrollTo(selected, true)
      setHasSetInitialDate(true)
    }
  }, [embedApi, hasSetInitialDate, selected, setHasSetInitialDate])

  return (
    <>
      <div className="embla" ref={emblaRef} id="calendar">
        <div className="embla__container ml-4 py-4">
          {dates.map((date, i) => {
            const isDayBeforeRelease = isEqual(date, dayBeforeReleaseDate)
            const isSpecialDate = specialDate && isEqual(date, specialDate)

            const handleClick = () => {
              if (!disabled) {
                scrollToTop()
                setSelected(i)
                if (i > minSlideIndexToStartScrolling) embedApi?.scrollTo(i)
                onChange(date)
              }
            }

            if (isDayBeforeRelease && !specialDate) {
              return (
                <div key={date.toISOString()} className="embla__slide">
                  <SpecialDateCard
                    disabled={disabled}
                    date={date}
                    onClick={handleClick}
                    selected={!disabled && selected === i}
                  />
                </div>
              )
            }

            if (isSpecialDate) {
              return (
                <div key={date.toISOString()} className="embla__slide !mr-6">
                  <SpecialDateCard
                    disabled={disabled}
                    date={date}
                    onClick={handleClick}
                    selected={!disabled && selected === i}
                    useDivider
                  />
                </div>
              )
            }

            return (
              <div key={date.toISOString()}>
                <div className="embla__slide">
                  <DateCard
                    disabled={disabled}
                    date={date}
                    onClick={handleClick}
                    selected={!disabled && selected === i}
                  />
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}
