import { useCallback } from 'react'
import debounce from 'lodash/debounce'
import { useRouter } from 'next/router'
import type { UrlObject } from 'node:url'

interface DebounceSettings {
  leading?: boolean
  maxWait?: number
  trailing?: boolean
}

interface DebounceOpts extends DebounceSettings {
  debounceMs?: number
}

export function useRouterPush(opts?: DebounceOpts) {
  const { push } = useRouter()

  const { debounceMs, ...rest } = opts ?? {}
  const wait = debounceMs ?? 500
  const debounceOptions: DebounceSettings = { leading: false, trailing: true, maxWait: 2000, ...rest }

  const debouncedShallowPush = debounce(
    (url: UrlObject | string) => push(url, undefined, { shallow: true }),
    wait,
    debounceOptions,
  )

  const routerShallowPush = useCallback((url: UrlObject | string) => debouncedShallowPush(url), [debouncedShallowPush])

  return { routerShallowPush }
}

export interface UrlHash {
  hashFragment?: string
  hashValue?: string
}

export function extractUrlHash(asPath: string): UrlHash {
  const match = asPath.match(/#([a-z0-9-]+)/i)

  if (!match) {
    return {}
  }

  const hashFragment = match[0]
  const hashValue = match[1]

  return {
    hashFragment,
    hashValue,
  }
}
