import React, { useMemo } from 'react'
import classNames from 'classnames'
import Link from 'next/link'
import { FlexRow } from '@/atoms/FlexContainers'
import { CircleInfoIcon } from '@/atoms/Icons/CircleInfoIcon'
import { Popover } from '@/atoms/Popover'
import { EyebrowSM } from '@/atoms/Text'
import { getHexColor } from '@/atoms/utils'
import { slugs } from '@/constants'
import { useThemeContext } from '@/contexts/ThemeContext'
import { useIncrementer } from '@/services/GoalService/hooks/useIncrementer'
import { useLocale } from '@/utils/LocaleUtil'
import { Translate, useTranslate } from '@/utils/translate/translate-client'
import { TicketsInfoIconTooltip } from '@/views/TicketCheckoutViews/ShowtimesView/components/TicketsInfoIconTooltip'
import { GoalLineInfo } from '@/views/TicketCheckoutViews/hooks/useTheatricalGoalService'

interface GoalLineProps {
  theatricalSlug: string
  countryCode: string
  releaseDate: string
  goalLineInfo: GoalLineInfo
  isRounded?: boolean
  shouldUseIncrementer?: boolean
  shouldShowDaysLeft?: boolean
}

export const GoalLineV3: React.FC<GoalLineProps> = ({
  theatricalSlug,
  countryCode,
  goalLineInfo,
  releaseDate,
  isRounded = true,
  shouldUseIncrementer = false,
  shouldShowDaysLeft = false,
}) => {
  const { isDarkMode } = useThemeContext()
  const { locale } = useLocale()
  const color = getHexColor('oxide-700')
  const { t } = useTranslate('tickets')

  const goalDisplay = useMemo(() => {
    return new Intl.NumberFormat(locale).format(goalLineInfo?.currentMilestone)
  }, [goalLineInfo?.currentMilestone, locale])

  const borderRadiusClasses = isRounded ? 'rounded-full' : ''
  const shouldUseNewAfterDeathGoal = theatricalSlug === slugs.afterDeath
  const incrementerNumber = useIncrementer(goalLineInfo?.ticketsSold, 0.75)
  const incrementerPercent = Math.floor(
    (incrementerNumber / (shouldUseNewAfterDeathGoal ? 166234 : goalLineInfo?.currentMilestone)) * 100,
  )
  const ticketsSold = shouldUseIncrementer ? Math.ceil(incrementerNumber) : goalLineInfo?.ticketsSold
  const goalPercent = shouldUseIncrementer ? incrementerPercent : goalLineInfo?.milestonePercentage
  const dayLeftString = t('dayLeftTicketGoal', 'Day Left')
  const daysLeftString = t('daysLeftTicketGoal', 'Days Left')
  const daysLeft = useMemo(() => {
    if (releaseDate) {
      const daysLeft = new Date(releaseDate).getDate() - new Date().getDate()
      if (daysLeft === 1) return `${daysLeft} ${dayLeftString}`
      if (daysLeft > 1) return `${daysLeft} ${daysLeftString}`
    }
    return null
  }, [dayLeftString, daysLeftString, releaseDate])

  return (
    <div className="w-full">
      {theatricalSlug === slugs.soundOfHopeTheStoryOfPossumTrot && (
        <div>
          <EyebrowSM className="flex w-full items-center justify-center py-0 text-center text-white">
            {t('OneMillionFightersV3', 'We need 1 million fighters. The fight for kids begins the week of the 4th.')}
          </EyebrowSM>
          <hr className="mx-auto my-2 h-0.5 w-48 rounded border-0 bg-gray-500" />
        </div>
      )}
      <div className={classNames('flex items-center justify-between text-gray-200 py-0')}>
        <FlexRow className="gap-1">
          {theatricalSlug === slugs.soundOfHopeTheStoryOfPossumTrot && (
            <div className="flex w-full flex-row items-center justify-center">
              <EyebrowSM>
                {t('numberOfTicketsTicketsPageV2', '{{numTickets}} FIGHTERS', {
                  numTickets: new Intl.NumberFormat(locale).format(ticketsSold),
                })}
              </EyebrowSM>
              &nbsp;&nbsp;
              <Popover
                className="!z-[99999]"
                title={t('numberOfTicketsTicketsPageV2', '{{numTickets}} FIGHTERS', {
                  numTickets: new Intl.NumberFormat(locale).format(ticketsSold),
                })}
                description={t(
                  'SoundOfHopeFightersDescription',
                  'This movie is the beginning of a determined fight for kids.\n' +
                    'In America, there are over 100,000 kids who need a home.\n' +
                    'Unacceptable.\n' +
                    'Sound of Hope: The Story of Possum Trot is the true story of a small community who decided enough was enough, and fought to give 77 kids a loving and safe home.\n' +
                    'Get your ticket and join the fight. We can end the crisis. ',
                )}
                isDarkMode={false}
                triggerComponent={<CircleInfoIcon color="white" className="inline" size={18} />}
              />
            </div>
          )}
          {theatricalSlug !== slugs.soundOfHopeTheStoryOfPossumTrot && (
            <EyebrowSM>
              {t('numberOfTicketsTicketsPage', '{{numTickets}} Tickets', {
                numTickets: new Intl.NumberFormat(locale).format(ticketsSold),
              })}
            </EyebrowSM>
          )}
          {countryCode === slugs.cabrini && (
            <div className="lg:hidden">
              <TicketsInfoIconTooltip
                overlayClassname="left-0"
                shouldUseNewAfterDeathGoal={theatricalSlug === slugs.afterDeath}
              />
            </div>
          )}
        </FlexRow>
        <EyebrowSM>
          {!goalLineInfo?.isLastMilestone ? (
            <Translate
              t={t}
              i18nKey="milestoneDisplayGoalV2"
              values={{
                goalDisplay: new Intl.NumberFormat(locale).format(
                  shouldUseNewAfterDeathGoal ? 166234 : goalLineInfo?.currentMilestone,
                ),
              }}
            >
              Milestone: {{ goalDisplay }}
            </Translate>
          ) : (
            <Translate
              t={t}
              i18nKey="goalDisplayGoal"
              values={{
                goalDisplay: new Intl.NumberFormat(locale).format(
                  shouldUseNewAfterDeathGoal ? 166234 : goalLineInfo?.currentMilestone,
                ),
              }}
            >
              {{ goalDisplay }} Goal
            </Translate>
          )}
        </EyebrowSM>
      </div>

      <div
        className={classNames('w-full my-2', borderRadiusClasses, {
          'bg-gray-800': isDarkMode,
          'bg-gray-200': !isDarkMode,
        })}
      >
        <div
          className={classNames('h-2', borderRadiusClasses)}
          style={{
            width: `${goalPercent > 100 ? 100 : goalPercent}%`,
            backgroundColor: color,
          }}
        />
      </div>
      <div className="flex items-center justify-between text-gray-200">
        {goalLineInfo?.isLastMilestone ? (
          <EyebrowSM>
            {t('progressOfTicketGoalTicketsOfGoal', `{{progress}}% of Goal`, {
              progress: goalPercent,
            })}
          </EyebrowSM>
        ) : (
          <EyebrowSM>
            {t('progressOfTicketGoalTicketsPagev3', `{{progress}}% of Milestone`, {
              progress: goalPercent,
            })}
          </EyebrowSM>
        )}
        {slugs.sight === theatricalSlug && (
          <EyebrowSM>
            <Link className="underline" href="/ambassadors/sight">
              <Translate t={t} i18nKey="iAmIn">
                #IAMIN
              </Translate>
            </Link>
          </EyebrowSM>
        )}
        {theatricalSlug === slugs.cabrini && (
          <EyebrowSM>{t('cabrini2MillionTotalGoal', '2 million total goal')}</EyebrowSM>
        )}
        {shouldShowDaysLeft && daysLeft && <EyebrowSM>{daysLeft}</EyebrowSM>}
      </div>
    </div>
  )
}
