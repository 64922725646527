import React, { FC } from 'react'
import classNames from 'classnames'
import { FlexColumn, FlexRow } from '@/atoms/FlexContainers'
import { CircleStarIcon } from '@/atoms/Icons/CircleStarIcon'
import { Image } from '@/atoms/Image'
import { CaptionSM, EyebrowLG, EyebrowSM, Footer, TitleMD, TitleXS } from '@/atoms/Text'
import { slugs } from '@/constants'
import { useTranslate } from '@/utils/translate/translate-client'
import { useCountDown } from '@/views/TicketCheckoutViews/hooks/useCountDown'
import { DiscountType, useTicketsContext } from '../../TicketsContext'
import { FlexibleBookingToolTip } from '../../ToolTips/FlexibleBookingToolTip'
import { SpecificTimesToolTip } from '../../ToolTips/SpecificTimesToolTip'

interface Props {
  className?: string
  theatricalSlug: string
  code: string
  discount?: DiscountType | null
}

export const EarlyBirdDiscountBanner: FC<Props> = ({ className, code, theatricalSlug, discount }) => {
  const { t } = useTranslate('tickets')
  const { adjustedDiscount } = useTicketsContext()

  return (
    <div
      className={classNames(
        'relative z-[49] bg-cover bg-left bg-no-repeat @sm:max-h-[160px] @2xl:max-h-[120px] bg-[#501519] @container flex items-center justify-between pr-3',
        className,
        theatricalSlug === slugs.soundOfHopeTheStoryOfPossumTrot && 'text-white',
      )}
      style={
        theatricalSlug === slugs.soundOfHopeTheStoryOfPossumTrot
          ? {
              backgroundImage:
                'url(https://images.angelstudios.com/image/upload/v1715801934/hope-vert-banner-vanilla-uw-flip2_2.png)',
            }
          : undefined
      }
    >
      <FlexColumn className="pl-4 @sm:px-6">
        <FlexRow className="gap-1.5">
          <div className="relative mx-auto w-fit">
            <TitleMD className="relative z-10 text-[26px] uppercase" weight="bold" color="white">
              {adjustedDiscount ? `${adjustedDiscount}%` : discount?.description} {t('offV2', 'off')}
            </TitleMD>
          </div>
        </FlexRow>
        <EyebrowLG className="mt-1 text-center uppercase" color="white">
          use code <span className="bg-[#F2A31E] p-0.5 font-bold text-core-gray-950">{code ? code : 'BON24'}</span>
          <br />
          AT CHECKOUT
        </EyebrowLG>
        <div className="mt-1 hidden @sm:block @2xl:hidden">
          <CountdownTimer />
        </div>
      </FlexColumn>
      <FlexColumn className="w-[55%] items-center py-[14px] @sm:w-[250px] @4xl:py-0">
        <TitleMD className="mb-[5px] hidden text-center @sm:block" weight="bold" color="white">
          On Select Showtimes
        </TitleMD>
        <EyebrowSM className="text-center opacity-70 @sm:text-[15px] @sm:leading-[18px]" color="white">
          Opening Week (Nov. 21 - 24)
        </EyebrowSM>
        <SpecificTimesToolTip buttonClassName="focus-visible:outline-none focus-visible:ring-offset-transparent focus-visible:ring-transparent" />
        <Footer color="white" className="mb-[5px] mt-1 flex items-center gap-1 @sm:hidden">
          <CircleStarIcon color="white" /> <span className="opacity-70">For Angel Partner Theaters.</span>
        </Footer>
        <CaptionSM color="white" className="mb-[5px] mt-1 hidden items-center gap-1 @sm:flex">
          <CircleStarIcon color="white" /> <span className="opacity-70">For Angel Partner Theaters.</span>
        </CaptionSM>
        <FlexibleBookingToolTip
          className="mt-1 hidden @sm:block @2xl:hidden"
          buttonClassName="bg-black/30 border-none text-white focus-visible:outline-none focus-visible:ring-offset-transparent focus-visible:ring-transparent"
        />
        <div className="@sm:hidden">
          <CountdownTimer />
        </div>
      </FlexColumn>
      <div className="hidden @2xl:block">
        <div className="rounded-xl border-none bg-black/30 p-2">
          <CountdownTimer />
        </div>
        <FlexibleBookingToolTip
          className="mt-2"
          buttonClassName="bg-black/30 border-none text-white focus-visible:outline-none focus-visible:ring-offset-transparent focus-visible:ring-transparent"
        />
      </div>
      <div className="-mr-3 hidden @4xl:block">
        <Image src="/v1727383544/bonhoeffer/Sight_Image-10.png" alt="Bonhoeffer" width={200} height={200} />
      </div>
    </div>
  )
}

const CountdownTimer = () => {
  const endOfDay = new Date()
  endOfDay.setHours(23, 59, 59, 999)
  const { timeLeft } = useCountDown({ utcTargetDate: endOfDay.toISOString(), shouldPadSeconds: true })
  const { hours, minutes, seconds } = timeLeft
  return (
    <FlexColumn>
      <EyebrowSM className="text-center" weight="semibold" color="white">
        ENDING IN
      </EyebrowSM>
      <FlexRow className="gap-3 self-center">
        <FlexRow className="items-baseline gap-0.5">
          <TitleXS weight="bold" color="white">
            {hours}
          </TitleXS>
          <CaptionSM color="white">hrs</CaptionSM>
        </FlexRow>
        <FlexRow className="items-baseline gap-0.5">
          <TitleXS weight="bold" color="white">
            {minutes}
          </TitleXS>
          <CaptionSM color="white">min</CaptionSM>
        </FlexRow>
        <FlexRow className="items-baseline gap-0.5">
          <TitleXS weight="bold" color="white">
            {seconds}
          </TitleXS>
          <CaptionSM color="white">sec</CaptionSM>
        </FlexRow>
      </FlexRow>
    </FlexColumn>
  )
}
