import React, { useCallback } from 'react'
import classNames from 'classnames'
import { AsSpan, ParagraphSM, Text, TitleXS } from '@/atoms/Text'
import { GroupTicketOption } from '@/types/codegen-contentful'

interface GroupTicketsV2OptionsProps {
  groupTicketOptions: GroupTicketOption[]
  ticketPrice: number
  groupTicketOption: GroupTicketOption
  setGroupTicketOption: React.Dispatch<React.SetStateAction<GroupTicketOption>>
  shouldUseNewDesign?: boolean
}

export const GroupTicketsV2Options: React.FC<GroupTicketsV2OptionsProps> = ({
  groupTicketOptions,
  ticketPrice,
  groupTicketOption,
  setGroupTicketOption,
  shouldUseNewDesign,
}) => {
  const formatSubtitle = useCallback((subTitle: string) => {
    const words = subTitle.split(',')
    words[1] = '\u2192'
    return words.join('')
  }, [])

  if (shouldUseNewDesign) {
    return (
      <>
        <div className="mb-6 mt-4 flex justify-center">
          <form className="flex w-full flex-col gap-2" onSubmit={(e) => e.preventDefault()}>
            {groupTicketOptions.map((info) => {
              const isSelected = groupTicketOption.title === info.title

              return (
                <div key={info.title} className="radio grow-1 select-none" onClick={() => setGroupTicketOption(info)}>
                  <label className="flex w-full cursor-pointer items-center justify-center gap-2">
                    <span
                      className={classNames(
                        'flex gap-2 items-center py-3 pl-4 pr-6 border rounded-full flex-grow-1 max-w-[380px] w-full',
                        isSelected
                          ? 'border-core-gray-950 bg-core-gray-950'
                          : 'border-core-gray-400 hover:bg-core-gray-100',
                      )}
                    >
                      <span
                        className={classNames(
                          'relative h-6 w-6 rounded-full border',
                          isSelected ? 'bg-oxide-bright border-oxide-bright' : 'border-core-gray-400',
                        )}
                      >
                        {isSelected && (
                          <span className="absolute inset-1/2 h-2.5 w-2.5 -translate-x-1/2 -translate-y-1/2 rounded-full bg-black" />
                        )}
                      </span>
                      <span className="flex flex-col gap-1 text-left">
                        <Text
                          as={AsSpan}
                          weight="semibold"
                          className={classNames(
                            'block text-base leading-4',
                            isSelected ? 'text-white' : 'text-core-gray-950',
                          )}
                        >
                          {info.title}
                        </Text>
                        <Text
                          as={AsSpan}
                          weight="semibold"
                          className={classNames(
                            'block text-sm leading-[0.875rem]',
                            isSelected ? '!text-core-gray-500' : '!text-core-gray-600',
                          )}
                        >
                          {formatSubtitle(info.subTitle as string)}
                        </Text>
                      </span>
                      <span className="ml-auto flex flex-col gap-1 text-right">
                        <Text
                          as={AsSpan}
                          weight="semibold"
                          color="core-gray-600"
                          className={classNames(
                            'block line-through leading-4 text-base',
                            isSelected ? '!text-core-gray-500' : '!text-core-gray-600',
                          )}
                        >
                          ${info.totalTicketCount && info.totalTicketCount * ticketPrice}
                        </Text>
                        <Text
                          as={AsSpan}
                          weight="semibold"
                          className={classNames(
                            'text-sm leading-[0.875rem]',
                            isSelected ? '!text-white' : '!text-core-gray-950',
                          )}
                        >
                          ${info.totalTicketCount && (info.totalTicketCount * ticketPrice) / 2}
                        </Text>
                      </span>
                    </span>
                  </label>
                </div>
              )
            })}
          </form>
        </div>
      </>
    )
  }

  return (
    <>
      <div className="mb-6 mt-4 flex justify-center">
        <form className="flex w-full flex-col gap-3" onSubmit={(e) => e.preventDefault()}>
          {groupTicketOptions.map((info) => {
            const isSelected = groupTicketOption.title === info.title

            return (
              <div key={info.title} className="radio grow-1 select-none" onClick={() => setGroupTicketOption(info)}>
                <label className="flex w-full cursor-pointer items-center justify-center gap-2">
                  <span className="relative h-5 w-5 rounded-full border-2">
                    {isSelected && (
                      <span className="absolute inset-1/2 h-3 w-3 -translate-x-1/2 -translate-y-1/2 rounded-full bg-black" />
                    )}
                  </span>
                  <span
                    className={classNames(
                      'flex justify-between py-3 px-4 border-2 rounded-lg flex-grow-1 max-w-[380px] w-full',
                      isSelected ? 'border-core-gray-950' : 'border-core-gray-400',
                    )}
                  >
                    <span className="text-left">
                      <TitleXS as={AsSpan} weight="bold" className="block">
                        {info.title}
                      </TitleXS>
                      <ParagraphSM as={AsSpan} weight="medium" color="core-gray-600" className="block">
                        {formatSubtitle(info.subTitle as string)}
                      </ParagraphSM>
                    </span>
                    <span className="text-right">
                      <TitleXS as={AsSpan} weight="bold" color="core-gray-400" className="block line-through">
                        ${info.totalTicketCount && info.totalTicketCount * ticketPrice}
                      </TitleXS>
                      <TitleXS as={AsSpan} weight="bold" className="block">
                        ${info.totalTicketCount && (info.totalTicketCount * ticketPrice) / 2}
                      </TitleXS>
                    </span>
                  </span>
                </label>
              </div>
            )
          })}
        </form>
      </div>
    </>
  )
}
