import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { Button } from '@/atoms/Button'
import { CopyIcon } from '@/atoms/Icons/CopyIcon'
import { useSafeTrack } from '@/utils/analytics'
import { useTranslate } from '@/utils/translate/translate-client'

interface InstantPromoCodeInputProps {
  promoCode: string
}

export const InstantPromoCodeInput: React.VFC<InstantPromoCodeInputProps> = ({ promoCode }) => {
  const [copiedPromoCode, setCopiedPromoCode] = useState<boolean>(false)
  const { t } = useTranslate('tickets')
  const track = useSafeTrack()

  useEffect(() => {
    if (!copiedPromoCode) return
    const copyTimout = setTimeout(() => {
      setCopiedPromoCode(false)
    }, 3000)

    return () => {
      clearTimeout(copyTimout)
    }
  }, [copiedPromoCode])

  const variants = {
    show: { opacity: 1, display: 'inline' },
    hide: { opacity: 0, display: 'none' },
  }
  return (
    <Button
      key={promoCode}
      variant="plain"
      className="mt-2 flex w-full items-center justify-between rounded-md bg-core-gray-100 px-2 py-3"
      onClick={async () => {
        await navigator.clipboard.writeText(promoCode)
        setCopiedPromoCode(true)
        track('Ticket Promo Code Copied')
      }}
    >
      {promoCode}
      <div className="flex items-center gap-1">
        <CopyIcon color="core-gray-700" size={22} />{' '}
        <motion.span className="text-core-gray-700" variants={variants} animate={copiedPromoCode ? 'hide' : 'show'}>
          {t('copyPromoCode', 'Copy')}
        </motion.span>
        <motion.span
          className="text-core-gray-700"
          variants={variants}
          animate={copiedPromoCode ? 'show' : 'hide'}
          initial={{ display: 'none' }}
        >
          {t('copiedPromoCode', 'Copied!')}
        </motion.span>
      </div>
    </Button>
  )
}
