import React, { useMemo } from 'react'
import { CircleInfoIcon } from '@/atoms/Icons/CircleInfoIcon'
import { ResponsiveBackgroundImage } from '@/atoms/ResponsiveBackgroundImage'
import { LabelXS } from '@/atoms/Text'
import { useTranslate } from '@/utils/translate/translate-client'
import {
  HomesteadTitleMD,
  HomesteadTitleXL,
  HomesteadTitleXS,
} from '@/views/PreSales/PreSalesGiveawayView/components/Typography'

export const PreOrderHomesteadGiveawayBanner: React.FC = () => {
  const { t } = useTranslate('tickets')

  const label = useMemo(
    () => (
      <LabelXS className="flex items-center gap-1 rounded-full bg-lightened-200 px-3 py-1 text-white backdrop-blur-xl">
        <CircleInfoIcon size={12} />
        {t('withAngelPartnerTheaters', 'With Angel Partner Theaters')}
      </LabelXS>
    ),
    [t],
  )

  return (
    <div className="relative bg-[#15120F] @container">
      <ResponsiveBackgroundImage
        src="/v1731110392/Homestead/homestead-banner-bg-fade"
        className="hidden xs:block"
        backgroundSize="bg-contain"
        backgroundPosition="bg-right"
        responsiveAR={{
          xs: 2,
          sm: 2,
          md: 2,
          lg: 2,
          xl: 2,
          '2xl': 2,
          '3xl': 2,
        }}
      />
      <div className="relative z-10 flex flex-col items-start gap-2 px-4 py-6 @2xl:flex-row @2xl:items-center">
        <div>
          <HomesteadTitleMD color="white" className="!text-[26px] md:!text-[28px]">
            {t('thankYouForPreOrderingTicketsExclamation', 'Thank you for pre-ordering tickets!')}
          </HomesteadTitleMD>
          <div className="flex items-center gap-5">
            <HomesteadTitleXL className="!font-normal text-homestead-yellow">
              {t('promoCodeApplied', 'Promo code applied')}
            </HomesteadTitleXL>
            <div className="hidden @2xl:block">{label}</div>
          </div>
          <HomesteadTitleXS color="white" className="leading-[1.1]">
            {t('simplyChooseAShowtimeAndCheckout', 'Simply choose a showtime and checkout.')}
          </HomesteadTitleXS>
        </div>
        <div className="@2xl:hidden">{label}</div>
      </div>
    </div>
  )
}
