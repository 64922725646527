import React from 'react'
import { getHexColor, OneColorIconProps } from '@/atoms/utils'

export const GuildShieldSolid: React.FC<OneColorIconProps> = ({ color = 'gray-100', size = 16 }) => {
  const c = getHexColor(color)

  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.66675 2H13.3334C13.8857 2 14.3334 2.44736 14.3334 2.99964V7.92593C14.3334 10.8889 11.9584 14.2222 8.00008 15.3333C4.04175 14.2222 1.66675 10.8889 1.66675 7.92593V2.99996C1.66675 2.44768 2.11446 2 2.66675 2ZM10.3042 7.45803H8.00642C7.98276 7.4546 7.95911 7.45803 7.93546 7.46489C7.9118 7.47174 7.89153 7.48546 7.87463 7.5026C7.85774 7.51975 7.84422 7.54032 7.83408 7.56089C7.82394 7.58147 7.82057 7.60547 7.82057 7.62947V8.61012C7.82057 8.63412 7.82394 8.65812 7.83408 8.6787C7.84422 8.69927 7.85774 8.71984 7.87463 8.73699C7.89153 8.75413 7.9118 8.76442 7.93208 8.7747C7.95573 8.78156 7.976 8.78499 7.99966 8.78499C8.18213 8.78499 8.83769 8.78499 9.04043 8.79528V9.73478C8.77686 9.82736 8.50315 9.86851 8.22606 9.86165C7.10419 9.83765 6.41485 9.01129 6.41485 7.9895C6.41485 6.97799 7.10419 6.11392 8.21592 6.13792C8.65859 6.14821 9.06746 6.27508 9.41551 6.62139C9.45268 6.64539 9.49661 6.65911 9.54054 6.65911C9.58447 6.65911 9.6284 6.64539 9.66557 6.62139L10.3786 5.86362C10.3955 5.84647 10.409 5.8259 10.4191 5.80533C10.4293 5.78132 10.4326 5.75732 10.4326 5.73332C10.4326 5.70932 10.4259 5.68532 10.4124 5.66474C10.4022 5.64417 10.3853 5.6236 10.365 5.60988C9.73991 5.03384 8.88837 4.69095 8.11793 4.66695C6.2932 4.64295 4.83342 6.15164 4.83342 7.99979C4.83342 9.85136 6.2932 11.288 8.11793 11.3326C8.91202 11.3463 9.69598 11.1612 10.3988 10.7909C10.4191 10.7737 10.436 10.7531 10.4461 10.7291C10.4563 10.7051 10.463 10.6777 10.463 10.6537V7.63976C10.463 7.59175 10.4428 7.54718 10.4124 7.51632C10.3921 7.48203 10.3482 7.46146 10.3042 7.45803Z"
        fill={c}
      ></path>
    </svg>
  )
}
