import React, { useEffect } from 'react'
import { Else, If, Then } from 'react-if'
import { LinkButton } from '@/atoms/Button'
import { EnvelopeIcon } from '@/atoms/Icons/EnvelopeIcon'
import { HeadingSM, ParagraphMD, ParagraphSM } from '@/atoms/Text'
import { VoucherWaitlistResponse } from '@/types/codegen-federation'
import { useSafeTrack } from '@/utils/analytics'
import { useTranslate } from '@/utils/translate/translate-client'
import { ModalState } from '@/views/TicketCheckoutViews/ShowtimesView/components/TicketsPromo/FreeTicketModal'
import { InstantPromoCodeInput } from '@/views/TicketCheckoutViews/ShowtimesView/components/TicketsPromo/InstantPromoCodeInput'

interface InstantPromoCodeProps {
  showTimeLink: VoucherWaitlistResponse['showtimeLink']
  voucherCodes: VoucherWaitlistResponse['voucherCodes']
  setModalState: React.Dispatch<React.SetStateAction<ModalState>>
  viaWhatsApp: boolean
}

export const InstantPromoCodeV2: React.VFC<InstantPromoCodeProps> = ({
  viaWhatsApp,
  showTimeLink,
  voucherCodes,
  setModalState,
}) => {
  const { t } = useTranslate('tickets')
  const track = useSafeTrack()

  useEffect(() => {
    track('ticket instant redemption shown', { voucherCodes, showTimeLink })
  }, [track, voucherCodes, showTimeLink])

  if (!showTimeLink || !voucherCodes) {
    setModalState('error')
    return null
  }

  return (
    <div className="mx-auto sm:w-[420px]">
      <div className="flex flex-row items-center justify-center">
        <HeadingSM weight="bold">{t('claimedFreeTicket!', 'Claimed Free Ticket!')}</HeadingSM>
      </div>
      <div className="mt-8">
        <>
          <ParagraphMD className="text-left">{t('ticketPromoCode', 'Promo Code')}</ParagraphMD>
          {voucherCodes.map((voucherCode) => {
            if (!voucherCode) return setModalState('error')
            return <InstantPromoCodeInput key={voucherCode} promoCode={voucherCode} />
          })}
        </>
      </div>
      <LinkButton
        onClick={() => {
          track('Clicked Continue To Redeem Ticket Promo Code')
        }}
        className="mt-8 flex justify-center py-3 font-bold"
        href={showTimeLink}
        variant="black"
      >
        {t('continueToRedeem', 'Continue to Redeem')}
      </LinkButton>
      <div className="mt-2 flex items-center justify-center gap-1">
        <EnvelopeIcon color="core-gray-700" size={20} />
        <If condition={viaWhatsApp}>
          <Then>
            <ParagraphSM className="text-core-gray-500">
              {t(
                'thePromoCodeWillAlsoBeAvailableInWhatsApp',
                'The promo code will be sent to WhatsApp when a voucher is available.',
              )}
            </ParagraphSM>
          </Then>
          <Else>
            <ParagraphSM className="text-core-gray-500">
              {t('thePromoCodeWillAlsoBeAvailableInInbox', 'The promo code will also be available in your inbox!')}
            </ParagraphSM>
          </Else>
        </If>
      </div>
    </div>
  )
}
