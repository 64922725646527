import React, { FC, MouseEventHandler, useMemo } from 'react'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { ParsedUrlQuery } from 'querystring'
import { LinkButton } from '@/atoms/Button'
import { FlexRow } from '@/atoms/FlexContainers'
import { FireIcon } from '@/atoms/Icons/FireIcon'
import { LoadingSpinnerIcon } from '@/atoms/Icons/LoadingSpinnerIcon'
import { CaptionXS, LabelSM } from '@/atoms/Text'
import { paths } from '@/constants/paths'
import { TheatricalShowtime } from '@/types/codegen-federation'
import { useLocale } from '@/utils/LocaleUtil'
import { useTranslate } from '@/utils/translate/translate-client'
import { useEnhancedShowtimeContext } from '@/views/TicketCheckoutViews/ShowtimesView/components/EnhancedShowtimeContext'
import { convertUTCToLocalTime } from '@/views/TicketCheckoutViews/ShowtimesView/components/TicketsPromo/utils'

interface ShowTimeLinkProps {
  showTime: TheatricalShowtime
  countryCode: string
  onClick: MouseEventHandler<HTMLAnchorElement>
  query: ParsedUrlQuery
  reservationId?: string
  theaterSlug: string
  isVenueIntegrated: boolean
  isDisabled?: boolean
  isLoading?: boolean
}

export const ShowTimeLink: React.FC<ShowTimeLinkProps> = ({
  showTime,
  countryCode,
  onClick,
  isVenueIntegrated,
  theaterSlug,
  isDisabled = false,
  isLoading = false,
}) => {
  const { query } = useRouter()
  const { isShowtimeEnhanced } = useEnhancedShowtimeContext()
  const { locale } = useLocale()

  const hasPurchaseTicketsUrl = !!showTime?.ticketUrl
  const isSoldOut = showTime?.availableInventory === 0
  const hasShowTimePassed = showTime?.utcStartTime && new Date(showTime?.utcStartTime) < new Date()
  const isUtmSourceMobileApp = query?.['utm_source'] === 'livestream-claim-tickets-cta'
  const targetType = isVenueIntegrated || isUtmSourceMobileApp ? '_self' : '_blank'
  const shouldDisplayFlame = useMemo(
    () => isShowtimeEnhanced(showTime?.ticketingId ?? ''),
    [isShowtimeEnhanced, showTime?.ticketingId],
  )
  const startTime = convertUTCToLocalTime(showTime?.utcStartTime, showTime?.localTimeOffset, locale)

  const href = useMemo(() => {
    if (isVenueIntegrated) {
      if (showTime?.reservable) {
        return paths.tickets.checkout.seatMap(theaterSlug, showTime?.id)
      }
      return paths.tickets.checkout.seatQuantitySelection(theaterSlug, showTime?.id)
    }
    return showTime?.ticketUrl ?? '#'
  }, [isVenueIntegrated, showTime?.id, showTime?.reservable, showTime?.ticketUrl, theaterSlug])

  const isLinkEnabled = useMemo(() => {
    if (query?.promo && countryCode === 'PH' && !hasShowTimePassed) return true
    return !isSoldOut && !hasShowTimePassed && hasPurchaseTicketsUrl && !isDisabled
  }, [hasPurchaseTicketsUrl, hasShowTimePassed, isDisabled, isSoldOut, query?.promo, countryCode])

  const iconVariant: IconType | null = useMemo(() => {
    if (isSoldOut) return 'sold-out'
    if (shouldDisplayFlame) return 'flame'
    return null
  }, [isSoldOut, shouldDisplayFlame])

  if (!startTime) return null

  return (
    <LinkButton
      onClick={onClick}
      target={targetType}
      disabled={!isLinkEnabled}
      className={classNames(
        'relative h-10 w-full max-w-[5.4rem] shrink-0 p-0 md:max-w-[6.5rem]',
        !isLinkEnabled && 'pointer-events-none',
      )}
      href={href}
      variant="oxide-300"
      data-dd-action-name="Tickets Showtime Selected"
      external={true}
    >
      {!isLoading && (
        <FlexRow className="gap-1">
          <LabelSM weight="semibold">{startTime}</LabelSM>
        </FlexRow>
      )}
      {isLoading && <LoadingSpinnerIcon color="core-gray-950" size={16} />}
      {iconVariant && <ShowtimeIcon variant={iconVariant} />}
    </LinkButton>
  )
}

type IconType = 'sold-out' | 'flame'

interface showtimeIconProps {
  variant: IconType | null
}
const ShowtimeIcon: FC<showtimeIconProps> = ({ variant }) => {
  const { t } = useTranslate('tickets')

  if (variant === 'flame') {
    return (
      <div className="absolute -right-2 -top-3 rounded-full border-[1.5px] border-warm-01 bg-warm-01 p-1">
        <FireIcon color="white" size={12} />
      </div>
    )
  }

  if (variant === 'sold-out') {
    return (
      <div className="absolute -right-2 -top-3 shrink-0 rounded-full border-[1.5px] border-warm-01 bg-warm-01 px-1.5">
        <CaptionXS weight="bold" className="text-[0.5rem]" color="white">
          {t('soldOut', 'Sold Out')}
        </CaptionXS>
      </div>
    )
  }

  return null
}
