import React from 'react'
import classNames from 'classnames'
import { Button } from '@/atoms/Button'
import { LabelSM } from '@/atoms/Text'
import { useThemeContext } from '@/contexts/ThemeContext'
import { useTranslate } from '@/utils/translate/translate-client'

interface GroupTicketsButtonProps {
  onClick: () => void
}
const GroupTicketsButton = ({ onClick }: GroupTicketsButtonProps) => {
  const { t } = useTranslate('tickets')
  const { isDarkMode } = useThemeContext()
  const lightModeVariant = 'core-gray-800'
  return (
    <div>
      <Button
        onClick={onClick}
        className={classNames(
          'relative flex h-10 shrink-0 items-center justify-center gap-2 rounded-[8px] px-3 py-3 hover:scale-105',
          !isDarkMode && 'border border-core-gray-200',
        )}
        variant={isDarkMode ? 'white' : lightModeVariant}
      >
        <LabelSM weight="medium"> {t('groupDiscount', 'Group Discount')}</LabelSM>
      </Button>
    </div>
  )
}

export default GroupTicketsButton
