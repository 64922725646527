import React from 'react'
import classNames from 'classnames'
import { AutoFollowChatMessageContainer } from '@/molecules/AutoFollowChatMessageContainer'
import { TheatricalReleaseObject } from '@/types/codegen-federation'

interface HypebotProps {
  isOneLineMessages?: boolean
  hideScrollbar?: boolean
  className?: string
  isNewDesign?: boolean
  initialMessageClassName?: string
  theatricalRelease?: TheatricalReleaseObject | null
}

export const Hypebot: React.FC<HypebotProps> = ({
  className,
  isOneLineMessages,
  hideScrollbar,
  isNewDesign = false,
  initialMessageClassName,
  theatricalRelease,
}) => {
  return (
    <div
      className={classNames(
        'flex flex-col justify-between pb-3 shadow-xl rounded-b-2xl overflow-hidden w-full min-w-52 h-full bg-core-gray-950 relative shrink grow-1 min-h-0',
        className,
      )}
    >
      <AutoFollowChatMessageContainer
        initialMessageClassName={initialMessageClassName}
        isNewDesign={isNewDesign}
        isOneLineMessages={isOneLineMessages}
        hideScrollbar={hideScrollbar}
        theatricalRelease={theatricalRelease}
      />
    </div>
  )
}
