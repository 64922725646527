/* eslint-disable sonarjs/cognitive-complexity */
import React, { FC } from 'react'
import { useExperiment } from '@growthbook/growthbook-react'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { TheatricalPromoType, TicketPromoTypes } from '@/services/Theatrical/contentfulQueries'
import { isTheatricalPromoValid } from '@/services/Theatrical/utils'
import { DiscountType, useTicketsContext } from '@/views/TicketCheckoutViews/ShowtimesView/components/TicketsContext'
import BogoBanner from '@/views/TicketCheckoutViews/ShowtimesView/components/banners/BogoBanner'
import FlashSaleBanner from '@/views/TicketCheckoutViews/ShowtimesView/components/banners/DiscountBanner/FlashSaleBanner'
import NoFeesBanner from '@/views/TicketCheckoutViews/ShowtimesView/components/banners/NoFeesBanner'
import { AntisemitePromoBanner } from './AntisemitePromoBanner'
import { EarlyBirdDiscountBanner } from './EarlyBirdDiscountBanner'

interface EarlyBirdDiscountProps {
  className?: string
  discount: DiscountType | null
  promo?: TheatricalPromoType
  theatricalSlug: string
  calendarRef?: React.RefObject<HTMLDivElement>
}

export const TicketPromoBanner: FC<EarlyBirdDiscountProps> = ({
  className,
  discount,
  promo,
  theatricalSlug,
  calendarRef,
}) => {
  const { promoExperiment } = useTicketsContext()
  const { query } = useRouter()
  const bogoCode = query?.bogocode as string

  // TODO: create a variations array with variations in Contentful and pass through accordingly
  const experimentDetails = useExperiment({
    key: promoExperiment?.experimentName ?? '',
    variations: ['EARLY_BIRD', 'NO_FEES'],
  })

  const experimentValue = experimentDetails?.value ?? undefined

  const getPromoBasedOnExperiment = () => {
    if (experimentValue) {
      if (promo && promo.type === experimentValue) {
        return promo
      } else {
        const experimentPromo = promo?.experimentDataCollection?.items?.find(
          (item) => item && item.type === experimentValue,
        )
        return experimentPromo ?? promo
      }
    }
    return promo
  }

  const isPromoValid = isTheatricalPromoValid(promo)
  const ticketPromo = getPromoBasedOnExperiment()

  const promoType = ticketPromo?.type

  if (query?.promo === 'none') return null

  if (query?.promo === 'stop-hate') {
    return <AntisemitePromoBanner className={className} calendarRef={calendarRef} />
  }

  if (query?.promo === 'bogo' && bogoCode) {
    return <BogoBanner theatricalSlug={theatricalSlug} className={className} code={bogoCode} />
  }

  if (!isPromoValid) return null

  if (promoType === TicketPromoTypes.FlashSale && discount) {
    return <FlashSaleBanner theatricalSlug={theatricalSlug} discount={discount} className={className} />
  }

  if (promoType === TicketPromoTypes.EarlyBird && discount) {
    return (
      <EarlyBirdDiscountBanner
        theatricalSlug={theatricalSlug}
        discount={discount}
        className={className}
        code={ticketPromo?.code as string}
      />
    )
  }

  if (promoType === TicketPromoTypes.NoFees) {
    return (
      <NoFeesBanner
        theatricalSlug={theatricalSlug}
        code={ticketPromo?.code as string}
        className={classNames('w-full', className)}
      />
    )
  }

  return null
}
