import React from 'react'
import { Button } from '@/atoms/Button'
import { ArrowLeftIcon } from '@/atoms/Icons/ArrowLeftIcon'
import { HeadingSM, ParagraphMD } from '@/atoms/Text'
import { Accordion, AccordionItem } from '@/molecules/Accordion'
import { Translate, useTranslate } from '@/utils/translate/translate-client'
import { GroupTicketsModalTypes } from '@/views/TicketCheckoutViews/ShowtimesView/components/modals/GroupTicketsModal/GroupTicketsModal'

interface GroupTicketsFaqProps {
  setModalType: React.Dispatch<React.SetStateAction<GroupTicketsModalTypes>>
}

export const GroupTicketsFaq: React.FC<GroupTicketsFaqProps> = ({ setModalType }) => {
  const { t } = useTranslate('tickets')

  return (
    <>
      <Button
        onClick={() => setModalType('tickets')}
        className="absolute left-6 top-6 cursor-pointer rounded-full bg-core-gray-950 p-1"
        variant="black"
      >
        <ArrowLeftIcon size={18} />
      </Button>
      <HeadingSM className="mt-10 text-[1.75rem]" color="core-gray-950" weight="bold">
        {t('ticketFaqTitle', 'Frequently Asked Questions')}
      </HeadingSM>
      <div className="mt-4 border-t border-core-gray-300">
        <Accordion>
          <AccordionItem
            contentElementId="collapsible-content-which-theaters-support-this-promotion"
            iconColor="core-gray-950"
            triggerClassName="!border-t-0"
            labelClassName="text-lg text-core-gray-950"
            label={t('whichTheatersSupportThisPromotion', 'Which theaters support this promotion?')}
            defaultOpen
          >
            <ParagraphMD className="text-left font-medium text-core-gray-700">
              <Translate t={t} i18nKey="couponCodesAreValidAtAnyTheaterGroupsAngelPartner">
                Coupon codes are valid at any Angel Partner theater.
              </Translate>
            </ParagraphMD>
          </AccordionItem>
        </Accordion>
      </div>
      <div className="border-t border-core-gray-300">
        <Accordion>
          <AccordionItem
            contentElementId="collapsible-content-why-are-group-tickets-discounted"
            iconColor="core-gray-950"
            defaultOpen
            labelClassName="text-lg text-core-gray-950"
            label={t('whatAreGroupTicketsDiscounted', 'Why are group tickets discounted?')}
            triggerClassName="!border-none"
          >
            <ParagraphMD className="text-left font-medium text-core-gray-700">
              {t(
                'theAngelCommunityWillMatchYourGroupTicket',
                'The Angel Community will match your group ticket purchase (ex: buy 10 get 10 free)',
              )}
            </ParagraphMD>
          </AccordionItem>
        </Accordion>
      </div>
      <div className="mt-4 border-y border-core-gray-300">
        <Accordion>
          <AccordionItem
            contentElementId="collapsible-content-why-are-group-tickets-discounted"
            iconColor="core-gray-950"
            defaultOpen
            labelClassName="text-lg text-core-gray-950"
            label={t('whatAreGroupTicketsDiscounted', 'Why are group tickets discounted?')}
            triggerClassName="!border-none"
          >
            <ParagraphMD className="text-left font-medium text-core-gray-700">
              {t(
                'youWillGetACouponCodeGoodForUpToTotalSize',
                'You will get a coupon code good for up to the total size of your group. Pass your coupon code to your group so they can pick their own seats.',
              )}
            </ParagraphMD>
          </AccordionItem>
        </Accordion>
      </div>

      <Button
        onClick={() => setModalType('tickets')}
        className="mx-auto mt-6 flex w-fit items-center justify-center px-16"
        variant="black"
      >
        {t('ticketsContinue', 'Continue')}
      </Button>
    </>
  )
}
