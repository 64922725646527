import React, { FC, useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'
import { Button } from '@/atoms/Button'
import { FlexColumn, FlexRow } from '@/atoms/FlexContainers'
import { CheckIcon } from '@/atoms/Icons/CheckIcon'
import { CloseIcon } from '@/atoms/Icons/CloseIcon'
import { CopyIcon } from '@/atoms/Icons/CopyIcon'
import { Image } from '@/atoms/Image'
import { CaptionSM, EyebrowMD, EyebrowSM, HeadingLG, TitleMD } from '@/atoms/Text'
import { slugs } from '@/constants'
import { Modal } from '@/molecules/Modal'
import { useTranslate } from '@/utils/translate/translate-client'
import { DiscountType, useTicketsContext } from '@/views/TicketCheckoutViews/ShowtimesView/components/TicketsContext'
import { FlexibleBookingToolTip } from '@/views/TicketCheckoutViews/ShowtimesView/components/ToolTips/FlexibleBookingToolTip'
import { SpecificTimesToolTip } from '@/views/TicketCheckoutViews/ShowtimesView/components/ToolTips/SpecificTimesToolTip'
import TimeLeft from '@/views/TicketCheckoutViews/ShowtimesView/components/banners/DiscountBanner/TimeLeft'

interface Props {
  onClose: () => void
  open: boolean
  theatricalSlug: string
  utcTimeUntilDiscountExpires?: string | null
  discount: DiscountType
}

const EarlyBirdModal: FC<Props> = ({ open, onClose, utcTimeUntilDiscountExpires, discount, theatricalSlug }) => {
  const { t } = useTranslate('tickets')
  const { adjustedDiscount } = useTicketsContext()

  const [copiedCode, setCopiedCode] = useState(false)

  const handleCopyCode = useCallback(() => {
    if (discount?.code != null) {
      navigator.clipboard.writeText(discount?.code)
      setCopiedCode(true)
    }
  }, [discount?.code])

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>

    if (copiedCode) {
      timer = setTimeout(() => {
        setCopiedCode(false)
      }, 3000)
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [copiedCode, setCopiedCode])

  return (
    <Modal
      backgroundScreenColor="black"
      isOpen={open}
      onClose={onClose}
      panelClasses="!bg-[url(https://images.angelstudios.com/image/upload/v1727392065/bonhoeffer/bonhoeffer_early_bird_modal.jpg)] bg-bottom bg-cover !text-white !max-w-[380px] shadow-dark-4 rounded-3xl !p-6 !pt-10 relative text-center !overflow-visible"
      transition="appear"
      className="z-[99999]"
    >
      <div>
        <div className="absolute right-6 top-6 cursor-pointer rounded-full bg-white/20 p-1" onClick={onClose}>
          <CloseIcon color="white" size={18} />
        </div>
        <FlexColumn className="relative z-10 gap-6">
          {utcTimeUntilDiscountExpires && (
            <FlexColumn className="relative mx-auto w-full max-w-[177px] rounded-2xl border border-white/30 px-3 py-2">
              <EyebrowSM className="absolute -top-2 left-1/2 -translate-x-1/2 bg-[#6b1e22] px-1 uppercase">
                {t('endingInCount', 'Ending In')}
              </EyebrowSM>
              <TimeLeft isModal className="gap-4 border-white/30" />
            </FlexColumn>
          )}
          <FlexColumn className="relative z-20 shrink-0 items-center gap-1.5">
            <HeadingLG className="uppercase" weight="bold">
              {adjustedDiscount ? `${adjustedDiscount}%` : discount?.description} {t('offEarlyBird', 'off')}
            </HeadingLG>
            <FlexColumn className="items-center gap-0.5">
              <FlexRow className="gap-1">
                <EyebrowMD className="text-[0.938rem] uppercase">{t('useCode', 'Use Code')}</EyebrowMD>
                <div className={classNames('relative flex h-[26px] items-center justify-center overflow-hidden px-1')}>
                  <EyebrowMD
                    weight="bold"
                    color={theatricalSlug === slugs.soundOfHopeTheStoryOfPossumTrot ? 'white' : 'black'}
                    className="relative z-10 text-[0.938rem] uppercase"
                  >
                    {discount?.code}
                  </EyebrowMD>
                  {theatricalSlug !== slugs.soundOfHopeTheStoryOfPossumTrot && (
                    <Image
                      className="absolute inset-0 scale-150 p-1"
                      alt=""
                      src={`/v1712075285/brush-stroke-orange-01.svg`}
                      fill
                    />
                  )}
                </div>
              </FlexRow>
              <EyebrowMD className="text-[0.938rem] uppercase">{t('atCheckout', 'At Checkout')}</EyebrowMD>
            </FlexColumn>
          </FlexColumn>

          <FlexColumn className="gap-1">
            <TitleMD weight="bold">{t('OnSelectShowtimes', 'On Select Showtimes')}</TitleMD>
            {theatricalSlug === slugs.bonhoeffer && (
              <EyebrowMD className="opacity-70">OPENING WEEK (Nov 21-24)</EyebrowMD>
            )}
            <FlexRow className="justify-center gap-1">
              <SpecificTimesToolTip />
            </FlexRow>
            <FlexibleBookingToolTip
              className="mx-auto w-fit pt-5"
              panelPosition="CENTER"
              buttonClassName={classNames(
                'backdrop-blur border-none w-full',
                theatricalSlug === slugs.soundOfHopeTheStoryOfPossumTrot ? 'bg-white/20' : 'bg-black/30',
              )}
            />
          </FlexColumn>
          <FlexColumn className="items-center gap-3">
            <Button onClick={handleCopyCode} className="flex w-fit items-center gap-1" variant="white">
              {copiedCode ? (
                <CheckIcon size={24} color="core-gray-950" />
              ) : (
                <CopyIcon size={24} color="core-gray-950" />
              )}
              {copiedCode ? t('copiedCode', 'Copied Code') : t('copyCode', 'Copy Code')}
            </Button>
            <CaptionSM italic color="core-gray-400">
              {t('availableForAngelPartneringTheaters', 'Available for Angel Partnering Theaters.')}
            </CaptionSM>
          </FlexColumn>
        </FlexColumn>
      </div>
    </Modal>
  )
}

export default EarlyBirdModal
