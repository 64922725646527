import React, { FC } from 'react'
import { Button, LinkButton } from '@/atoms/Button'
import { CloseIcon } from '@/atoms/Icons/CloseIcon'
import { HeadingSM, ParagraphSM } from '@/atoms/Text'
import { Modal } from '@/molecules/Modal'
import { TheatricalShowtimeVenue } from '@/types/codegen-federation'
import { useTranslate } from '@/utils/translate/translate-client'
import { GroupTicketsModalTypes } from '@/views/TicketCheckoutViews/ShowtimesView/components/modals/GroupTicketsModal/GroupTicketsModal'

interface BuyoutModalProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  openGroupTicket?: (type: GroupTicketsModalTypes) => void
  buyoutVenue: TheatricalShowtimeVenue | null
  venueChain: string
}
const BuyoutModal: FC<BuyoutModalProps> = ({ open, setOpen, openGroupTicket, buyoutVenue, venueChain }) => {
  const { t } = useTranslate('tickets')
  const buyoutLink = (() => {
    switch (venueChain) {
      case 'amc':
        return 'https://www.amctheatres.com/rentals'
      case 'cinemark':
        return 'https://www.cinemark.com/private-events/premium-private-screenings/'
      case 'regal':
        return 'https://www.corporateboxoffice.com/event-request-form'
      default:
        return ''
    }
  })()

  const venueChainLabel = (() => {
    switch (venueChain) {
      case 'amc':
        return 'AMC Theatres'
      case 'cinemark':
        return 'Cinemark'
      case 'regal':
        return 'Regal Cinemas'
      default:
        return ''
    }
  })()

  return (
    <Modal
      backgroundScreenColor="black"
      backgroundOverlayClassName="overflow-y-auto min-h-screen"
      className="z-[9999]"
      isOpen={open}
      transition="appear"
      panelClasses="!w-[calc(100%-2em)] md:w-full !max-w-[540px] overflow-y-auto !p-6 !pt-12 md:!px-10 md:!pb-8 shadow-light-3"
    >
      <div
        className="absolute right-6 top-6 cursor-pointer rounded-full bg-black/10 p-2"
        onClick={() => setOpen((state) => !state)}
      >
        <CloseIcon color="black" size={16} />
      </div>
      <div className="!max-w-[460px]">
        <div>
          <HeadingSM className="mt-6 text-center text-[1.75rem] sm:mt-0 md:mt-8" color="core-gray-950" weight="bold">
            {t('privateShowing', 'Private Showing')}
          </HeadingSM>
          <ParagraphSM className="px-3 pt-2 text-center">
            Enjoy our upcoming movie in a private setting at {venueChainLabel ? venueChainLabel : 'this theater'}. Use
            the info below to reserve your screening.
          </ParagraphSM>
          <div className="justify-center p-6 text-center md:flex md:py-8">
            {(buyoutVenue?.ticketingProviderSlug === 'atom' || buyoutLink) && (
              <div className="pr-4">
                <span className="photon-title-sm block font-whitney font-semibold leading-8 text-core-gray-950">
                  Schedule Online
                </span>
                <LinkButton
                  className="photon-paragraph-sm mx-auto mt-2 flex w-fit items-center  justify-center px-6 py-2 md:mt-2"
                  href={buyoutLink}
                  variant="black"
                  external
                >
                  {t('learnMore', 'Learn More')}
                </LinkButton>
              </div>
            )}
            {buyoutVenue?.phone && (
              <div className="mt-6 pl-4 md:mt-0">
                <span className="photon-title-sm block font-whitney font-semibold leading-8 text-core-gray-950">
                  Call to Schedule
                </span>
                <LinkButton
                  className="photon-paragraph-sm mx-auto mt-1 flex w-fit items-center  justify-center px-6 py-2 md:mt-2"
                  href={`tel:${buyoutVenue?.phone}`}
                  variant="lightgray"
                >
                  {buyoutVenue?.phone}
                </LinkButton>
              </div>
            )}
          </div>
          {buyoutVenue?.ticketingProviderSlug === 'atom' && (
            <div className="justify-center border-t-[1px] border-gray-400 pt-6  text-center md:pt-8 ">
              <ParagraphSM color="core-gray-700">
                {t('interestedInGroupTickets', 'Interested in Group Tickets?')}
              </ParagraphSM>
              <Button
                onClick={() => {
                  openGroupTicket && openGroupTicket('tickets')
                  setOpen((state) => !state)
                }}
                className="photon-paragraph-sm border-none !p-0 text-core-gray-700 underline"
                variant="plain"
              >
                Learn More
              </Button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default BuyoutModal
