import React from 'react'
import { getHexColor, OneColorIconProps } from '../utils'

export const BlockedIcon: React.FC<OneColorIconProps> = ({ size = 14, color = 'error-700', className }) => {
  const hex = getHexColor(color)

  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon">
        <path
          id="Vector"
          d="M9.86736 10.6847L9.87844 10.676L9.86847 10.666L3.33514 4.13266L3.32518 4.1227L3.31647 4.13378C2.9948 4.54319 2.74864 4.99162 2.57805 5.47904C2.40743 5.96651 2.32214 6.47346 2.32214 6.99984C2.32214 8.30586 2.77549 9.41253 3.68205 10.3191C4.58861 11.2257 5.69528 11.679 7.0013 11.679C7.52768 11.679 8.03463 11.5937 8.5221 11.4231C9.00952 11.2525 9.45795 11.0063 9.86736 10.6847ZM10.6675 9.86701L10.6774 9.87697L10.6861 9.86589C11.0078 9.45649 11.254 9.00805 11.4246 8.52063C11.5952 8.03317 11.6805 7.52622 11.6805 6.99984C11.6805 5.69381 11.2271 4.58714 10.3206 3.68058C9.414 2.77402 8.30732 2.32067 7.0013 2.32067C6.47492 2.32067 5.96797 2.40597 5.48051 2.57658C4.99309 2.74718 4.54465 2.99333 4.13525 3.31501L4.12417 3.32371L4.13413 3.33368L10.6675 9.86701ZM7.0013 12.8207C6.19598 12.8207 5.43934 12.6679 4.73125 12.3623C4.02292 12.0567 3.40689 11.6419 2.88306 11.1181C2.35922 10.5942 1.94447 9.97822 1.63882 9.26989C1.33328 8.5618 1.18047 7.80515 1.18047 6.99984C1.18047 6.19452 1.33328 5.43788 1.63882 4.72979C1.94447 4.02146 2.35922 3.40543 2.88306 2.88159C3.40689 2.35776 4.02292 1.94301 4.73125 1.63736C5.43934 1.33181 6.19598 1.179 7.0013 1.179C7.80662 1.179 8.56326 1.33181 9.27135 1.63736C9.97968 1.94301 10.5957 2.35776 11.1195 2.88159C11.6434 3.40543 12.0581 4.02146 12.3638 4.72979C12.6693 5.43788 12.8221 6.19452 12.8221 6.99984C12.8221 7.80515 12.6693 8.5618 12.3638 9.26989C12.0581 9.97822 11.6434 10.5942 11.1195 11.1181C10.5957 11.6419 9.97968 12.0567 9.27135 12.3623C8.56326 12.6679 7.80662 12.8207 7.0013 12.8207Z"
          fill={hex}
          stroke={hex}
          strokeWidth="0.025"
        />
      </g>
    </svg>
  )
}
