import React, { useState } from 'react'
import classNames from 'classnames'
import { CircleInfoIcon } from '@/atoms/Icons/CircleInfoIcon'
import { ParagraphSM } from '@/atoms/Text'
import { useTranslate } from '@/utils/translate/translate-client'

interface SoldOutInfoIconTooltipProps {
  isDark?: boolean
  iconSize?: number
  shouldUseNewAfterDeathGoal?: boolean
  overlayClassname?: string
}

export const SoldOutInfoIconTooltip: React.FC<SoldOutInfoIconTooltipProps> = ({
  isDark = false,
  iconSize = 16,
  overlayClassname,
}) => {
  const [isShown, setShown] = useState<boolean>(false)
  const { t } = useTranslate('tickets')

  const toggleShown = () => setShown((currentShown) => !currentShown)

  return (
    <div
      onMouseOver={() => setShown(true)}
      onMouseOut={() => setShown(false)}
      onClick={toggleShown}
      className="relative z-50 inline py-[5px]"
    >
      <ParagraphSM
        className={classNames(
          'absolute top-full right-0 rounded text-sm w-[260px] py-2 px-2 text-center !normal-case drop-shadow-md transition ease-in-out inline color-black',
          isDark ? 'text-gray-200 bg-core-gray-900' : 'text-gray-950 bg-white',
          {
            'scale-100': isShown,
            'scale-0': !isShown,
          },
          overlayClassname,
        )}
        weight="normal"
      >
        {t(
          'soldOutLanguageV5',
          'Our latest data estimates which include sold-out showtimes, through to the end of the theatrical run, with the exception of wheelchair-accessible and front-row seating.\n',
        )}
      </ParagraphSM>
      <CircleInfoIcon size={iconSize} color="black" className="inline" />
    </div>
  )
}
