import React, { useCallback, useState } from 'react'
import { CountryCode, E164Number } from 'libphonenumber-js'
import { When } from 'react-if'
import { Button } from '@/atoms/Button'
import { WhatsAppFullGlyph } from '@/atoms/Icons/WhatsAppFullGlyph'
import { WhatsAppGlyph } from '@/atoms/Icons/WhatsAppGlyph'
import { IntlPhoneInput } from '@/atoms/Input/IntlPhoneInput/IntlPhoneInput'
import { AngelVerificationInput } from '@/atoms/Input/VerificationInput/AngelVerificationInput'
import { ParagraphMD } from '@/atoms/Text'
import {
  doWhatsAppSignUp,
  validateWhatsAppVerificationCode,
  whatsAppResendCode,
} from '@/organisms/WhatsAppSignUp/WhatsAppSignUpService'
import { AnalyticsEvent, useSafeTrack } from '@/utils/analytics'
import { useTranslate } from '@/utils/translate/translate-client'
import { BuyTicketsLink } from '@/views/TicketCheckoutViews/ShowtimesView/components/TicketsPromo/FreeTicketModal'

export interface WhatsAppSignUpProps {
  countryCode: CountryCode
  locale: string
  onPhoneVerified: (verificationCode?: string) => void
  ticketUrl: string | undefined
  trackClick: (eventName: AnalyticsEvent, customAttributes?: unknown) => void
  ticketButtonText: string
  projectSlug: string
}

type SignUpStates = 'phone' | 'verify' | 'retry' | 'complete'

export const WhatsAppSignUp: React.FC<WhatsAppSignUpProps> = ({
  countryCode,
  locale,
  onPhoneVerified,
  ticketUrl,
  ticketButtonText,
  trackClick,
  projectSlug,
}) => {
  const { t } = useTranslate('common')
  const [signupState, setSignupState] = useState<SignUpStates>('phone')
  const [validPhone, setValidPhone] = useState<boolean>(false)
  const [phoneValue, setPhoneValue] = useState<E164Number | undefined>('')
  const [verificationCode, setVerificationCode] = useState<string>('')
  const track = useSafeTrack()

  const handleVerificationSubmit = () => {
    validateWhatsAppVerificationCode(phoneValue, verificationCode, locale).then((isValid) => {
      if (isValid) {
        onPhoneVerified()
        setSignupState('complete')
      } else {
        setVerificationCode('')
        setSignupState('retry')
      }
    })
  }

  const trackSent = useCallback(() => {
    const params = {
      project_slug: projectSlug,
      message_kind: 'utility',
      // message cost is an average since cost varies
      message_cost: 0.032,
      date_message_sent: new Date(Date.now()).toISOString(),
    }

    track('Whatsapp Message Sent', params)
  }, [track, projectSlug])

  return (
    <div className="flex flex-col gap-[3.5rem]">
      <div className="flex flex-row">
        <WhatsAppFullGlyph />
      </div>
      <When condition={signupState === 'phone'}>
        <div className="flex w-full flex-col items-center justify-center">
          <div className="flex flex-row">
            <IntlPhoneInput
              countryCode={countryCode}
              onValidPhone={(v) => setValidPhone(v)}
              onPhoneValue={(v) => setPhoneValue(v)}
            />
          </div>
          <div className="flex flex-row">
            <Button
              disabled={!validPhone}
              className="mt-2 w-full"
              variant="whatsapp"
              onClick={() => {
                doWhatsAppSignUp(locale, phoneValue, trackSent).then(() => {
                  trackSent()
                  setSignupState('verify')
                })
              }}
            >
              <WhatsAppGlyph />
              <ParagraphMD className="mx-2" weight="semibold">
                {t('signUpWhatsAppVerificationCode', 'Send Verification Code')}
              </ParagraphMD>
            </Button>
          </div>
          <div className="flex w-full flex-row">
            <BuyTicketsLink ticketUrl={ticketUrl} trackClick={trackClick} text={ticketButtonText} />
          </div>
        </div>
      </When>
      <When condition={signupState === 'verify'}>
        <div className="flex w-full flex-col items-center justify-center gap-[3.5rem]">
          <AngelVerificationInput theme={'whatsApp'} onComplete={(v) => setVerificationCode(v)} />
          <div>
            <Button
              disabled={verificationCode.length < 6}
              className="mt-2 w-full"
              variant="whatsapp"
              onClick={handleVerificationSubmit}
            >
              <ParagraphMD className="mx-2" weight="semibold">
                {t('signUpWhatsAppVerify', 'Verify Code')}
              </ParagraphMD>
            </Button>
            <BuyTicketsLink ticketUrl={ticketUrl} trackClick={trackClick} text={ticketButtonText} />
          </div>
        </div>
      </When>
      <When condition={signupState === 'retry'}>
        <div className="flex w-full flex-col items-center justify-center gap-[3.5rem]">
          <AngelVerificationInput onComplete={(v) => setVerificationCode(v)} />
          <div>
            <Button
              className="mt-2 w-full"
              variant="whatsapp"
              onClick={() => {
                whatsAppResendCode(phoneValue, locale, trackSent).catch((e) => e)
              }}
            >
              <ParagraphMD className="mx-2" weight="semibold">
                {t('signUpWhatsAppResendCode', 'Resend Code')}
              </ParagraphMD>
            </Button>
            <Button
              disabled={verificationCode.length < 6}
              className="mt-2 w-full"
              variant="whatsapp"
              onClick={handleVerificationSubmit}
            >
              <ParagraphMD className="mx-2" weight="semibold">
                {t('signUpWhatsAppVerify', 'Verify Code')}
              </ParagraphMD>
            </Button>
            <BuyTicketsLink ticketUrl={ticketUrl} trackClick={trackClick} text={ticketButtonText} />
          </div>
        </div>
      </When>
      <When condition={signupState === 'complete'}>
        <div className="flex w-full flex-col items-center justify-center gap-[3.5rem]">
          <div>
            <ParagraphMD className="mx-2" weight="semibold">
              {t('signUpWhatsAppComplete', 'Complete')}
            </ParagraphMD>
          </div>
        </div>
      </When>
    </div>
  )
}
