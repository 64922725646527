import React, { FC } from 'react'
import classNames from 'classnames'
import { FlexColumn, FlexRow } from '@/atoms/FlexContainers'
import { Image } from '@/atoms/Image'
import { CaptionSM, CaptionXS, EyebrowSM, HeadingLG, HeadingMD, TitleSM } from '@/atoms/Text'
import { slugs } from '@/constants'
import { useTranslate } from '@/utils/translate/translate-client'
import { DiscountType } from '@/views/TicketCheckoutViews/ShowtimesView/components/TicketsContext'
import TimeLeft from '@/views/TicketCheckoutViews/ShowtimesView/components/banners/DiscountBanner/TimeLeft'
import { FlexibleBookingToolTip } from '../../ToolTips/FlexibleBookingToolTip'

interface Props {
  discount: DiscountType | null
  theatricalSlug: string
  className?: string
}

const FlashSaleBanner: FC<Props> = ({ discount, className, theatricalSlug }) => {
  const { t } = useTranslate('tickets')

  return (
    <div className="relative z-[49] w-full @container">
      <FlexRow
        className={classNames(
          'justify-between relative gap-4 bg-core-gray-950 pr-4 @4xl:pr-0 z-[999] h-full max-h-[100px] md:max-h-[120px] text-white w-full',
          className,
        )}
      >
        <FlexRow className="z-10 h-full w-full !items-stretch justify-between">
          <FlexRow className="max-h-[100px] overflow-y-hidden md:max-h-[120px]">
            <FlexColumn
              className="relative h-full justify-center bg-repeat p-4 px-0 text-center text-core-gray-950 @lg:pl-4 @xl:text-left"
              style={{
                backgroundImage: 'url(https://images.angelstudios.com/image/upload/v1709769876/bg-vert-bar_4x.png)',
                backgroundSize: '1px',
              }}
            >
              <EyebrowSM className="text-[0.563rem] uppercase md:text-sm">
                {t('limitedTimeOffer', 'Limited Time Offer')}
              </EyebrowSM>
              <HeadingMD className="text-2xl md:text-4xl" weight="bold">
                {t('flashSale', 'Flash Sale')}
              </HeadingMD>
              <CaptionSM className="max-w-[9rem] text-[0.625rem] md:!max-w-full md:text-xs" italic>
                {t('availableForAngelPartnering', 'Available for Angel Partnering Theaters.')}
              </CaptionSM>
            </FlexColumn>
            <Image
              className="z-0 !ml-0 hidden h-full @lg:block xs:z-10"
              alt=""
              src="/v1709769609/bolt_4x.webp"
              width={93.5}
              height={0}
            />
            <Image
              className="z-0 -ml-[5px] h-full @lg:hidden xs:z-10"
              alt=""
              src="/v1709836465/bolt-yellow-small_4x.webp"
              width={50}
              height={0}
            />
          </FlexRow>
          <FlexColumn className="mx-auto shrink-0 items-center justify-center @4xl:mx-0">
            <HeadingLG
              className="-mb-[4px] text-[2rem] uppercase leading-[40px] md:-mb-[2px] md:text-[2.5rem]"
              weight="bold"
            >
              {`${discount?.value}% ${t('offTicket', 'off')}`}
            </HeadingLG>
            <TitleSM className="hidden text-[0.813rem] uppercase tracking-[2px] @2xl:block md:text-lg" weight="bold">
              {t('cabriniTickets', 'Cabrini Tickets')}
            </TitleSM>
            <FlexibleBookingToolTip panelPosition="LEFT" />
            <EyebrowSM
              color="core-gray-500"
              weight="normal"
              className="-mb-0.5 mt-0.5 text-[9px] uppercase @2xl:hidden"
            >
              {t('endingIn', 'Ending In')}
            </EyebrowSM>
            <TimeLeft className="@2xl:hidden" />
          </FlexColumn>
          <FlexColumn className="hidden justify-center gap-1 text-center @2xl:flex">
            <EyebrowSM color="core-gray-500" weight="normal" className="uppercase">
              {t('timeRemaining', 'Time Remaining')}
            </EyebrowSM>
            <TimeLeft />
            <div className="h-[1px] w-full bg-core-gray-800" />
            <CaptionXS color="core-gray-500">
              {t('discountAppliedAtCheckout', 'Discount applied at checkout')}
            </CaptionXS>
          </FlexColumn>
          {theatricalSlug === slugs.sight && (
            <Image
              className="hidden h-full @4xl:block"
              alt=""
              src="/v1715181550/sight-edge.webp"
              width={190}
              height={0}
            />
          )}
        </FlexRow>
      </FlexRow>
    </div>
  )
}

export default FlashSaleBanner
