import React, { useEffect, useState } from 'react'
import { addDays, differenceInSeconds, getDay, intervalToDuration } from 'date-fns'
import { Button } from '@/atoms/Button'
import { CloseIcon } from '@/atoms/Icons/CloseIcon'
import { Image } from '@/atoms/Image'
import { Modal } from '@/molecules/Modal'
import { getBooleanFromLocalStorage, writeToLocalStorage } from '@/utils/local-storage'
import { Translate, useTranslate } from '@/utils/translate/translate-client'
import {
  HomesteadButtonText,
  HomesteadEyebrowMD,
  HomesteadTitleSM,
  HomesteadTitleXL,
} from '@/views/PreSales/PreSalesGiveawayView/components/Typography'
import { useMultiplier } from '../../banners/Homestead/utils'

interface Props {
  isOpen: boolean
  onClose: () => void
}

const nextSunday = (date: Date): Date => {
  const dayOfWeek = getDay(date)
  const daysUntilNextSunday = (7 - dayOfWeek) % 7
  return addDays(date, daysUntilNextSunday)
}

const useCountdownToSundayMidnight = () => {
  const [timeLeft, setTimeLeft] = useState('')

  useEffect(() => {
    const updateCountdown = () => {
      const now = new Date()
      const mountainOffset = -7 * 60 * 60000 // Mountain Time is UTC-7

      const nextSundayMidnight = new Date(nextSunday(now).getTime() + mountainOffset)
      nextSundayMidnight.setUTCHours(0, 0, 0, 0)

      const secondsLeft = differenceInSeconds(nextSundayMidnight, now)
      const duration = intervalToDuration({ start: 0, end: secondsLeft * 1000 })

      const formattedDuration = `${duration.days}D ${duration.hours}H ${duration.minutes}M ${duration.seconds}S`

      setTimeLeft(formattedDuration)
    }

    updateCountdown()
    const intervalId = setInterval(updateCountdown, 1000)

    return () => clearInterval(intervalId)
  }, [])

  return timeLeft
}

export const GeneralHomesteadGiveawayModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { t } = useTranslate('tickets')
  const timer = useCountdownToSundayMidnight()
  const multiplier = useMultiplier()

  useEffect(() => {
    if (isOpen) {
      const hasSeenModal = getBooleanFromLocalStorage('homestead-giveaway-modal-seen')
      if (!hasSeenModal) {
        writeToLocalStorage('homestead-giveaway-modal-seen', true)
      }
    }
  }, [isOpen])

  if (!multiplier) return null

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      panelClasses="!max-w-[480px] !p-0"
      childWrapperClassName="!mt-0"
      className="!z-[60]"
    >
      <div className="relative flex flex-col bg-homestead-tan-200">
        <div className="absolute inset-0 bg-[url(https://images.angelstudios.com/image/upload/v1729801080/dirt-texture-on-white-01.webp)] bg-cover bg-no-repeat opacity-[.16] mix-blend-multiply" />
        <div className="relative h-[240px]">
          <Image
            width={480}
            height={360}
            src={`/v1730228369/angel-studios/projects/homestead/homestead-giveaway-set-01`}
            alt={t('homeSteadGiveawayItems', 'Homestead giveaway items')}
            className="h-full object-cover object-[50%_90%]"
          />
        </div>

        <button
          onClick={onClose}
          className="absolute right-6 top-5 z-10 flex size-8 cursor-pointer items-center justify-center rounded-full bg-homestead-tan-200 transition-bg hover:bg-homestead-tan-200/50"
        >
          <CloseIcon size={16} color="core-gray-950" />
        </button>

        <div className="relative z-10 px-6 pb-6 text-left">
          <div className="inline-block translate-y-[-45%] bg-homestead-yellow px-3 py-2">
            <HomesteadEyebrowMD className="font-semibold !leading-[1.2]">
              {t('thisWeekOnly', 'This week only')}
            </HomesteadEyebrowMD>
            <HomesteadTitleSM className="-mb-1 -mt-2 font-medium !leading-[1.2]">
              <Translate
                t={t}
                i18nKey="getMultiplerYourEntries"
                defaults="Get <bold>{{multiplier}}x</bold> Your Entries"
                components={{ bold: <span className="font-bold" /> }}
                values={{ multiplier }}
              />
            </HomesteadTitleSM>
            <HomesteadEyebrowMD className="font-semibold">
              {t('endingInTimer', 'Ending in {{timer}}', { timer })}
            </HomesteadEyebrowMD>
          </div>

          <HomesteadTitleXL className="-mt-4 !font-medium text-homestead-primary-blue">
            {t(
              'watchHomesteadInTheatersAndYouCouldWin',
              'Watch Homestead in Theaters and You Could Win The $300k Homestead Giveaway!',
            )}
          </HomesteadTitleXL>

          <Button
            variant="plain"
            onClick={onClose}
            className="mt-6 rounded-lg bg-homestead-red px-4 py-3 text-white transition-all duration-300 ease-in-out hover:bg-homestead-red-dark active:bg-homestead-red-200"
          >
            <HomesteadButtonText>{t('getShowtimes', 'Get Showtimes')}</HomesteadButtonText>
          </Button>
        </div>
      </div>
    </Modal>
  )
}
