/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { useRouter } from 'next/router'
import { getWebClient } from '@/services/ApolloClient'
import {
  AddUserToVoucherWaitlistRequest,
  AddUserToWaitlistMutation,
  AddUserToWaitlistMutationVariables,
  GetClaimReasonsQuery,
  InventoryStateValues,
  TheatricalShowtime,
  TheatricalShowtimeVenue,
  VelocityStateValues,
  VoucherWaitlistResponse,
} from '@/types/codegen-federation'
import { useLocale } from '@/utils/LocaleUtil'
import { getItemOrFirstEntry } from '@/utils/array-utils'
import { QueryParams } from '@/utils/types'
import { addUserToWaitlistMutation, getClaimReasonsQuery } from './queries'

export interface Venue {
  id: string
  name: string
  phoneNumber?: string
  url?: string
  address: VenueAddress
  showtimes: VenueShowtime[]
  distance?: number
  ticketingProviderSlug: string
  isFeatured?: boolean
}

interface VenueAddress {
  line: string
  city: string
  state: string
  zip?: string
  zipCode?: string
}

export interface VenueShowtime {
  id?: string
  localStartTimeFriendlyString?: string
  availableInventory: number | null
  ticketUrl: string | null
  utcStartTime: string | null
  utcDate: Date | null
  velocityState?: VelocityStateValues | null
  inventoryState?: InventoryStateValues | null
  language?: string
  subtitleLanguage?: string
  seatsReservable?: boolean
}

export function transformVenues(venues?: any): Venue[] {
  const array = venues || []
  const transformed = array.map((v: any) => {
    return {
      ...v,
      address: {
        ...v?.address,
        zip: v?.address?.zipCode,
      },
      showtimes: transformShowtimes(v?.showtimes),
    }
  })

  return transformed
}

export function getVenueFromQuery(
  query: QueryParams,
  venues: TheatricalShowtimeVenue[],
): TheatricalShowtimeVenue | null {
  const maybeVenue = query.selectedVenue
  if (!maybeVenue) return null

  try {
    const venueId = getItemOrFirstEntry(maybeVenue)
    const venue = venues.find((v) => v.id === venueId)

    if (!venue) throw new Error(`Could not find venue with id "${venueId}"`)
    return venue
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(`An error occurred while attempting to extract the venue from the query params`)
    // eslint-disable-next-line no-console
    console.error(err)
    return null
  }
}

export function transformShowtimes(showtimes?: any): VenueShowtime[] {
  const array = showtimes || []

  const transformed = array.map((s: any) => {
    return {
      ...s,
      utcDate: s?.utcStartTime ? new Date(s?.utcStartTime) : null,
    }
  })
  return transformed.filter(Boolean)
}

export function getSortedShowtimes(showtimes: TheatricalShowtime[]): TheatricalShowtime[] {
  const toSort = [...showtimes]

  toSort.sort((a, b) => {
    if (a.utcStartTime && !b.utcStartTime) return -1
    if (!a.utcStartTime && b.utcStartTime) return 1
    if (!a.utcStartTime || !b.utcStartTime) return 0
    if (a.utcStartTime < b.utcStartTime) return -1
    if (a.utcStartTime > b.utcStartTime) return 1
    return 0
  })

  return toSort
}

export type ClaimReason = NonNullable<NonNullable<NonNullable<GetClaimReasonsQuery>['claimReasons']>[number]>

export function useClaimReasons() {
  const response = useQuery(getClaimReasonsQuery, {
    errorPolicy: 'all',
  })

  return {
    ...response,
    claimReasons: (response?.data?.claimReasons ?? []) as ClaimReason[],
  }
}

export type AddUserToWaitlistResponse = NonNullable<AddUserToWaitlistMutation>

export function useClaimTicketMutation() {
  const { locale } = useLocale()
  const { query } = useRouter()
  const client = getWebClient({ locale: locale as string, region: query.region as string })
  const [claimTicketInternal, { data: claimTicketResponse, error: claimTicketError }] = useMutation<
    { addUserToWaitlist: VoucherWaitlistResponse },
    AddUserToWaitlistMutationVariables
  >(addUserToWaitlistMutation, { client, errorPolicy: 'all' })

  const claimTicket = useCallback(
    (input: AddUserToVoucherWaitlistRequest) => {
      return claimTicketInternal({ variables: { input } })
    },
    [claimTicketInternal],
  )

  return { claimTicket, claimTicketResponse, claimTicketError }
}
