import React, { useState, useMemo, ReactNode } from 'react'
import { useFloating, offset, flip, shift, arrow, Placement, Side } from '@floating-ui/react-dom'
import { Popover } from '@headlessui/react'
import classNames from 'classnames'
import { IconColor } from '@/atoms/utils/IconTypes'

export interface BaseTooltipProps {
  iconSize?: number
  className?: string
  buttonClassName?: string
  panelClassName?: string
  panelPosition?: 'LEFT' | 'CENTER' | 'RIGHT'
  isIconOnly?: boolean
  removeBorder?: boolean
  buttonStyle?: React.CSSProperties
  guildScore?: number | undefined
  titleText?: string
  children?: ReactNode
  buttonColor?: IconColor
}

interface BaseTooltipComponentProps extends BaseTooltipProps {
  trigger: ReactNode
  content: ReactNode
}

export const BaseTooltip: React.FC<BaseTooltipComponentProps> = ({
  className,
  buttonClassName,
  panelClassName,
  panelPosition = 'CENTER',
  trigger,
  content,
  isIconOnly,
  removeBorder = false,
  buttonStyle,
}) => {
  const [isHovering, setIsHovering] = useState(false)
  const arrowRef = React.useRef<HTMLDivElement>(null)

  const placement: Placement =
    panelPosition === 'LEFT' ? 'bottom-start' : panelPosition === 'RIGHT' ? 'bottom-end' : 'bottom'

  const { x, y, refs, strategy, middlewareData } = useFloating({
    placement,
    middleware: [offset(8), flip(), shift({ padding: 8 }), arrow({ element: arrowRef })],
  })

  const staticSide = useMemo(() => {
    const side = placement.split('-')[0] as Side
    return {
      top: 'bottom',
      right: 'left',
      bottom: 'top',
      left: 'right',
    }[side] as string
  }, [placement])

  return (
    <Popover className={classNames('relative', className)}>
      {({ open }) => (
        <>
          <Popover.Button
            ref={refs.setReference}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            className={classNames(
              'flex gap-1 items-center justify-center relative rounded-full py-0.5 @sm:py-1 px-3',
              !removeBorder &&
                !isIconOnly &&
                'border border-white/30 !focus-visible:outline-0 focus-visible:ring-0 focus-visible:ring-transparent focus-visible:ring-offset-0',
              buttonClassName,
            )}
            style={buttonStyle}
          >
            {trigger}
          </Popover.Button>
          {(open || isHovering) && (
            <div
              ref={refs.setFloating}
              style={{
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
                width: 'calc(100vw - 2rem)',
                maxWidth: '320px',
              }}
              className={classNames('p-6 !z-[60] text-left bg-white shadow-light-2 rounded-2xl', panelClassName)}
            >
              <div
                ref={arrowRef}
                className="absolute h-3 w-3 rotate-45 bg-white"
                style={{
                  left: middlewareData.arrow?.x != null ? `${middlewareData.arrow.x}px` : '',
                  top: middlewareData.arrow?.y != null ? `${middlewareData.arrow.y}px` : '',
                  right: '',
                  bottom: '',
                  [staticSide]: '-5px',
                }}
              />
              {content}
            </div>
          )}
        </>
      )}
    </Popover>
  )
}
