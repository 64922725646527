import React, { useCallback, useMemo } from 'react'
import { gql, useQuery } from '@apollo/client'
import {
  TheatricalShowtimeEnhancedDataQuery,
  TheatricalShowtimeEnhancedDataQueryVariables,
  TheatricalShowtimeVenue,
} from '@/types/codegen-federation'
import { ReactFCC } from '@/types/react'

const getTheatricalShowtimeEnhancedData = gql`
  query theatricalShowtimeEnhancedData($input: TheatricalShowtimeEnhancedDataInput!) {
    theatricalShowtimeEnhancedData(input: $input) {
      enhancedData {
        id
        hasSignificantPurchaseVelocity
      }
    }
  }
`
interface EnhancedShowtimeContextValues {
  isShowtimeEnhanced: (showtimeId: string) => boolean
}

const EnhancedShowtimeContext = React.createContext<EnhancedShowtimeContextValues | null>(null)

interface EnhancedShowtimeProviderProps {
  venues: TheatricalShowtimeVenue[]
}

export const EnhancedShowtimeProvider: ReactFCC<EnhancedShowtimeProviderProps> = ({ children, venues }) => {
  const showtimeIds = useMemo(() => {
    return venues
      ?.map((venue) => venue?.showtimes)
      ?.flat()
      ?.map((showtime) => showtime?.ticketingId ?? '')
      ?.filter((showtimeId) => !!showtimeId)
  }, [venues])

  const { data } = useQuery<TheatricalShowtimeEnhancedDataQuery, TheatricalShowtimeEnhancedDataQueryVariables>(
    getTheatricalShowtimeEnhancedData,
    { variables: { input: { showtimeIds } }, skip: !showtimeIds || showtimeIds?.length === 0, errorPolicy: 'all' },
  )

  const isShowtimeEnhanced = useCallback(
    (showtimeId: string) => {
      return !!data?.theatricalShowtimeEnhancedData?.enhancedData?.find(
        (enhancedData) => enhancedData?.id === showtimeId,
      )?.hasSignificantPurchaseVelocity
    },
    [data?.theatricalShowtimeEnhancedData?.enhancedData],
  )

  const value = useMemo(() => {
    return { isShowtimeEnhanced }
  }, [isShowtimeEnhanced])

  return <EnhancedShowtimeContext.Provider value={value}>{children}</EnhancedShowtimeContext.Provider>
}

export const useEnhancedShowtimeContext = (): EnhancedShowtimeContextValues => {
  return React.useContext(EnhancedShowtimeContext) as EnhancedShowtimeContextValues
}
