import React, { useCallback } from 'react'
import { Button } from '@/atoms/Button'
import { CaptionMD, ParagraphMD } from '@/atoms/Text'
import { AngelDialog } from '@/molecules/AngelDialog'
import { useTranslate } from '@/utils/translate/translate-client'
import { LocaleWarningModalState } from '@/views/TicketCheckoutViews/ShowtimesView/components/VenueList'

interface LocalWarningModalProps {
  onClose: () => void
  localeWarningModalState: LocaleWarningModalState
}
const LocalWarningModal = ({ onClose, localeWarningModalState }: LocalWarningModalProps) => {
  const { t } = useTranslate('tickets')
  const onContinue = useCallback(() => {
    localeWarningModalState.callback()
    onClose()
  }, [localeWarningModalState, onClose])

  return (
    <AngelDialog
      isDarkMode={false}
      open={localeWarningModalState.isOpen}
      title={t('showtimeFeatures', 'Showtime Features')}
      onClose={onClose}
    >
      <ParagraphMD className="mb-6">{localeWarningModalState.warning}</ParagraphMD>
      <div className="flex justify-end gap-2">
        <Button variant="black" outline onClick={onClose}>
          <CaptionMD weight="semibold">{t('cancel', 'Cancel')}</CaptionMD>
        </Button>
        <Button variant="black" onClick={onContinue}>
          <CaptionMD weight="semibold">{t('continue', 'Continue')}</CaptionMD>
        </Button>
      </div>
    </AngelDialog>
  )
}

export default LocalWarningModal
