import React, { FC, useCallback } from 'react'
import classNames from 'classnames'
import { CopyIcon } from '@/atoms/Icons/CopyIcon'
import { CaptionXS, EyebrowSM, TitleLG } from '@/atoms/Text'
import { useToast } from '@/molecules/Toast'
import { Translate, useTranslate } from '@/utils/translate/translate-client'

interface ManualPromoCodeProps {
  code: string
  className?: string
  onClick?: () => void
  codeClassName?: string
  codeOnly?: boolean
  isDarkMode?: boolean
  showCopyIcon?: boolean
}
export const ManualPromoCode: FC<ManualPromoCodeProps> = ({
  code,
  className,
  codeClassName,
  onClick,
  isDarkMode = false,
  codeOnly = false,
  showCopyIcon = false,
}) => {
  const { t } = useTranslate('tickets')
  const toast = useToast()

  const handleCopyCode = useCallback(() => {
    onClick && onClick()
    if (navigator?.clipboard) {
      navigator.clipboard.writeText(code).then(() => {
        toast.showToast(t('codeCopiedToClipboard', 'Code copied to clipboard'))
      })
    }
  }, [code, onClick, t, toast])

  return (
    <div className={className}>
      {!codeOnly && (
        <EyebrowSM weight="semibold" color={isDarkMode ? 'white' : 'black'} className="uppercase">
          {t('usePromoCode', 'Use Promo Code')}
        </EyebrowSM>
      )}
      <TitleLG
        onClick={handleCopyCode}
        className={classNames(
          'cursor-pointer flex items-center justify-center gap-1 bg-warm-03/70 px-2 py-1',
          codeClassName,
        )}
        weight="bold"
      >
        {code}
        {showCopyIcon && <CopyIcon size={16} color={isDarkMode ? 'black' : 'core-gray-950'} />}
      </TitleLG>
      {!codeOnly && (
        <>
          <EyebrowSM weight="semibold" color={isDarkMode ? 'white' : 'black'} className="uppercase">
            {t('atCheckout', 'At Checkout')}
          </EyebrowSM>
          <CaptionXS
            className="text-center leading-[12px] md:leading-[14px]"
            italic
            color={isDarkMode ? 'core-gray-300' : 'core-gray-700'}
          >
            <Translate i18nKey="availableForAngelPartneringTheatersNoFees" t={t}>
              Available for <span className="block">Angel Partnering Theaters.</span>
            </Translate>
          </CaptionXS>
        </>
      )}
    </div>
  )
}
