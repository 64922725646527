import { gql } from '@apollo/client'

export const getClaimReasonsQuery = gql`
  query GetClaimReasons {
    claimReasons {
      key
      message
    }
  }
`

export const addUserToWaitlistMutation = gql`
  mutation addUserToWaitlist($input: AddUserToVoucherWaitlistRequest!) {
    addUserToWaitlist(input: $input) {
      updatedAt
      insertedAt
      isRedeemed
      redeemedAt
      showtimeLink
      ticketingService
      userId
      venueEventSlug
      voucherCode
      voucherCodes
      isOnlineRedeemable
    }
  }
`
