import { isAfter, isValid, parse, parseISO } from 'date-fns'
import { getObjectFromLocalStorage, writeToLocalStorage } from '@/utils/local-storage'
import {
  Coordinates,
  SavedSearchStateType,
} from '@/views/TicketCheckoutViews/ShowtimesView/hooks/useVenueSearch/useVenueSearch'

const getNewExpiration = () => {
  const newExpiration = new Date()
  newExpiration.setHours(newExpiration.getHours() + 1)
  return newExpiration
}

export const saveSearchDate = (date: Date, key: string) => {
  const savedSearchState = getObjectFromLocalStorage<SavedSearchStateType>(key)
  if (!savedSearchState) {
    return writeToLocalStorage<SavedSearchStateType>(key, {
      date,
      coordinates: null,
      searchString: null,
      expires: getNewExpiration(),
    })
  }
  writeToLocalStorage<SavedSearchStateType>(key, {
    date,
    coordinates: savedSearchState?.coordinates,
    searchString: savedSearchState?.searchString,
    expires: getNewExpiration(),
  })
}

export const saveCoordinates = (coordinates: Coordinates, searchString: string, key: string) => {
  const savedSearchState = getObjectFromLocalStorage<SavedSearchStateType>(key)
  if (!savedSearchState) {
    return writeToLocalStorage<SavedSearchStateType>(key, {
      date: null,
      coordinates,
      searchString,
      expires: getNewExpiration(),
    })
  }
  writeToLocalStorage<SavedSearchStateType>(key, {
    date: savedSearchState?.date,
    coordinates,
    searchString,
    expires: getNewExpiration(),
  })
}

export const parseQueryDate = (queryDate: string) => {
  const parsedIsoDate = parseISO(queryDate)
  if (isValid(parsedIsoDate)) return parsedIsoDate

  const parsedDate = parse(queryDate, 'MM/dd/yyyy', new Date())
  if (isValid(parsedDate)) return parsedDate
}

export const isDateExpired = (date: Date) => {
  return new Date(date).getTime() < new Date().getTime()
}

export const getEarliestDate = (releaseDate: Date) => {
  if (releaseDate && isAfter(releaseDate, new Date())) {
    return releaseDate
  } else {
    return new Date()
  }
}
