import { useRouter } from 'next/router'
import { slugs } from '@/constants'
import { TranslateFunction } from '@/utils/translate/translate-client'

export const getAttributionListItem = (projectName: string, projectSlug: string, t: TranslateFunction) => {
  if (projectSlug) {
    return {
      title: t('helpFundProjectContent', 'Help fund {{projectName}}', { projectName }),
      description: t('yourMembershipSupportsThisShow', 'Your membership supports this show'),
    }
  }

  return {
    title: t('helpFundAngelContent', 'Help fund future films and shows'),
    description: t('yourMembershipSupportsNewProjects', 'Your membership supports new projects'),
  }
}

export const getBannerImageSrc = (projectSlug: string, winWidth: number) => {
  if (projectSlug === slugs.tuttleTwins) return '/v1724179969/angel-studios/guild/guild-join-tuttle/tuttle_welcome'
  if (projectSlug === slugs.wingfeatherSaga) return '/v1714614962/angel-studios/guild/guild-join-wingfeather/wfs_poster'
  if (projectSlug === slugs.youngDavid)
    return '/v1726259330/angel-studios/guild/guild-join-young-david/young_david_welcome'

  return winWidth < 1024
    ? '/v1714746688/angel-studios/guild/guild-join-wingfeather/guild_poster_mobile'
    : '/v1714746339/angel-studios/guild/guild-join-wingfeather/guild_poster_desktop'
}

export const getGuildUserPollTimeout = () => 20 * 1000

// Note: 1855 ($180/year) is a staging offer, 1848 ($20/month) and 1841 ($179/year) are production offers
export const PREMIUM_OFFER_IDS = ['1855', '1848', '1841']

// NextJS router.query is an empty object on first render, so this helper func
// can be useful if we need a query param right away
// getNextRouterQueryParam('desiredQueryParamKey', routerObject)
export const getNextRouterQueryParam = (key: string, { asPath, query }: ReturnType<typeof useRouter>) => {
  if (query[key]) {
    return query[key]
  }

  const match = asPath.match(new RegExp(`[&?]${key}=(.*?)(&|$)`))
  if (!match) return undefined
  return decodeURIComponent(match[1])
}
