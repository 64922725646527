import { FC } from 'react'
import { LinkButton } from '@/atoms/Button'
import { ParagraphSM, TitleLG } from '@/atoms/Text'
import { useUser } from '@/services/UserService'
import { useLoginUrl } from '@/utils/auth-utils'
import { projectThemes } from '@/utils/project-themes'
import { useTranslate, Translate } from '@/utils/translate/translate-client'
import { useTicketsContext } from '@/views/TicketCheckoutViews/ShowtimesView/components/TicketsContext'

interface ComingSoonProps {
  slug: string
}
export const ComingSoon: FC<ComingSoonProps> = ({ slug }) => {
  const { t } = useTranslate('tickets')
  const { theatricalRelease } = useTicketsContext()
  const { loginUrl, trackLoginStarted } = useLoginUrl()
  const { user } = useUser()
  const countryCode = theatricalRelease?.region?.countryCode
  const name = projectThemes?.[slug].name

  return (
    <div className="mt-16 flex min-h-[40vh] flex-col items-center md:mt-32">
      <div className="w-full max-w-md text-center">
        <TitleLG weight="bold">{t('showTimesComingInAreaSoon', 'Showtimes in your area coming soon!')}</TitleLG>
        <ParagraphSM color="core-gray-600" className="mt-2">
          <Translate t={t} i18nKey="showTimesComingInAreaSoonDescriptionProjectsV3" values={{ name }}>
            We encourage you to call or stop by your local theater and request{' '}
            <strong className="text-core-gray-950">{{ name }}</strong> today! Sign up for updates below and we will tell
            you when your local theaters have showtimes.
          </Translate>
        </ParagraphSM>
        {!user && (
          <LinkButton
            onClick={() => {
              trackLoginStarted(`Ticket Page Coming Soon ${countryCode}`)
            }}
            className="mx-auto mt-4 w-fit px-8 py-3"
            href={loginUrl}
            variant="black"
          >
            {t('signUpAndWeWillEmailYouTickets', 'Sign Up To Be Notified')}
          </LinkButton>
        )}
      </div>
    </div>
  )
}
