import React, { useEffect, useState } from 'react'
import { Button } from '@/atoms/Button'
import { HeadingMD, ParagraphLG, Text } from '@/atoms/Text'
import { AngelDialog } from '@/molecules/AngelDialog'
import { useUser } from '@/services/UserService'
import { TheatricalShowtimeVenue } from '@/types/codegen-federation'
import { useSafeAnalytics } from '@/utils/analytics'
import { useTranslate } from '@/utils/translate/translate-client'

interface RequestShowtimesModalProps {
  showModal: boolean
  onClose: () => void
  analyticsPayload: {
    venue: TheatricalShowtimeVenue
    latitude: number | undefined
    longitude: number | undefined
    theatricalName: string
    theatricalSlug: string
    projectSlug: string
  }
}

export const RequestShowtimesModal = (props: RequestShowtimesModalProps) => {
  const { user } = useUser()
  const { onClose, showModal, analyticsPayload } = props
  const { t } = useTranslate('tickets')
  const [email, setEmail] = useState('')
  const [submitted, setSubmitted] = useState(true)
  const [phone, setPhone] = useState('')
  const { track } = useSafeAnalytics()

  useEffect(() => {
    setSubmitted(false)
  }, [showModal])

  useEffect(() => {
    setEmail(user?.email || '')
  }, [user?.email])

  const setValue = (name: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (name === 'email') {
      setEmail(e.target.value)
    } else {
      setPhone(e.target.value)
    }
  }

  const handleSubmit = () => {
    track('Clicked Request Showtimes', { ...analyticsPayload, email, phone }).then(() => {
      setSubmitted(true)
    })
  }

  if (!showModal) return null

  return (
    <AngelDialog isDarkMode={false} onClose={onClose} open={showModal}>
      {submitted ? (
        <div>
          <HeadingMD className="text-center" weight="semibold">
            {t('RequestShowtimesHeaderThanks', 'Thanks!')}
          </HeadingMD>
          <ParagraphLG className="m-4 max-w-md text-center" weight="normal">
            {t('requestShowtimesThanks', 'Thanks for your interest in {{venueName}}!', {
              venueName: analyticsPayload.venue.name,
            })}
          </ParagraphLG>
          <Button className="h-12 w-full" type="submit" variant="black" onClick={onClose}>
            <Text className="font-semibold">{t('requestShowtimesClose', 'Close')}</Text>
          </Button>
        </div>
      ) : (
        <div>
          <HeadingMD className="text-center" weight="semibold">
            {t('RequestShowtimesHeader', 'Request Showtimes')}
          </HeadingMD>
          <ParagraphLG className="m-4 max-w-md text-center" weight="normal">
            {t(
              'requestShowtimesModalText',
              'We will contact you as soon as showtimes at {{venueName}} are available.',
              {
                venueName: analyticsPayload.venue.name,
              },
            )}
          </ParagraphLG>
          <div className="mb-4 flex flex-col space-y-2">
            <label htmlFor="requestEmail" className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              className="block w-full rounded-lg border border-gray-300 p-2.5 text-sm text-gray-900 focus:border-oxide focus:ring-oxide"
              id="email"
              name={t('requestShowtimesModalEmail', 'Email')}
              onChange={setValue('email')}
              placeholder="Email Address"
              type="email"
              value={email}
            />
          </div>
          <div className="mb-4 flex flex-col space-y-2">
            <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
              Phone #
            </label>
            <input
              className="block w-full rounded-lg border border-gray-300 p-2.5 text-sm text-gray-900 focus:border-oxide focus:ring-oxide"
              id="phone"
              name={t('requestShowtimesModalPhone', 'Phone #')}
              onChange={setValue('phone')}
              placeholder="Phone #"
              type="tel"
              value={phone}
            />
          </div>
          <Button className="h-12 w-full" type="submit" variant="black" onClick={handleSubmit}>
            <Text className="font-semibold">{t('requestShowtimesSubmit', 'Submit')}</Text>
          </Button>
        </div>
      )}
    </AngelDialog>
  )
}
