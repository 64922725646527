import React from 'react'
import { OneColorLogoProps } from '@/atoms/Logos/LogoTypes'
import { scaleDimensions } from '../utils'

export const CinemarkLogo: React.FC<OneColorLogoProps> = ({ height = 24 }) => {
  const { width: w, height: h } = scaleDimensions(height, 174, 24)
  return (
    <svg width={w} height={h} viewBox="0 0 174 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0009 0C5.39748 0 0 5.39708 0 12C0 18.6029 5.39748 24 12.0009 24C16.1703 24 19.8583 21.8477 22.0111 18.5995L18.7499 16.0635C17.3789 18.3484 14.8795 19.8661 12.0009 19.8661C7.63148 19.8661 4.13417 16.3691 4.13417 12C4.13417 7.63088 7.63148 4.13176 12.0009 4.13176C14.8795 4.13176 17.3789 5.65046 18.7499 7.93594L22.0111 5.39999C19.8583 2.15187 16.1702 0 12.0009 0ZM25.9588 0.747901V23.6042H29.9591V0.747901H25.9588ZM35.16 0.747901V23.6042H39.1603V8.01457C42.6435 13.1637 49.707 23.6042 49.707 23.6042H53.6203V0.747901H49.62V16.3375C46.136 11.1884 39.0739 0.747901 39.0739 0.747901H35.16ZM58.8452 0.747901V23.6042H73.4526V19.6042H62.8455V12.4234H72.3108V8.42343H62.8455V4.74791H73.7203V0.747901H58.8452ZM78.2113 0.747901V23.6042H82.2116V7.83385L88.3813 16.3115L94.5506 7.83385V23.6042H98.5509V0.747901H94.7615C94.7615 0.747901 91.165 5.68924 88.3813 9.51354C85.5976 5.68909 82.0006 0.747901 82.0006 0.747901H78.2113ZM110.039 0.747901L101.284 23.6042H105.568L107.954 17.3739H115.823L118.209 23.6042H122.493L113.739 0.747901H110.039ZM125.083 0.747901V23.6042H129.083V12.3151H131.106L139.211 23.6036H144.137L136.027 12.3109C136.699 12.2899 137.782 12.1589 138.987 11.4646C140.348 10.6804 141.672 8.88541 141.672 6.53123C141.672 4.17704 140.348 2.38469 138.987 1.60051C137.626 0.816339 136.419 0.747901 135.786 0.747901H125.083ZM146.96 0.747901V23.6042H150.961V15.1198L154.895 11.4245L163.551 23.6042H168.459L157.838 8.66093L166.265 0.747901H160.422L150.961 9.63177V0.747901H146.96ZM167.467 0.747901V1.4724H168.391V3.68228H169.298V1.4724H170.223V0.747901H167.467ZM170.699 0.747901V3.68228H171.442V1.44426L172.014 3.68228H172.685L173.26 1.44426V3.68228H174V0.747901H172.811L172.353 2.53331L171.893 0.747901H170.699ZM129.083 4.74791H135.786C137.081 4.74791 137.667 5.44212 137.672 6.53123C137.677 7.62035 137.081 8.3151 135.786 8.3151H129.083V4.74791ZM111.888 7.10103L114.291 13.374H109.486L111.888 7.10103Z"
        fill="#DD0000"
      />
    </svg>
  )
}
