import React from 'react'
import { CircleInfoIcon } from '@/atoms/Icons/CircleInfoIcon'
import { EyebrowMD, ParagraphSM, TitleXS } from '@/atoms/Text'
import { Translate, useTranslate } from '@/utils/translate/translate-client'
import { BaseTooltipProps } from '.'
import { BaseTooltip } from '.'

export const AntisemitesToolTip: React.FC<BaseTooltipProps> = ({
  iconSize = 16,
  className,
  buttonClassName,
  panelClassName,
  panelPosition = 'CENTER',
  isIconOnly,
  buttonStyle,
}) => {
  const { t } = useTranslate('tickets')

  const trigger = (
    <>
      {!isIconOnly && (
        <EyebrowMD className="!capitalize !text-core-gray-200">
          {t('antisemiteToolTipHeaderv2', 'Matinee Only, Front Row Only, No Holidays')}
        </EyebrowMD>
      )}
      <CircleInfoIcon size={iconSize} color={'core-gray-200'} />
    </>
  )

  const content = (
    <>
      <TitleXS color="core-gray-950" weight="bold">
        {t('onSelectShowtimes', 'On Select Showtimes')}
      </TitleXS>
      <ParagraphSM className="mt-2 text-[0.688rem] leading-[16px]" color="core-gray-700">
        <Translate i18nKey="antisemiteToolTipDetails" t={t}>
          Disclaimer*: Angel Studios will use best efforts to fulfill all free ticket requests it receives on its
          website. Notwithstanding, Angel reserves the right, in its sole discretion, to fulfill requests in a manner
          that best supports the release of the film Bonhoeffer. Requesting free tickets to Bonhoeffer is not an
          admission of one&apos;s anti-semitism; similarly, Angel&apos;s delivery of free tickets is in no way an
          acknowledgment or indictment of the recipient&apos;s political, religious, or ethnic beliefs.
        </Translate>
      </ParagraphSM>
    </>
  )

  return (
    <BaseTooltip
      className={className}
      buttonClassName={buttonClassName}
      panelClassName={panelClassName}
      panelPosition={panelPosition}
      isIconOnly={isIconOnly}
      removeBorder={true}
      buttonStyle={buttonStyle}
      trigger={trigger}
      content={content}
    />
  )
}
