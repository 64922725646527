import React, { FC } from 'react'
import classNames from 'classnames'
import { Button } from '@/atoms/Button'
import { LabelSM } from '@/atoms/Text'
import { TheatricalShowtimeVenue } from '@/types/codegen-federation'
import { useTranslate } from '@/utils/translate/translate-client'

interface PrivateShowingButtonProps {
  isOnEmptyShowtimeVenue?: boolean
  venue: TheatricalShowtimeVenue
  theaterChain: string | null
  onClick: (venue: TheatricalShowtimeVenue, theaterChain: string) => void
}

const PrivateShowingButton: FC<PrivateShowingButtonProps> = ({
  isOnEmptyShowtimeVenue,
  onClick,
  venue,
  theaterChain,
}) => {
  const { t } = useTranslate('tickets')

  const handleClick = () => {
    onClick(venue, theaterChain ?? '')
  }

  return (
    <Button
      onClick={handleClick}
      className={classNames(
        'relative flex h-10 shrink-0 items-center justify-center gap-2 rounded-[8px] px-3 py-3 hover:scale-105',
        isOnEmptyShowtimeVenue && '!font-semibold',
      )}
      variant={isOnEmptyShowtimeVenue ? 'lightgray' : 'core-gray-800'}
    >
      {isOnEmptyShowtimeVenue ? (
        t('privateShowing', 'Private Showing')
      ) : (
        <LabelSM weight="medium"> {t('privateShowing', 'Private Showing')}</LabelSM>
      )}
    </Button>
  )
}

export default PrivateShowingButton
