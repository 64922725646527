import React, { useCallback } from 'react'
import { LinkButton } from '@/atoms/Button'
import { Image } from '@/atoms/Image'
import { Text, TitleMD } from '@/atoms/Text'
import { useSafeTrack } from '@/utils/analytics'
import { useTranslate } from '@/utils/translate/translate-client'

export const EmptyStateGiveaway = () => {
  const { t } = useTranslate('tickets')
  const track = useSafeTrack()

  const handleButtonClick = useCallback(() => {
    track('Pre-Order Tickets Button Clicked')
  }, [track])

  return (
    <div className="mx-auto mb-6 mt-4 grid max-w-[480px] place-items-center gap-6 text-center">
      <Image
        alt=""
        className="rounded-lg md:!h-[150px] md:!w-[360px]"
        priority
        width={396}
        height={180}
        src="https://images.angelstudios.com/image/upload/v1732302526/homestead-giveaway-image-01.png"
      />

      <div className="flex flex-col gap-2">
        <TitleMD weight="bold">{t('showtimesComingSoonGiveawayEmpty', 'Showtimes Coming Soon')}</TitleMD>

        <Text className="photon-paragraph-sm md:photon-paragraph-md" color="core-gray-700">
          {t(
            'showtimesComingSoonDescriptionGiveawayEmpty',
            "We're adding new theaters every week. While you wait, pre-order tickets today for your chance to win the $300k Homestead Giveaway!",
          )}
        </Text>
      </div>

      <LinkButton
        onClick={handleButtonClick}
        href="/pre-sales/homestead/giveaway"
        className="w-fit"
        variant="oxide-primary"
      >
        {t('preOrderTicketsGiveawayEmpty', 'Pre-Order Tickets')}
      </LinkButton>
    </div>
  )
}
