import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { paths } from '@/constants/paths'
import { TheatricalReleaseObject, TheatricalShowtime, TheatricalShowtimeVenue } from '@/types/codegen-federation'
import { ReactFCC } from '@/types/react'
import { useSafeAnalytics } from '@/utils/analytics'
import { getItemOrFirstEntry } from '@/utils/array-utils'
import { useRouterPush } from '@/utils/router-utils'
import { QueryParams } from '@/utils/types'
import { TicketsAnalyticsPayload, useTicketsContext } from '../TicketsContext'
import { getPromoFromQuery, TicketPromo } from './utils'

type PromoType = {
  promoContext: TicketsPromoContextValues
  event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>
  showTime: TheatricalShowtime
  venue: TheatricalShowtimeVenue
  trackingPayload: TicketsAnalyticsPayload
}

type ShowAlertType = {
  showTime: TheatricalShowtime
  warning?: string | undefined
  event: React.MouseEvent
  promoCallback?: (() => void) | null
}

export interface TicketsPromoContextValues {
  hasShownIntro: boolean
  isPromoActive: boolean
  isPromoModalOpen: boolean
  openPromoModal: () => void
  closePromoModal: () => void
  ticketUrl?: string
  promo: TicketPromo | undefined
  showtimeSelected: (
    venue: TheatricalShowtimeVenue,
    ticketUrl: string,
    utcStartTime: string,
    showtime?: TheatricalShowtime,
  ) => void
  theatricalRelease: TheatricalReleaseObject
  theatricalSlug: string
  venue: TheatricalShowtimeVenue | undefined
  handleOpenPromo: ({ promoContext, event, showTime, venue, trackingPayload }: PromoType) => void
}

const TicketsPromoContext = React.createContext<TicketsPromoContextValues | null>(null)

export const useTicketsPromoContext = (): TicketsPromoContextValues => {
  return React.useContext(TicketsPromoContext) as TicketsPromoContextValues
}

interface TicketsPromoContextProviderProps {
  theatricalSlug: string
}

export const TicketsPromoContextProvider: ReactFCC<TicketsPromoContextProviderProps> = ({
  children,
  theatricalSlug,
}) => {
  const router = useRouter()
  const [hasShownIntro, setHasShownIntro] = useState(false)
  const [isQueryParseComplete, setIsQueryParseComplete] = useState(false)
  const [isPromoActive, setIsPromoActive] = useState(false)
  const [isPromoModalOpen, setIsPromoModalOpen] = useState(false)
  const [promo, setPromo] = useState<TicketPromo | undefined>()
  const [ticketUrl, setTicketUrlInternal] = useState<string | undefined>()
  const [venue, setVenueInternal] = useState<TheatricalShowtimeVenue | undefined>()
  const { theatricalRelease } = useTicketsContext()
  const { track } = useSafeAnalytics()
  const regionCode = theatricalRelease?.region?.countryCode
  const isPhilippinesFreeTicketFlow = router?.query?.promo && regionCode === 'PH'
  const isUtmSourceMobileApp = router?.query?.['utm_source'] === 'livestream-claim-tickets-cta'
  const { routerShallowPush } = useRouterPush()

  useEffect(() => {
    if (theatricalRelease.region?.useSimpleWaitlistOverlay && router.query.promo === 'claim-free-ticket') {
      setTicketUrlInternal(theatricalRelease.region.defaultTicketingProviderSlug ?? undefined)
      setHasShownIntro(true)
    }
  }, [router.query, theatricalRelease])

  const closePromoModal = useCallback(() => {
    setHasShownIntro(true)
    setIsPromoModalOpen(false)

    localStorage.removeItem('selectedShowtime')
  }, [setHasShownIntro, setIsPromoModalOpen])

  const showtimeSelected = useCallback(
    (venue: TheatricalShowtimeVenue, ticketUrl: string, utcStartTime: string, showtime?: TheatricalShowtime) => {
      setVenueInternal(venue)
      const isAtomVenue = venue?.ticketingProviderSlug === 'atom-tickets'
      setTicketUrlInternal(isAtomVenue ? `${paths.tickets.checkout.showtimes(theatricalSlug)}?flow=angel` : ticketUrl)
      setIsPromoModalOpen(true)

      localStorage.setItem(
        'selectedShowtime',
        JSON.stringify({
          ticketUrl,
          venue,
          utcStartTime,
          showtime,
        }),
      )

      const currentPathWithoutQueryParams = router.asPath.split('?')[0]
      const queryCopy = { ...router.query }
      delete queryCopy.slug
      const newQuery = addTicketUrlToQuery(ticketUrl, addVenueToQuery(venue, queryCopy))
      routerShallowPush({ pathname: currentPathWithoutQueryParams, query: newQuery })
    },
    [theatricalSlug, router.asPath, router.query, routerShallowPush],
  )

  const openPromoModal = useCallback(() => {
    setIsPromoModalOpen(true)
  }, [])

  useEffect(() => {
    if (isQueryParseComplete || !router.isReady) return

    const p = getPromoFromQuery(router.query)
    const isActive = determineIfPromoIsActive(p)
    const shouldShowMovieSelector = false
    if (!isActive) {
      if (shouldShowMovieSelector) {
        setIsPromoModalOpen(true)
      }
      setIsQueryParseComplete(true)
      return
    }

    setPromo(p)
    setIsPromoActive(true)

    const t = getTicketUrlFromQuery(router.query)
    if (t) {
      // when the user is returning from logging in, skip the intro.
      setHasShownIntro(true)
      setTicketUrlInternal(t)
    }

    // open the modal. This will start the user on the intro screen or will continue the claim process after logging in.
    setIsPromoModalOpen(true)

    setIsQueryParseComplete(true)
  }, [
    isQueryParseComplete,
    setIsQueryParseComplete,
    router,
    setPromo,
    setIsPromoActive,
    setHasShownIntro,
    setTicketUrlInternal,
    setIsPromoModalOpen,
    showtimeSelected,
    regionCode,
  ])

  const showAlert = useCallback(
    ({ showTime, event, promoCallback }: ShowAlertType) => {
      if (showTime.ticketUrl) {
        event.preventDefault()
        if (promoCallback) return promoCallback()
        window.open(showTime.ticketUrl as string, isUtmSourceMobileApp ? '_self' : '_blank', 'noopener,noreferrer')
      }
    },
    [isUtmSourceMobileApp],
  )

  const handlePromoCallback = useCallback(
    ({ promoContext, event, showTime, venue, trackingPayload }: PromoType) => {
      event.preventDefault()
      promoContext.showtimeSelected(
        venue,
        isPhilippinesFreeTicketFlow
          ? showTime.ticketUrl ?? venue.url ?? 'https://www.smcinema.com'
          : (showTime.ticketUrl as string),
        showTime.utcStartTime as string,
        showTime,
      )
      track('Tickets Promo Modal Opened', {
        startTime: showTime.utcStartTime,
        promo: promoContext,
        ...trackingPayload,
      })
    },
    [isPhilippinesFreeTicketFlow, track],
  )

  const handleOpenPromo = useCallback(
    ({ promoContext, event, showTime, venue, trackingPayload }: PromoType) => {
      const shouldDoPromoCallback = promoContext.isPromoActive && showTime.ticketUrl
      const promoCallback = () => {
        handlePromoCallback({ event, promoContext, showTime, venue, trackingPayload })
      }
      showAlert({ showTime, event, promoCallback: shouldDoPromoCallback ? promoCallback : null })
    },
    [handlePromoCallback, showAlert],
  )

  const value: TicketsPromoContextValues = useMemo(() => {
    return {
      hasShownIntro,
      isPromoModalOpen,
      isPromoActive,
      openPromoModal,
      closePromoModal,
      ticketUrl,
      promo,
      showtimeSelected,
      theatricalRelease,
      theatricalSlug,
      venue,
      handleOpenPromo,
    }
  }, [
    closePromoModal,
    hasShownIntro,
    isPromoActive,
    isPromoModalOpen,
    openPromoModal,
    promo,
    showtimeSelected,
    theatricalRelease,
    theatricalSlug,
    ticketUrl,
    venue,
    handleOpenPromo,
  ])

  return <TicketsPromoContext.Provider value={value}>{children}</TicketsPromoContext.Provider>
}

function determineIfPromoIsActive(promo: TicketPromo | undefined): boolean {
  if (promo === undefined) return false
  if (promo === 'sof-claim-free' || promo === 'claim-free-ticket') {
    return new Date() < new Date('2025-08-01')
  }
  return false
}

function getTicketUrlFromQuery(query: QueryParams): string | undefined {
  const maybeHashedUrl = query.ticketUrl
  if (!maybeHashedUrl) return

  const encodedUrl = getItemOrFirstEntry(maybeHashedUrl)
  const url = Buffer.from(encodedUrl, 'base64').toString('utf-8')

  if (url) return url
}

function addTicketUrlToQuery(url: string, query: QueryParams): QueryParams {
  const encodedUrl = Buffer.from(url, 'utf-8').toString('base64')
  if (encodedUrl) return { ...query, ticketUrl: encodedUrl }
  return query
}

function addVenueToQuery(venue: TheatricalShowtimeVenue, query: QueryParams): QueryParams {
  if (venue.id) return { ...query, selectedVenue: venue.id }
  return query
}
