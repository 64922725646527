import React from 'react'
import { CircleInfoIcon } from '@/atoms/Icons/CircleInfoIcon'
import { LabelXS, ParagraphSM, TitleXS } from '@/atoms/Text'
import { useTranslate } from '@/utils/translate/translate-client'
import { BaseTooltipProps } from '.'
import { BaseTooltip } from '.'

export const GuildGiveawayToolTip: React.FC<BaseTooltipProps> = ({
  iconSize = 16,
  className,
  buttonClassName,
  panelClassName,
  panelPosition = 'CENTER',
  isIconOnly,
  removeBorder = false,
  buttonStyle,
  buttonColor = 'white',
}) => {
  const { t } = useTranslate('tickets')
  const trigger = (
    <>
      <CircleInfoIcon size={iconSize} color={buttonColor} />
      {!isIconOnly && (
        <LabelXS className="text-[9px] sm:text-xs">
          {t('angelPartnerTheatersLabel', 'With Angel Partner Theaters')}
        </LabelXS>
      )}
    </>
  )
  const content = (
    <>
      <TitleXS color="core-gray-950" weight="bold">
        {t('angelPartnerTheatersTitle', 'Angel Partner Theaters')}
      </TitleXS>
      <ParagraphSM className="mt-2 text-[0.688rem] leading-[16px]" color="core-gray-700">
        {t(
          'angelPartnerTheatersDescription',
          'Angel Partner Theaters offer discounts, coupons, Flexible Booking™, and Guild ticket redemptions.',
        )}
      </ParagraphSM>
    </>
  )

  return (
    <BaseTooltip
      className={className}
      buttonClassName={buttonClassName}
      panelClassName={panelClassName}
      panelPosition={panelPosition}
      isIconOnly={isIconOnly}
      removeBorder={removeBorder}
      buttonStyle={buttonStyle}
      trigger={trigger}
      content={content}
    />
  )
}
