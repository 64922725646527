import React from 'react'
import classNames from 'classnames'
import { colors } from '@/constants/colors'
import { NamedColor } from '@/constants/types'
import { getHexColor } from '../utils'

const spinnerColors = {
  dark: { from: colors.black, to: colors['gray-700'], background: colors.black },
  light: { from: colors.white, to: colors['gray-700'], background: colors.white },
  oxide: { from: colors.white, to: colors['core-oxide'], background: colors.white },
}

interface CircleBusyIndicatorProps {
  className?: string
  variant?: keyof typeof spinnerColors
  size?: number
  strokeWidth?: number
  backgroundColor?: NamedColor | string
}

export const CircleBusyIndicator: React.VFC<CircleBusyIndicatorProps> = ({
  className,
  variant = 'light',
  size = 24,
  strokeWidth,
  backgroundColor,
}: CircleBusyIndicatorProps) => {
  const config = spinnerColors[variant]
  const defaultStrokeSizeProportion = 0.17
  const stroke = strokeWidth ? strokeWidth : size * defaultStrokeSizeProportion
  const backgroundHex = backgroundColor ? getHexColor(backgroundColor) : config.background

  return (
    <div className={classNames('inline-block', className)}>
      <div
        className="flex animate-spin items-center justify-center rounded-full align-middle"
        style={{
          background: `conic-gradient(${config.from}, ${config.to})`,
          width: size,
          height: size,
          animation: 'spin 1.5s linear infinite',
        }}
      >
        <div
          className="rounded-full"
          style={{
            background: backgroundHex,
            width: size - stroke,
            height: size - stroke,
          }}
        ></div>
      </div>
    </div>
  )
}
