import { useEffect, useMemo, useState } from 'react'
import { Durations } from '@/constants/durations'

interface Props {
  utcTargetDate: string | null
  shouldPadMinutes?: boolean
  shouldPadSeconds?: boolean
  shouldPadHours?: boolean
}

interface TimeLeft {
  hours: string
  minutes: string
  seconds: string
}

export const useCountDown = ({
  utcTargetDate,
  shouldPadHours = false,
  shouldPadMinutes = false,
  shouldPadSeconds = false,
}: Props) => {
  const targetDate = useMemo(() => new Date(utcTargetDate || ''), [utcTargetDate])
  const [timeLeft, setTimeLeft] = useState<TimeLeft>(
    calculateTimeLeft({ targetDate, shouldPadSeconds, shouldPadMinutes, shouldPadHours }),
  )
  const [isExpired, setIsExpired] = useState(false)
  const { minutes, seconds } = timeLeft

  const totalValue = useMemo(() => {
    return parseInt(minutes) + parseInt(seconds)
  }, [minutes, seconds])

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(calculateTimeLeft({ targetDate, shouldPadSeconds, shouldPadMinutes, shouldPadHours }))
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [shouldPadHours, shouldPadMinutes, shouldPadSeconds, targetDate])

  useEffect(() => {
    if (totalValue === 0 || totalValue < 0) {
      setIsExpired(true)
    }
  }, [timeLeft, totalValue])

  return { timeLeft, isExpired }
}

interface calculateTimeLeftProps {
  targetDate: Date
  startDate?: Date
  shouldPadMinutes?: boolean
  shouldPadSeconds?: boolean
  shouldPadHours?: boolean
}
const calculateTimeLeft = ({
  targetDate,
  startDate = new Date(),
  shouldPadSeconds,
  shouldPadMinutes,
  shouldPadHours,
}: calculateTimeLeftProps): TimeLeft => {
  const difference = targetDate.getTime() - startDate.getTime()

  if (difference <= 0) {
    return {
      hours: '0',
      minutes: '0',
      seconds: '0',
    }
  }
  const hours = Math.floor(difference / Durations.ONE_HOUR_IN_MILLISECONDS).toString()
  const minutes = Math.floor(
    (difference % Durations.ONE_HOUR_IN_MILLISECONDS) / Durations.ONE_MINUTE_IN_MILLISECONDS,
  ).toString()
  const seconds = Math.floor((difference % Durations.ONE_MINUTE_IN_MILLISECONDS) / Durations.ONE_SECOND_IN_MILLISECONDS)
    .toString()
    .padStart(2, '0')

  return {
    hours: shouldPadHours ? hours.padStart(2, '0') : hours,
    minutes: shouldPadMinutes ? minutes.padStart(2, '0') : minutes,
    seconds: shouldPadSeconds ? seconds.padStart(2, '0') : seconds,
  }
}
